const AWS = require('aws-sdk');

export const AWSS3FetchFile = async (filePath) => {
  const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;

  const awsConfig = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  AWS.config.update(awsConfig);

  const s3 = new AWS.S3({
    params: { Bucket: bucketName },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
    Bucket: bucketName,
    Key: filePath,
  };

  const getObject = s3.getObject(params).promise();

  try {
    const data = await getObject;
    return data.Body;
  } catch (error) {
    // console.log(error);
    throw new Error("Error fetching file from AWS S3.");
  }
};
