import { createContext, useState, useEffect } from 'react'
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom'
import { refreshTokenRequest, fetchProfileRequest, fetchTokenRequest, registerUserRequest, updateTaskerProfileRequest } from './Api';
import { useToast, useDisclosure } from "@chakra-ui/react"
import { errlogger } from './Logger';

const emptyProfile = {
  id: null,
  userId: null,
  type: null,
  data: {
    firstName: null,
    lastName: null,
    phoneNumber: null,
    country: null,
    company: null,
    role: null,
    industry: null,
  },
  balance: null,
}

const AuthContext = createContext()

export default AuthContext;

export const AuthProvider = ({children}) => {
  const [user, setUser] = useState(() => (localStorage.getItem('authTokens') ? jwtDecode(localStorage.getItem('authTokens')) : null))
  const [authTokens, setAuthTokens] = useState(() => (localStorage.getItem('authTokens') ? JSON.parse(localStorage.getItem('authTokens')) : null))
  const [loading, setLoading] = useState(true);

  const [profile, setProfile] = useState(() => {
    const profileInLocalStore = localStorage.getItem('profile');
    return profileInLocalStore !== null ? JSON.parse(profileInLocalStore) : emptyProfile;
  });
  
  const toast = useToast();
  const navigate = useNavigate()
  const { isOpen: isProfileOpen, onOpen: onProfileOpen, onClose: onProfileClose } = useDisclosure();
  
  useEffect(() => {
    localStorage.setItem('profile', JSON.stringify(profile));
  }, [profile]);

  // Note: this useEffect hook could update the profile whenever the authtokens are updated
  //       which include cases of when user login or logout
  useEffect(() => {
    if (authTokens) {
      refreshProfile();
    }
  }, [authTokens]);

  const loginUser = async (email, password) => {
    await fetchTokenRequest(email, password)
    .then(async response => {
      if (response.status === 200) {
        localStorage.setItem('authTokens', JSON.stringify(response.data));
        setAuthTokens(response.data)
        setUser(jwtDecode(response.data.access))
      } else {
        toast({
          title: "Could not log user in with the provided credentials!",
          description: "Could not log user in with the provided credentials!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })
    .catch(error => {
      toast({
        title: "Something went wrong while logging in the user!",
        description: "Something went wrong while logging in the user!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // console.log("Catch error: ", error);
    })
  }

  const signupUser = async (email, password, accessCode) => {
    await registerUserRequest(email, password, accessCode)
    .then(async response => {
      if (response.status === 201) {
        localStorage.setItem('authTokens', JSON.stringify(response.data));
        setAuthTokens(response.data)
        setUser(jwtDecode(response.data.access))
      } else {
        toast({
          title: "Something went wrong while registering your account!",
          description: "Something went wrong while registering your account!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })
    .catch(error => {
      toast({
        title: "Something went wrong while registering your account!",
        description: "Something went wrong while registering your account!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // console.log("Catch error: ", error);
    })
  }

  const logoutUser = () => {
    localStorage.removeItem('authTokens')
    setAuthTokens(null)
    setUser(null)
    setProfile(null)
    navigate('/')
  }

  // User Profile Related Context Functions
  const refreshProfile = async () => {
    await fetchProfileRequest(authTokens)
    .then(response => {
      if (response.status === 200) {
        setProfile(response.data);
      } else {
        toast({
          title: "Something went wrong while fetching the profile!",
          description: "Something went wrong while fetching the profile!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })
    .catch(error => {
      errlogger("refreshProfile", error);
    })
  }

  const updateProfile = async () => {
    await updateTaskerProfileRequest(authTokens, profile)
    .then(response => {
      if (response.status === 200) {
        setProfile(response.data);
      } else {
        toast({
          title: "Something went wrong while updating the profile!",
          description: "Something went wrong while updating the profile!",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    })
    .catch(error => {
      toast({
        title: "Something went wrong while updating the profile!",
        description: "Something went wrong while updating the profile!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      // console.log("Catch error: ", error);
    })
  }

  const editProfile = async (dataName, dataValue) => {
    setProfile({
      ...profile,
      data: {
        ...profile.data,
        [dataName]: dataValue,
      }
    })
  }

  const contextData = {
      user:user,
      authTokens:authTokens,
      loginUser:loginUser,
      logoutUser:logoutUser,
      signupUser:signupUser,
      profile:profile,
      refreshProfile:refreshProfile,
      updateProfile:updateProfile,
      editProfile:editProfile,
      isProfileOpen:isProfileOpen,
      onProfileOpen:onProfileOpen,
      onProfileClose:onProfileClose,
  }

  const updateToken = async () => {
    await refreshTokenRequest(authTokens)
    .then(response => {
      if (response.status === 200) {
        setAuthTokens(response.data)
        setUser(jwtDecode(response.data.access))
        localStorage.setItem('authTokens', JSON.stringify(response.data))
      } else {
        logoutUser()
      }

      if(loading){
        setLoading(false)
      }
    }).catch(error => {
      logoutUser()
    })
  }

  useEffect(()=>{
    if(loading){
      updateToken()
    }

    const REFRESH_INTERVAL = 1000 * 60 * 4 // 4 minutes
    const interval = setInterval(()=>{
        if(authTokens){
            updateToken()
        }
    }, REFRESH_INTERVAL)
    return () => clearInterval(interval)

  },[authTokens, loading])

  return(
      <AuthContext.Provider value={contextData}>
          {children}
      </AuthContext.Provider>
  )
}