import React, { useState, useContext, useEffect } from "react";
import { Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton, Button, useToast } from "@chakra-ui/react";
import AuthContext from "../../common/AuthContext";
import ProfileForm from "../ProfileForm";

function EditProfileModal({ isOpen, onClose }) {

  const { user, authTokens, profile, updateProfile, refreshProfile } = useContext(AuthContext);
  const toast = useToast();

  useEffect (() => {
    if (user && authTokens) {
      refreshProfile();
    }
  }, [user, authTokens])

  const checkProfileValidity = () => {
    // console.log("checkProfileValidity profile: ", profile);

    if (profile?.data?.firstName &&
      profile?.data?.lastName &&
      profile?.data?.phoneNumber &&
      profile?.data?.country &&
      profile?.data?.company &&
      profile?.data?.role &&
      profile?.data?.industry) {
        return true;
    } else {
      return false;
    }
  }

  const handleUpdateProfile = async () => {
    if (checkProfileValidity()) {
      // console.log("handleUpdateProfile profile: ", profile);

      await updateProfile();
      onClose();
    } else {
      toast({
        title: "Please make sure to have fill out all profile field!",
        description: "Please make sure to have fill out all profile field!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderUpdateProfileButton = () => {
    return (
      <Button colorScheme="blue" onClick={handleUpdateProfile} mr={2} ml={2} w="180px">Save Profile</Button>
    )
  }

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="60vw">
          <ModalCloseButton />
          <ModalBody m={8}>
            <ProfileForm renderSubmitButton={(renderUpdateProfileButton)} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}

export default EditProfileModal;