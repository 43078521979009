import React, { useState, useEffect, useContext } from 'react';

import { FormControl, FormLabel, Input, Text, HStack, Box, useToast, Link } from "@chakra-ui/react";
import AuthContext from '../../common/AuthContext';
import ProjectContext from '../../common/ProjectContext';
// import { AWSS3FetchFile } from '../../common/AWSS3FetchFile';
// import { AWSS3StoreFile } from '../../common/AWSS3StoreFile';
import { UploadFile, DownloadFile } from '../../common/PresignUrlHelper';
import { devlogger, errlogger } from '../../common/Logger';
import { useNavigate } from 'react-router-dom';

function ProjectBatchSettingForm({ renderSubmitButton, renderCancelButton }) {
  const [batchFile, setBatchFile] = useState(null);
  // const [batchData, setBatchData] = useState(null);
  // const [batchDataHeaders, setBatchDataHeaders] = useState(null);
  const navigate = useNavigate();

  const { currentProject, editSetting } = useContext(ProjectContext);
  const { authTokens, user } = useContext(AuthContext);
  const toast = useToast();
  const type = "text/manifest";

  const handleBatchSettingChange = (event) => {
    // console.log("ProjectBatchSettingForm handleBatchSettingChange event: ", event);

    editSetting("batchSetting", event.target.name, event.target.value);
  }

  useEffect(() => {
    // console.log("ProjectBatchSettingForm useEffect currentProject: ", currentProject);

    const path = currentProject?.setting?.batchSetting?.batchFilePath;

    if (path) {
      const fetchData = async () => {
        const parts = path.split('/');
        const file = parts.pop();
        const fetchedBatchFile = await DownloadFile(file, path, type, authTokens);

        devlogger("fetchData ProjectBatchSettingForm useEffect fetchedBatchFile", fetchedBatchFile);

        setBatchFile(fetchedBatchFile);
      }

      fetchData();
    } else {
      errlogger("fetchData", "ProjectBatchSettingForm useEffect currentProject?.setting?.batchSetting?.batchFilePath is null");
    }
  }, [currentProject?.setting?.batchSetting?.batchFilePath, currentProject]);

  useEffect(() => {
    // console.log("ProjectBatchSettingForm useEffect batchFile: ", batchFile);

    if (batchFile) {
      devlogger("ProjectBatchSettingForm useEffect batchFile", batchFile);

      devlogger("ProjectBatchSettingForm useEffect type", typeof batchFile);
      const lines = batchFile.split("\n");
      devlogger("ProjectBatchSettingForm useEffect lines", lines);

      const result = [];
      for(let i = 0; i < lines.length; i++) {
        if (lines[i].trim() !== '') {
          devlogger("ProjectBatchSettingForm useEffect lines[i]", lines[i]);

          const input = JSON.parse(lines[i]);
          const data = input["source"] || input["source-ref"] || input;
          result.push(data);

          if (i === 0) {
            editSetting("batchSetting", "firstData", data);
          }
        }
      }

      editSetting("batchSetting", "dataCount", result.length);
    };

  }, [batchFile]);

  const handleStoreBatchFile = async (batchFile) => {
    const savedBatchFilePath = await UploadFile(batchFile, type, authTokens, user);
    devlogger("handleStoreBatchFile savedBatchFilePath", savedBatchFilePath);

    if (savedBatchFilePath) {
      return savedBatchFilePath;
    } else {
      toast({
        title: "Batch file upload failed.",
        description: "Something went wrong while uploading the batch file!",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  }

  return (
    <>
      <Text fontWeight="bold" fontSize="xl" color="#5C9CD5" mb={4} pt={4}>Upload your batch data</Text>

      <FormControl id="batchFileUpload">
        <FormLabel>Upload Batch File in Manifest Format</FormLabel>
        <Input type="file" accept=".manifest" p={1} onChange={async (event) => {
          const batchFilePath = await handleStoreBatchFile(event.target.files[0]);
          const newEvent = {
            target: {
              name: "batchFilePath",
              value: batchFilePath
            }
          }
          handleBatchSettingChange(newEvent);
        }}/>
        {currentProject?.setting?.batchSetting?.batchFilePath && <Text color="blue.500" mt={1}>Selected Batch File: {currentProject?.setting?.batchSetting?.batchFilePath.split('/').pop().replace(`${currentProject.id}_`, '')}</Text>}
        <Text mt={1} color="gray.600">
          Drag & drop your batch manifest file here, or click to select one from your computer. If you don't have a manifest file you can generate one
          <span 
            onClick={() => navigate('/dataset-import')} 
            style={{ color: '#3182CE', textDecoration: 'underline', cursor: 'pointer', paddingLeft: '5px' }}
          >
            here
          </span>
        </Text>
      </FormControl>
      
      <Box>
        {currentProject?.setting?.batchSetting?.dataCount && <Text mb={2}>Data Counts: {currentProject?.setting?.batchSetting?.dataCount}</Text>}
        {currentProject?.setting?.batchSetting?.firstData && <Text mb={2}>First Data: {currentProject?.setting?.batchSetting?.firstData}</Text>}
      </Box>

      <HStack spacing={4} mb={8} mt={8}>
        {renderCancelButton ? renderCancelButton(): null}
        {renderSubmitButton ? renderSubmitButton(): null}
      </HStack>
    </>
  );
}

export default ProjectBatchSettingForm;