export const devlogger = (title, content) => {
  if (process.env.REACT_APP_BACKEND_URL === 'http://127.0.0.1:8000') {
    if (title === undefined) {
      console.log(`${content}`);
    } else {
      console.log(`${title}: `, content);
    }
  }
}

export const errlogger = (title, content) => {
  if (title === undefined) {
    console.error(`${content}`);
  } else {
    console.error(`${title}: `, content);
  }
}
