import axios from 'axios';
import { devlogger } from './Logger';

const BASE_URL = process.env.REACT_APP_BACKEND_URL;

// NOTE: please include the Request suffix in the function name
//       to easier differenciate between the API functions and
//       the other type of functions in the project

// User Authentication related APIs
export async function registerUserRequest(username, password, accessCode) {
  // console.log("registerUser username: ", username);
  // console.log("registerUser password: ", password);
  // console.log("registerUser accessCode: ", accessCode);

  const response = await axios.post(`${BASE_URL}/tasker/register_user/`,
    { username, password, access: accessCode },
    { headers: { 'Content-Type': 'application/json' } });

  return response;
}

export async function fetchTokenRequest(username, password) {
  // console.log("fetchToken username: ", username);
  // console.log("fetchToken password: ", password);

  const response = await axios.post(`${BASE_URL}/tasker/token/`,
    { username: username, password: password },
    { headers: { 'Content-Type': 'application/json' } });

  return response;
}

export async function refreshTokenRequest(authTokens) {
  // console.log("refreshToken: ", authTokens);

  const response = await axios.post(`${BASE_URL}/tasker/token/refresh/`,
    { refresh: authTokens?.refresh },
    { headers: { 'Content-Type': 'application/json' } });

  return response;
}

// User Profile related APIs

export async function fetchProfileRequest(authTokens) {
  // console.log("fetchProfileRequest authTokens: ", authTokens);
  // console.log("fetchProfileRequest authTokens.access: ", authTokens.access);

  const response = await axios.get(`${BASE_URL}/tasker/profile/`,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function updateTaskerProfileRequest(authTokens, profile) {
  // console.log("updateTaskerProfile authTokens: ", authTokens);
  // console.log("updateTaskerProfile profile: ", profile);

  const response = await axios.put(`${BASE_URL}/tasker/update_profile/`,
    profile,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

// Project related APIs

export async function createNewProjectRequest(authTokens, project) {
  // console.log("createProject project: ", project);
  // console.log("createProject authTokens: ", authTokens);

  const response = await axios.post(`${BASE_URL}/tasker/create_project/`,
    project,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function fetchProjectsByTaskerRequest(authTokens, taskerId) {
  // console.log("fetchProjects authTokens: ", authTokens);
  // console.log("fetchProjects taskerId: ", taskerId);

  const response = await axios.get(`${BASE_URL}/tasker/fetch_projects_by_tasker/?tasker_id=${taskerId}`,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function renderPreviewLayoutRequest(authTokens, layout, firstData) {
  // console.log("fetchPreviewLayout authTokens: ", authTokens);

  const response = await axios.post(`${BASE_URL}/tasker/render_preview_layout/`,
    { layout, firstData },
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function updateProjectSettingRequest(authTokens, project) {
  devlogger("updateProjectSetting project: ", project);
  const response = await axios.put(`${BASE_URL}/tasker/update_project/`,
    project,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
};

// Batch related APIs

// Note: for MVP we could skip the batch, sample, and annotation database 
//       and directly use the S3 bucket to store the input and output data
// export async function publishBatchByProjectRequest(authTokens, project) {
//   console.log("publishBatchByProject project: ", project);
//   console.log("publishBatchByProject authTokens: ", authTokens);

//   const response = await axios.post(`${BASE_URL}/tasker/publish_batch_by_project/`,
//   project,
//   { headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer ' + String(authTokens.access) } });

//   return response;
// }

export async function publishProjectRequest(authTokens, project) {
  // console.log("publishBatchByProject project: ", project);
  // console.log("publishBatchByProject authTokens: ", authTokens);

  const response = await axios.post(`${BASE_URL}/tasker/publish_project/`,
    project,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function fetchTasksByProjectRequest(authTokens, projectId) {
  // console.log("fetchTasksByProject authTokens: ", authTokens);
  // console.log("fetchTasksByProject projectId: ", projectId);

  const response = await axios.get(`${BASE_URL}/tasker/fetch_tasks_by_project/?project_id=${projectId}`,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function fetchTasksByProjectRequestPage(authTokens, projectId, page, itemsPerPage) {
  const response = await axios.get(`${BASE_URL}/tasker/fetch_tasks_by_project_page/?project_id=${projectId}&page=${page}&items_per_page=${itemsPerPage}`,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

// export async function createHumanInTheLoopAnnotationJobRequest(authTokens, project) {
//   console.log("createHumaInTheLoopAnnotation project: ", project);

//   const response = await axios.post(`${BASE_URL}/tasker/create_hitl_annotation_job_by_project`,
//   project,
//   { headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer ' + String(authTokens.access) } });

//   return response;
// }

// export async function fetch_hitl_annotation_result_by_project(authTokens, projectId) {
//   console.log("fetch_hitl_annotation_result_by_project projectId: ", projectId);

//   const response = await axios.get(`${BASE_URL}/tasker/fetch_hitl_annotation_result_by_project/?project_id=${projectId}`,
//   { headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer ' + String(authTokens.access) } });

//   return response;
// }

// export async function captionGeminiRequest(file_name) {
//   const response = await axios.post(`${BASE_URL}/tasker/caption_gemini/`,
//     { file_name: file_name });
//   return response;
// }

// Payment related APIs

export async function calculateProjectCostRequest(authTokens, projectId) {
  const response = await axios.post(`${BASE_URL}/tasker/calculate_project_cost/`,
  { projectId },
  { headers: { 'Content-Type': 'application/json', 'Authorization':'Bearer ' + String(authTokens.access) } });

  return response;
};

export async function createCheckoutSessionRequest(authTokens, name, amount) {
  // console.log("createCheckoutSession amount: ", amount);

  const response = await axios.post(
    `${BASE_URL}/tasker/create_checkout_session/`,
    { name, amount },
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });
  return response;
}

export async function handleSessionRequest(authTokens, userId, sessionId, amount) {
  // console.log("createProject project: ", projectId);

  const response = await axios.post(`${BASE_URL}/tasker/handle_session/`,
    { userId, sessionId, amount },
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function payWithBalanceRequest(authTokens, userId, projectId, amount) {
  const response = await axios.post(`${BASE_URL}/tasker/pay_with_balance/`,
    { userId, projectId, amount },
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function requestForUploadPresignedUrlRequest(authTokens, group, expiration, files) {
  // Example Request body: {group: "animation dataset", expiration: 604800, files: {{file: "test.mp4", path, type}}}
  // Example Response body: {files: {{file: "test.mp4", url: "https://bucket.s3.amazonaws.com/test.mp4?AWSAccessKeyId=AKIAIOSFODNN7EXAMPLE&Expires=1609459200&Signature=QZTb7%2FZ%2F%2F%2F%3D", path: "tasker-upload/1234-dataset-ABCD1234/test.mp4"}}}

  const response = await axios.post(`${BASE_URL}/tasker/request_upload_presigned_url/`,
    { group, expiration, files },
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function requestForDownloadPresignedUrlRequest(authTokens, expiration, files) {
  // Example Request body: {expiration: 604800, files: {{file, path: "tasker-upload/1234-dataset-ABCD1234/test.mp4", type}}}
  // Example Response body: {files: {{path: "tasker-upload/1234-dataset-ABCD1234/test.mp4", url: "https://bucket.s3.amazonaws.com/test.mp4?AWSAccessKeyId=AKIAIOSFODNN7EXAMPLE&Expires=1609459200&", size: "1234"}}}

  const response = await axios.post(`${BASE_URL}/tasker/request_download_presigned_url/`,
    { expiration, files },
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}

export async function uploadLayoutToS3(authTokens, user, title, path, input) {
  const response = await axios.post(`${BASE_URL}/layout/add_layout/`,
    {
      taskerId: user.user_id,
      setting: JSON.stringify({
        title,
        layout: path,
        input,
      })
    },
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });
  return response
}

export async function fetchLayoutsFromS3(authTokens, user) {
  // console.log("fetchTasksByProject authTokens: ", authTokens);
  // console.log("fetchTasksByProject projectId: ", projectId);

  const response = await axios.get(`${BASE_URL}/layout/get_layouts/?tasker_id=${user.user_id}`,
    { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + String(authTokens.access) } });

  return response;
}