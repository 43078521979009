/* global BigUint64Array */

export const humanToUnixMs = (humanTime) => {
  const unixMs = new Date(humanTime).getTime();
  return unixMs;
}

export const unixMsToHuman = (unixMs) => {
  const humanTime = new Date(unixMs).toLocaleString();
  return humanTime;
}

export function convertToMinutes(value, unit) {
  let minutes = 0;

  if (unit === 'minutes') {
    minutes = value;
  } else if (unit === 'hours') {
    minutes = value * 60;
  } else if (unit === 'days') {
    minutes = value * 1440;
  }

  return minutes;
}

export function convertFromMinutes(minutes) {
  let value = 0;
  let unit = "days";

  if (minutes >= 1440 && minutes % 1440 === 0) {
    value = minutes / 1440;
    unit = "days";
  } else if (minutes >= 60 && minutes % 60 === 0) {
    value = minutes / 60;
    unit = "hours";
  } else {
    value = minutes;
    unit = "minutes";
  }

  return [value, unit];
}

export function generateUUID() {
  // TODO: uncomment the below line for production
  // return crypto.getRandomValues(new BigUint64Array(1))[0];
  return 1;
}

export function convertCentToDollar(cent) {
  return cent / 100;
}

export function convertDollarToCent(dollar) {
  return dollar * 100;
}

export function parseArrayToString(a) {
  return a.join(',');
}

export function parseOptionsToString(a) {
  // console.log("a: ", a);
  return a.map(option => option.value).join(',');
}

export function parseOptionToString(o) {
  // console.log("o: ", o);
  return o.value;
}

export function parseStringToArray(s) {
  return s.split(',').filter(Boolean);
}

export function convertFromStage(s) {
  if (s === 'PROGRESS') {
    return 'In Progress';
  } else if(s === 'REVIEW') {
    return 'In Review';
  } else if (s === 'COMPLETED') {
    return 'Completed';
  } else {
    return 'Unknown';
  }
}

export function getApprovalRateRequirementOptions() {
  const options = Array.from({ length: 99 }, (_, i) => {
    const value = i + 1;
    return {
      label: `${value}%`,
      value: value,
      target: {
        name: "approvalRateRequirement",
        value: {
          label: `${value}%`,
          value: value,
        },
      },
    };
  });

  return options;
}

export function getSupportedCountryOptions() {
  const options = [
    {
      label: "United States",
      value: "US",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "United States",
      //     value: "US",
      //   },
      // },
    },
    {
      label: "Canada",
      value: "CA",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "Canada",
      //     value: "CA",
      //   },
      // },
    },
    {
      label: "United Kingdom",
      value: "GB",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "United Kingdom",
      //     value: "GB",
      //   },
      // },
    },
    {
      label: "China",
      value: "CH",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "China",
      //     value: "CH",
      //   },
      // },
    },
    {
      label: "India",
      value: "IN",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "India",
      //     value: "IN",
      //   },
      // },
    },
    {
      label: "Japan",
      value: "JP",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "Japan",
      //     value: "JP",
      //   },
      // },
    },
    {
      label: "South Korea",
      value: "KR",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "South Korea",
      //     value: "KR",
      //   },
      // },
    },
    {
      label: "Singapore",
      value: "SG",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "Singapore",
      //     value: "SG",
      //   },
      // },
    },
    {
      label: "Vietnam",
      value: "VN",
      // target: {
      //   name: "locationRequirement",
      //   value: {
      //     label: "Vietnam",
      //     value: "VN",
      //   },
      // },
    },
  ];
  return options;
};

export function getTaskCompletionOption() {
  const options = [
    {
      label: "50",
      value: 50,
      target: {
        name: "taskCompletionRequirement",
        value: {
          label: "50",
          value: 50,
        },
      },
    },
    {
      label: "100",
      value: 100,
      target: {
        name: "taskCompletionRequirement",
        value: {
          label: "100",
          value: 100,
        },
      },
    },
    {
      label: "500",
      value: 500,
      target: {
        name: "taskCompletionRequirement",
        value: {
          label: "500",
          value: 500,
        },
      },
    },
    {
      label: "1000",
      value: 1000,
      target: {
        name: "taskCompletionRequirement",
        value: {
          label: "1000",
          value: 1000,
        },
      },
    },
    {
      label: "5000",
      value: 5000,
      target: {
        name: "taskCompletionRequirement",
        value: {
          label: "5000",
          value: 5000,
        },
      },
    },
    {
      label: "10000",
      value: 10000,
      target: {
        name: "taskCompletionRequirement",
        value: {
          label: "10000",
          value: 10000,
        },
      },
    },
  ]

  return options;
}