import React from 'react';
import { Container } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Login from './components/Login';
import Signup from './components/Signup';
import Home from './components/Home';
import ManageBatches from './components/ManageBatches';
import ManageSamples from './components/ManageSamples';
import ViewFeedback from './components/ViewFeedback';
import PublishBatch from './components/PublishBatch';
import ProjectForm from './components/ProjectForm';
import PrivateRoute from './common/PrivateRoute';
import { AuthProvider } from './common/AuthContext';
import { ProjectProvider } from './common/ProjectContext';
import Profile from './components/Profile';
import DatasetImport from './components/DatasetImport';
import LayoutBuilder from './components/LayoutBuilder';
import Success from './components/Success';
import Landing from './components/Landing';

function App() {
  return (
    <Router>
      <Container maxW="100%" p={0}> {/* Use a Container to constrain and center content */}
        <AuthProvider>
          <ProjectProvider>
            <Routes>
              <Route path="/" element={<Landing />} />
              <Route path="/home" element={<PrivateRoute><Home /></PrivateRoute>} />
              <Route path="/project/:projectId" element={<PrivateRoute><ProjectForm /></PrivateRoute>} /> 
              <Route path="/manage-batches/:projectId" element={<PrivateRoute><ManageBatches /></PrivateRoute>} />
              <Route path="/manage-samples/:projectId" element={<PrivateRoute><ManageSamples /></PrivateRoute>} />
              <Route path="/publish-batch/:projectId" element={<PrivateRoute><PublishBatch /></PrivateRoute>} />
              <Route path="/view-feedback/:projectId" element={<PrivateRoute><ViewFeedback /></PrivateRoute>} />
              <Route path="/profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
              <Route path="/dataset-import" element={<PrivateRoute><DatasetImport /></PrivateRoute>}/>
              <Route path="/layout-builder" element={<PrivateRoute><LayoutBuilder /></PrivateRoute>}/>
              <Route path="/success/:sessionId/:amount" element={<PrivateRoute><Success /></PrivateRoute>} />
              
              <Route path="/login" element={<Login />} />
              <Route path="/signup" element={<Signup />} />
            </Routes>
          </ProjectProvider>
        </AuthProvider>
      </Container>
    </Router>
  );
}

export default App;
