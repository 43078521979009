import React, { useEffect, useContext } from 'react';

import { 
  FormControl, 
  FormLabel, 
  Text, 
  HStack, 
  Box, 
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import ProjectContext from '../../common/ProjectContext';

function ProjectAnnotatorSettingForm({ renderSubmitButton, renderCancelButton }) {
  const { currentProject, editSetting } = useContext(ProjectContext);

  const getAnnotatorTypeOptions = () => {
    const options = [
      {
        label: "DataLance Internal Annotator",
        value: "internal",
        target: {
          name: "annotatorType",
          value: {
            label: "DataLance Internal Annotator",
            value: "internal",
          }
        },
      },
      {
        label: "Amazon MTurk Annotator",
        value: "mturk",
        target: {
          name: "annotatorType",
          value: {
            label: "Amazon MTurk Annotator",
            value: "mturk",
          }
        },
      },
      {
        label: "Objectways",
        value: "objectways",
        target: {
          name: "annotatorType",
          value: {
            label: "Objectways",
            value: "objectways",
          }
        },
      },
      {
        label: "SmartOne Team",
        value: "smartone",
        target: {
          name: "annotatorType",
          value: {
            label: "SmartOne Team",
            value: "smartone",
          }
        },
      },
      {
        label: "Cognito",
        value: "cognito",
        target: {
          name: "annotatorType",
          value: {
            label: "Cognito",
            value: "cognito",
          }
        },
      },
      {
        label: "AI Workspace",
        value: "aiworkspace",
        target: {
          name: "annotatorType",
          value: {
            label: "AI Workspace",
            value: "aiworkspace",
          }
        },
      },
      {
        label: "iMerit",
        value: "imerit",
        target: {
          name: "annotatorType",
          value: {
            label: "iMerit",
            value: "imerit",
          }
        },
      },
      {
        label: "Deepen",
        value: "deepen",
        target: {
          name: "annotatorType",
          value: {
            label: "Deepen",
            value: "deepen",
          }
        },
      },
    ]
    return options;
  };

  const handleAnnotatorSettingChange = (event) => {
    // console.log("ProjectAnntatorSettingForm handleAnnotatorSettingChange event: ", event);

    editSetting("annotatorSetting", event.target.name, event.target.value);
  }

  useEffect(() => {
    if (currentProject?.setting?.annotatorSetting?.annotatorType === undefined || currentProject?.setting?.annotatorSetting?.annotatorType === null) {
      editSetting("annotatorSetting", "annotatorType", {
        name: "annotatorType",
        value: {
          label: "DataLance Internal Annotator",
          value: "internal",
        }
      });
    }

    if (currentProject?.setting?.annotatorSetting?.annotatorReward === undefined || currentProject?.setting?.annotatorSetting?.annotatorReward === null) {
      editSetting("annotatorSetting", "annotatorReward", 10);
    } 

    if (currentProject?.setting?.annotatorSetting?.annotatorAssignment === undefined || currentProject?.setting?.annotatorSetting?.annotatorAssignment === null) {
      editSetting("annotatorSetting", "annotatorAssignment", 1);
    }
    
    if (currentProject?.setting?.annotatorSetting?.annotatorTimeAllotted === undefined || currentProject?.setting?.annotatorSetting?.annotatorTimeAllotted === null) {
      editSetting("annotatorSetting", "annotatorTimeAllotted", 30);
    }

    if (currentProject?.setting?.annotatorSetting?.annotatorTaskExpiresIn === undefined || currentProject?.setting?.annotatorSetting?.annotatorTaskExpiresIn === null) {
      editSetting("annotatorSetting", "annotatorTaskExpiresIn", 7);
    }
  });

  return (
    <>
      <Text fontWeight="bold" fontSize="xl" color="#5C9CD5" mb={4} pt={4}>Define your annotator requirement and configuration</Text>

      <FormControl id="annotatorType">
        <FormLabel>Annotator Type</FormLabel>
          <Select
            value={currentProject?.setting?.annotatorSetting?.annotatorType}
            name="annotatorType"
            onChange={handleAnnotatorSettingChange}
            height="200px"
            colorScheme="linkedin" // The global color scheme
            options={getAnnotatorTypeOptions()}
        />
        <Text mt={1} color="gray.600"> Select the type of human in the loop annotator for your project.</Text>
      </FormControl>

      <FormControl id="annotatorReward">
        <FormLabel>Set Reward per Assignment for Annotator</FormLabel>
        <Box p={0}>
          <InputGroup>
            <InputLeftAddon children="$" />
            <NumberInput
              name='annotatorReward'
              step={0.01}
              min={0.01}
              precision={2}
              value={currentProject?.setting?.annotatorSetting?.annotatorReward ? currentProject.setting.annotatorSetting.annotatorReward / 100: 0.10}
              onChange={(event) => {
                // console.log("annotatorReward event: ", event);
                // console.log("annotatorReward typeof(event): ", typeof(event));

                const newEvent = {
                  target: {
                    name: "annotatorReward",
                    value: Math.round(parseFloat(event) * 100),
                  }
                };

                handleAnnotatorSettingChange(newEvent);
              }}
              w="120px"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </Box>
        <Text mt={1} color="gray.600">This is how much an annotator will be paid for completing an assignment. Consider how long it will take a Worker to complete each assignment.</Text>
      </FormControl>

      <FormControl id="annotatorAssignment">
        <FormLabel>Assignments per Task</FormLabel>
        <InputGroup>
          <NumberInput
            name="annotatorAssignment"
            step={1}
            min={1}
            max={9}
            value={currentProject?.setting?.annotatorSetting?.annotatorAssignment ? currentProject.setting.annotatorSetting.annotatorAssignment: 1}
            onChange={(event) => {
              // console.log("annotatorAssignment event: ", event);
              // console.log("annotatorAssignment typeof(event): ", typeof(event));

              const newEvent = {
                target: {
                  name: "annotatorAssignment",
                  value: parseInt(event),
                }
              };
      
              handleAnnotatorSettingChange(newEvent);
            }}
            w="120px"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
        </InputGroup>
        <Text mt={1} color="gray.600">How many unique annotator do you want to work on each task?</Text>
      </FormControl>

      <FormControl id="annotatorTimeAllotted">
        <FormLabel>Time allotted per assignment</FormLabel>
        <Box p={0}>
          <InputGroup>
            <InputLeftAddon children="Minutes" />
            <NumberInput
              min={1}
              max={480}
              value={currentProject?.setting?.annotatorSetting?.annotatorTimeAllotted ? currentProject.setting.annotatorSetting.annotatorTimeAllotted: 30}
              onChange={(valueString) => {
                const newEvent = {
                  target: {
                    name: "annotatorTimeAllotted",
                    value: parseInt(valueString),
                  }
                };

                handleAnnotatorSettingChange(newEvent);
              }}
              w="120px"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </Box>
        <Text mt={1} color="gray.600">Maximum time an annotator has to work on a single task. Be generous so that Workers are not rushed.</Text>
      </FormControl>

      <FormControl id="annotatorTaskExpiresIn">
        <FormLabel>Task expires in</FormLabel>
        <Box p={0}>
          <InputGroup>
            <InputLeftAddon children="Days" />
            <NumberInput
              min={1}
              max={30}
              value={currentProject?.setting?.annotatorSetting?.annotatorTaskExpiresIn ? currentProject.setting.annotatorSetting.annotatorTaskExpiresIn: 7}
              onChange={(valueString) => {
                const newEvent = {
                  target: {
                    name: "annotatorTaskExpiresIn",
                    value: parseInt(valueString),
                  }
                };

                handleAnnotatorSettingChange(newEvent);
              }}
              w="120px"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </Box>
        <Text mt={1} color="gray.600">Maximum time your task will be available to annotator.</Text>
      </FormControl>

      <HStack spacing={4} mb={8} mt={8}>
        {renderCancelButton ? renderCancelButton(): null}
        {renderSubmitButton ? renderSubmitButton(): null}
      </HStack>
    </>
  )
}

export default ProjectAnnotatorSettingForm;