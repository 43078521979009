export const IMAGE_CLASSIFICATION_LAYOUT = {
  "name": "Image Classification",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>
  <crowd-form answer-format="flatten-objects">
      <crowd-image-classifier 
          src={{ task.input.taskObject | escape_once }}
          categories="['Cat', 'Dog', 'Bird', 'None of the Above']"
          header="Choose the correct category"
          name="category">
          <short-instructions>
              <p>Read the task carefully and inspect the image.</p>
              <p>Choose the appropriate label that best suits the image.</p>
          </short-instructions>
          <full-instructions header="Classification Instructions">
              <p>Read the task carefully and inspect the image.</p>
              <p>Choose the appropriate label that best suits the image.</p>
          </full-instructions>
  
      </crowd-image-classifier>
  </crowd-form>`,
  "input": "https://dl-public-samples.s3.amazonaws.com/image-samples-1/two-birds.jpg"
};

export const IMAGE_TEXT_GENERATION_LAYOUT = {
  "name": "Image Text Generation",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>
  <crowd-form answer-format="flatten-objects">
      <crowd-image-classifier 
          src={{ task.input.taskObject | escape_once }}
          categories="['Cat', 'Dog', 'Bird', 'None of the Above']"
          header="Choose the correct category"
          name="category">
          <short-instructions>
              <p>Read the task carefully and inspect the image.</p>
              <p>Choose the appropriate label that best suits the image.</p>
          </short-instructions>
          <full-instructions header="Classification Instructions">
              <p>Read the task carefully and inspect the image.</p>
              <p>Choose the appropriate label that best suits the image.</p>
          </full-instructions>
  
      </crowd-image-classifier>
  </crowd-form>
  `,
  "input": "https://dl-public-samples.s3.amazonaws.com/image-samples-1/two-birds.jpg"
};

export const IMAGE_BOUNDING_BOX_LAYOUT = {
  "name": "Image Bounding Box",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>
  <crowd-form answer-format="flatten-objects">
      <crowd-bounding-box 
          src={{ task.input.taskObject | escape_once }}
          labels="['Cat', 'Dog', 'Bird']"
          header="Draw bounding boxes around the requested items"
          name="annotatedResult">
          <short-instructions>Draw boxes around the requested target of interest.</short-instructions>
          <full-instructions header="Bounding Box Instructions">
              <p>Use the bounding box tool to draw boxes around the requested target of interest:</p>
              <ol>
                  <li>Draw a rectangle using your mouse over each instance of the target.</li>
                  <li>Make sure the box does not cut into the target, leave a 2 - 3 pixel margin</li>
                   <li>When targets are overlapping, draw a box around each object, include all 
                        contiguous parts of the target in the box. Do not include parts that are completely 
                        overlapped by another object.</li>
                   <li>Do not include parts of the target that cannot be seen, even though you think you 
                        can interpolate the whole shape of the target.</li>
                   <li>Avoid shadows, they're not considered as a part of the target.</li>
                   <li>If the target goes off the screen, label up to the edge of the image.</li>
              </ol>
          </full-instructions>
      </crowd-bounding-box>
  </crowd-form>
  `,
  "input": "https://dl-public-samples.s3.amazonaws.com/image-samples-1/two-birds.jpg"
};

export const IMAGE_SEMANTIC_SEGMENTATION_LAYOUT = {
  "name": "Image Semantic Segmentation",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>
  <crowd-form>
    <crowd-semantic-segmentation
      name="crowd-semantic-segmentation"
      src={{ task.input.taskObject | escape_once }}
      header="Color in the requested items in the image"
      labels="['Cat', 'Dog', 'Bird']"
    >
      <short-instructions>
        <p>Use the tools to label the requested items in the image</p>
      </short-instructions>
      <full-instructions header="Segmentation Instructions">
        <ol>
            <li><strong>Read</strong> the task carefully and inspect the image.</li>
            <li><strong>Read</strong> the options and review the examples provided to understand more about the labels.</li>
            <li><strong>Choose</strong> the appropriate label that best suits the image.</li>
        </ol>
      </full-instructions>
    </crowd-semantic-segmentation>
  </crowd-form>
  `,
  "input": "https://dl-public-samples.s3.amazonaws.com/image-samples-1/two-birds.jpg"
};

export const VIDEO_CLASSIFICATION_LAYOUT = {
  "name": "Video Classification",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>
  <crowd-form answer-format="flatten-objects">
      <crowd-classifier 
        name="category"
        categories="['Exercise', 'Dance', 'Other']"
        header="What action is in the video?"
      >
  
        <classification-target>
           <video width="100%" height="315" controls>
            <source src={{ task.input.taskObject | escape_once }} type="video/mp4">
          </video> 
        </classification-target>
        <short-instructions>
          <p>Read the task carefully and inspect the video.</p>
          <p>Choose the appropriate label that best suits the video.</p>
        </short-instructions>
        <full-instructions header="Video Classification Instructions">
          <p>Read the task carefully and inspect the video.</p>
          <p>Choose the appropriate label that best suits the video.</p>
        </full-instructions>
  
      </crowd-classifier>
  </crowd-form>`,
  "input": "https://dl-public-samples.s3.amazonaws.com/video-sample-1/Untitled.mp4"
}

export const VIDEO_TEXT_GENERATION_LAYOUT = {
  "name": "Video Text Generation",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>

  <crowd-form>
      <p>
          <strong>Instructions: </strong>Given an video, write a sentence summarizing what it shows
      </p>
      <p>
          Use punctuation and don't mention that you're describing an image.
      </p>
      <p>
          View the instructions for detailed instructions and examples.
      </p>
      <p>
        <video width="100%" height="315" controls>
          <source src={{ task.input.taskObject | escape_once }} type="video/mp4">
        </video> 
      </p>
      <crowd-input name="summary" label="Summarize the video with a sentence..."></crowd-input>
  </crowd-form>`,
  "input": "https://dl-public-samples.s3.amazonaws.com/video-sample-1/Untitled.mp4"
}

export const MESH_CLASSIFICATION_LAYOUT = {
  "name": "Mesh Classification",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>
  <script src="https://cdn.babylonjs.com/babylon.js"></script>
  <script src="https://preview.babylonjs.com/loaders/babylonjs.loaders.min.js"></script>
  <style>
      #renderCanvas {
          width: 50%;
          touch-action: none;
      }
      #labelingInterface {
          padding: 20px; /* Adjust as needed */
      }
      #loadingSpinner {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          border: 16px solid #f3f3f3; /* Light grey */
          border-top: 16px solid #3498db; /* Blue */
          border-radius: 50%;
          width: 120px;
          height: 120px;
          animation: spin 2s linear infinite;
      }
      @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }
  </style>
  <div id="labelingInterface">
    <crowd-form>
      
        <canvas id="renderCanvas"></canvas>
        <div id="loadingSpinner"></div>
        
        <script>
            const canvas = document.getElementById("renderCanvas");
            const engine = new BABYLON.Engine(canvas, true);
            const loadingSpinner = document.getElementById("loadingSpinner");
            const createScene = function () {
              const scene = new BABYLON.Scene(engine);
              const camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 2, new BABYLON.Vector3(0,0,5), scene);
              camera.attachControl(canvas, true);
              const animationFile = "{{ task.input.taskObject | escape_once}}";
              const tempElement = document.createElement("textarea");
              tempElement.innerHTML = animationFile;
              const decoded_url = tempElement.textContent;
              loadingSpinner.style.display = "block";
              BABYLON.SceneLoader.ImportMesh("", decoded_url, "", scene, function (newMeshes) {
                  scene.createDefaultCameraOrLight(true, true, true);
                  loadingSpinner.style.display = "none";
              }, null, function (error) {
                  console.error("An error occurred while fetching the animation file:", error);
                  loadingSpinner.style.display = "none";
              });
              return scene;
            };
            const scene = createScene();
            engine.runRenderLoop(function () {
                    scene.render();
            });
            window.addEventListener("resize", function () {
                    engine.resize();
            });
        </script>
        <div id="progressBarContainer" style="width: 100%; background-color: #f3f3f3;">
          <div id="progressBar" style="width: 0%; height: 20px; background-color: #4CAF50;"></div>
        </div>
        <p> 
          <strong>Instructions:</strong>
        </p>
      
        <short-instructions header="Animation quality judgment instructions">
          <p>
              <strong style="color: rgb(230, 0, 0);">the penetration is not a factor of quality judgment.</strong>
          </p>
          <p>
              <strong style="color: rgb(230, 0, 0);">please ignore the penetration.</strong>
          </p>
          
          <p>   <strong>quality judgment should base on whether the movements is:</strong>
      
              <ul>
              <li>Feasibility: whether it is a human achiveable movement</li>
              <li>Fluency: whether the movement is jittering</li>
              <li>Naturalness: whether the movement is natural</li>
              </ul>
          </p>
      </short-instructions>
        <crowd-classifier 
      name="qualityJudge"
      categories="['very good','good','neutral', 'bad', 'very Bad']"
      header=" ">
      
      
        <classification-target>
          <p>how would you rate this robot movement's quality?</p>
          <div style="display: none;">{{ task.input.taskObject | escape_once}}  </div>
      </classification-target>
      
  </crowd-classifier>
        
    </crowd-form>
  </div>`,
  "input": "https://dl-public-samples.s3.amazonaws.com/animation-sample-1/4.glb"
}

export const MESH_TEXT_GENERATION_LAYOUT = {
  "name": "Mesh Text Generation",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>
  <script src="https://cdn.babylonjs.com/babylon.js"></script>
  <script src="https://preview.babylonjs.com/loaders/babylonjs.loaders.min.js"></script>
  
  <style>
      #renderCanvas {
          width: 50%;
          touch-action: none;
      }
      #labelingInterface {
          padding: 20px; /* Adjust as needed */
      }
      #loadingSpinner {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          border: 16px solid #f3f3f3; /* Light grey */
          border-top: 16px solid #3498db; /* Blue */
          border-radius: 50%;
          width: 120px;
          height: 120px;
          animation: spin 2s linear infinite;
      }
      @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
      }
  </style>
  
  <div id="labelingInterface">
    <crowd-form>
        <canvas id="renderCanvas"></canvas>
  
        <div id="loadingSpinner"></div>
  
        <script>
            const canvas = document.getElementById("renderCanvas");
            const engine = new BABYLON.Engine(canvas, true);
            const loadingSpinner = document.getElementById("loadingSpinner");
  
            const createScene = function () {
              const scene = new BABYLON.Scene(engine);
              const camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 2, new BABYLON.Vector3(0,0,5), scene);
              camera.attachControl(canvas, true);
              const animationFile = "{{ task.input.taskObject | escape_once }}";
              const tempElement = document.createElement("textarea");
              tempElement.innerHTML = animationFile;
              const decoded_url = tempElement.textContent;
              loadingSpinner.style.display = "block";
              BABYLON.SceneLoader.ImportMesh("", decoded_url, "", scene, function (newMeshes) {
                  scene.createDefaultCameraOrLight(true, true, true);
                  loadingSpinner.style.display = "none";
              }, null, function (error) {
                  console.error("An error occurred while fetching the animation file:", error);
                  loadingSpinner.style.display = "none";
              });
              return scene;
            };
            const scene = createScene();
            engine.runRenderLoop(function () {
                    scene.render();
            });
            window.addEventListener("resize", function () {
                    engine.resize();
            });
        </script>
  
        <div id="progressBarContainer" style="width: 100%; background-color: #f3f3f3;">
          <div id="progressBar" style="width: 0%; height: 20px; background-color: #4CAF50;"></div>
        </div>
       
        <p> 
          <strong>Instructions:</strong>
          Given the animation, write one or few sentences with 50 to 500 characters describing the motion. Note that you are able to drag and zoom in the pane for different angles.
        </p>
      
        <short-instructions header="Animation Captioning instructions">
          <p>
              <strong>Guidelines:</strong>
      
              <ul>
              <li>50 to 500 characters</li>
              <li>At least 2 aspects (i.e., action type and style) from the previous section are included</li>
              <li>A verb or a noun representing the motion</li>
              <li>An adjective or an adverb describing/qualifying the motion</li>
              </ul>
          </p>
  
          <p>
              <strong style="color: rgb(0, 138, 0);">Good Descriptions:</strong>
              <ul>
                  <li>A person stands up from the ground, walks in a clockwise circle, and then sits back on the ground</li>
                  <li>A man walks from side to side while holding his right forearm with left hand, and then walks back</li>
                  <li>A person walks forward and climbs up something on the right hand side</li>
                  <li>Someone holds heads in hands and bends to the left, trying to dodge something.</li>
                  <li>The basketball player leaps explosively towards the basket, extending their arm high above the rim to forcefully slam the ball through the hoop, displaying athleticism and power.</li>
                  <li>The elderly practitioner moves slowly and gracefully, flowing through a sequence of gentle and deliberate Tai Chi movements. With serene focus and deep breathing, he embodies tranquility, balance, and the wisdom of years.</li>
                  <li>Spider-Man effortlessly swings through the city skyline, his body soaring through the air with acrobatic finesse. He shoots webs from his wrists, propelling himself from building to building.</li>
              </ul>
          </p>
          
          <p>
              <strong style="color: rgb(230, 0, 0);">Bad Descriptions:</strong>
              <ul>
                  <li>Over-general: A man walks; Someone tries to dodge something; A person acts like a dog.</li>
              </ul>
          </p>
  
      </short-instructions>
        
        
      <crowd-input name="summary" label="Summarize the image with a sentence..." max-length=500 min-length=50 error-message="Please make sure your input has follow the above guidelines" required></crowd-input>
  
    </crowd-form>
  </div>`,
  "input": "https://dl-public-samples.s3.amazonaws.com/animation-sample-1/4.glb"
}

export const GAUSSIAN_SPLAT_TEXT_GENERATION_LAYOUT = {
  "name": "Gaussian Splat Text Generation",
  "layout": `<script src="https://assets.crowd.aws/crowd-html-elements.js"></script>
  <script src="https://cdn.babylonjs.com/babylon.js"></script>
  <script src="https://preview.babylonjs.com/loaders/babylonjs.loaders.min.js"></script>
  
  <style>
      #renderCanvas {
          width: 75%;
          touch-action: none;
      }
      #labelingInterface {
          padding: 20px; /* Adjust as needed */
      }
      #loadingSpinner {
          position: fixed;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: none;
          border: 16px solid #f3f3f3; /* Light grey */
          border-top: 16px solid #3498db; /* Blue */
          border-radius: 50%;
          width: 120px;
          height: 120px;
          animation: spin 2s linear infinite;
      }
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
  </style>
  
  <div id="labelingInterface">
    <crowd-form>
        <canvas id="renderCanvas"></canvas>
        <div id="loadingSpinner"></div>
        <script>
            const canvas = document.getElementById("renderCanvas");
            const engine = new BABYLON.Engine(canvas, true);
            const loadingSpinner = document.getElementById("loadingSpinner");
            const createScene = function () {
                const scene = new BABYLON.Scene(engine);
                const camera = new BABYLON.ArcRotateCamera("Camera", Math.PI / 2, Math.PI / 2, 2, new BABYLON.Vector3(0,0,5), scene);
                camera.attachControl(canvas, true); 
              
                var light = new BABYLON.HemisphericLight("light", new BABYLON.Vector3(0, 1, 0), scene);
  
                light.intensity = 0.7;
                
                var ground = BABYLON.MeshBuilder.CreateGround("ground", {width: 6, height: 6}, scene);
              
               loadingSpinner.style.display = "block";

               const sceneFile = "{{ task.input.taskObject | escape_once }}";
               
               var gs = new BABYLON.GaussianSplattingMesh("Scene", null, scene);
              gs.loadFileAsync(sceneFile).then(()=>{
                  gs.position.y = 1.7;
                  loadingSpinner.style.display = "none";
              });
  
              return scene;
            };
            const scene = createScene();
            engine.runRenderLoop(function () {
                    scene.render();
            });
            window.addEventListener("resize", function () {
                    engine.resize();
            });
        </script>
       
        <p> 
          <strong>Instructions:</strong>
          Given the 3D scene, write one or few sentences with 10 to 50 word describing it. Note that you are able to drag and zoom in the pane for different angles.
        </p>
      
        <short-instructions header="Scene Captioning instructions">
          <p>
              <strong>Guidelines:</strong>
      
              <ul>
              <li>From 10 to 50 words</li>
              <li>At least 2 aspects (i.e., object type) from the previous section are included</li>
              <li>A noun and an adjective representing the 3D scene</li>
              </ul>
          </p>
  
          <p>
              <strong style="color: rgb(0, 138, 0);">Good Descriptions:</strong>
              <ul>
                  <li>A person stands up from the ground, walks in a clockwise circle, and then sits back on the ground</li>
                  <li>A man walks from side to side while holding his right forearm with left hand, and then walks back</li>
                  <li>A person walks forward and climbs up something on the right hand side</li>
                  <li>Someone holds heads in hands and bends to the left, trying to dodge something.</li>
                  <li>The basketball player leaps explosively towards the basket, extending their arm high above the rim to forcefully slam the ball through the hoop, displaying athleticism and power.</li>
                  <li>The elderly practitioner moves slowly and gracefully, flowing through a sequence of gentle and deliberate Tai Chi movements. With serene focus and deep breathing, he embodies tranquility, balance, and the wisdom of years.</li>
                  <li>Spider-Man effortlessly swings through the city skyline, his body soaring through the air with acrobatic finesse. He shoots webs from his wrists, propelling himself from building to building.</li>
              </ul>
          </p>
          
          <p>
              <strong style="color: rgb(230, 0, 0);">Bad Descriptions:</strong>
              <ul>
                  <li>Over-general: A man walks; Someone tries to dodge something; A person acts like a dog.</li>
              </ul>
          </p>
  
      </short-instructions>
        
        
      <crowd-input name="summary" label="Summarize the scene..."></crowd-input>
  
    </crowd-form>
  </div>`,
  "input": "https://dl-public-samples.s3.amazonaws.com/gaussian-splat-samples-1/Halo_Believe.splat"
};