import { Types } from 'aws-sdk/clients/applicationautoscaling';
import { requestForDownloadPresignedUrlRequest, requestForUploadPresignedUrlRequest } from './Api';
import { devlogger, errlogger } from './Logger';

const DownloadSinglePresignUrlFile = async (presignUrl, type) => {
  devlogger("DownloadSinglePresignUrlFile presignUrl", presignUrl);

  const response = await fetch(presignUrl);

  devlogger("DownloadSinglePresignUrlFile response", response);
  const blob = await response.blob();

  devlogger("DownloadSinglePresignUrlFile blob", blob);

  const text = await new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      const text = reader.result;
      devlogger("DownloadSinglePresignUrlFile text", text);
      resolve(text);
    };
    reader.onerror = reject;
    reader.readAsText(blob);
  });

  devlogger("DownloadSinglePresignUrlFile typeof text", typeof text);

  return text;  
}

const UploadSinglePresignUrlFile = async (presignUrl, file, type) => {
  await fetch(presignUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': type,
    },
  }).then((response) => {
    devlogger("UploadSinglePresignUrlFile response", response);

    return response;
  }).catch((error) => {
    errlogger("UploadSinglePresignUrlFile error", error);
  });
}

export const DownloadFile = async (file, path, type, authTokens) => {
 // TODO: request for download presgned URL
  devlogger("DownloadFile path", path);
  devlogger("DownloadFile file", file);
  devlogger("DownloadFile type", type);

  const files = [{ "file": file, "path": path, "type": type}];
  devlogger("DownloadFile files", files);

  return await requestForDownloadPresignedUrlRequest(authTokens, 3600, files)
  .then(async (response) => {
    const respondedFiles = response.data.files;
    const respondedFile = respondedFiles[0];

    devlogger("response", response);
    devlogger("respondedFiles", respondedFiles);
    devlogger("respondedFile", respondedFile);

    const fileText = await DownloadSinglePresignUrlFile(respondedFile.url, type);

    devlogger("DownloadFile fileText", fileText);
    return fileText;
  }).catch((error) => {
    devlogger("DownloadFile error", error);
    errlogger("requestForDownloadPresignedUrlRequest", error);

    return null;
  });  
}

export const UploadFile = async (file, type, authTokens, user) => {
  // TODO: request for upload presgned URL

  devlogger("UploadFile", file);
  devlogger("UploadFile", type);

  // const fileType = file.type || 'text/plain';

  return await requestForUploadPresignedUrlRequest(authTokens, `${user.user_id}-${Date.now()}`, 3600, [{ "file": file.name, "type": type}])
  .then(async (response) => {
    const respondedFiles = response.data.files;
    const respondedFile = respondedFiles[0];

    const url = respondedFile.url;
    const path = respondedFile.path;

    devlogger("respondedFile", respondedFile);
    devlogger("file", file);
    devlogger("url", url);
    devlogger("path", path);

    await UploadSinglePresignUrlFile(url, file, type);
   
    devlogger("path", path);
    return path;
  }).catch((error) => {
    errlogger("requestForUploadPresignedUrlRequest", error);
  });
 }