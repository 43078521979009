
import { loadStripe } from '@stripe/stripe-js';
import AuthContext from '../../common/AuthContext';
import EditProfileModal from '../EditProfileModal';
import { createCheckoutSessionRequest } from '../../common/Api';
import React, { useState, useContext } from 'react';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { Box, Card, CardHeader, CardBody, Heading, Text, Stack, StackDivider, FormControl, FormLabel, Input, Button, InputLeftAddon, InputGroup } from '@chakra-ui/react';
  
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export default function Profile() {
    const { authTokens, profile, isProfileOpen, onProfileOpen, onProfileClose } = useContext(AuthContext);

    const [open, setOpen] = useState(false);
    const [reloadAmount, setReloadAmount] = useState(null);
    const [clientSecret, setClientSecret] = useState('');

    const createStripeForm = async () => {
        setOpen(true)
        const linkResponse = await createCheckoutSessionRequest(authTokens, "Add To Your Datalance Balance", reloadAmount * 100);
        setClientSecret(linkResponse.data)
    }

    return(
        <Box p={8}>
            <Card>
                <CardHeader style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Heading size='md'>Profile</Heading>
                    <Button colorScheme="blue" onClick={onProfileOpen} mr={2} w="180px">
                        Edit
                    </Button>
                </CardHeader>

                <CardBody>
                    <Stack divider={<StackDivider />} spacing='4'>
                    <Box>
                        <Heading size='xs' textTransform='uppercase' mb={4}>
                        Basic Info
                        </Heading>
                        <Text pt='2' fontSize='sm' mb={4}>
                            <b>First Name</b>: {profile.data.firstName}
                        </Text>
                        <Text pt='2' fontSize='sm' mb={4}>
                            <b>Last Name</b>: {profile.data.lastName}
                        </Text>
                        <Text pt='2' fontSize='sm' mb={4}>
                            <b>Country</b>: {profile.data.country}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase' mb={4}>
                        Contact Info
                        </Heading>
                        <Text pt='2' fontSize='sm' mb={4}>
                            <b>Phone Number</b>: {profile.data.phoneNumber}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase' mb={4}>
                        Occupation
                        </Heading>
                        <Text pt='2' fontSize='sm' mb={4}>
                            <b>Industry</b>: {profile.data.industry}
                        </Text>
                        <Text pt='2' fontSize='sm' mb={4}>
                            <b>Company</b>: {profile.data.company}
                        </Text>
                        <Text pt='2' fontSize='sm' mb={4}>
                            <b>Role</b>: {profile.data.role}
                        </Text>
                    </Box>
                    <Box>
                        <Heading size='xs' textTransform='uppercase'>
                        Payment and Balance
                        </Heading>
                        <Text pt='2' fontSize='sm' mb={4}>
                            <b>Balance</b>: ${(profile.balance / 100).toFixed(2)}
                        </Text>
                        <FormControl mb={4}>
                            <FormLabel>Add Balance</FormLabel>
                            <InputGroup>
                                <InputLeftAddon>$</InputLeftAddon>
                                <Input 
                                    width={"20%"}
                                    type="text"
                                    value={reloadAmount}
                                    onChange={(event) => {
                                        const value = event.target.value;
                                        setReloadAmount(value.replace(/[^0-9.]/g, ''));
                                    }}
                                />
                            </InputGroup>
                        </FormControl>
                        <Button colorScheme="blue" onClick={createStripeForm} mr={2} w="180px">
                            Confirm
                        </Button>

                        {
                            open &&
                            <EmbeddedCheckoutProvider
                                stripe={stripePromise}
                                options={{clientSecret}}
                                >
                                <EmbeddedCheckout />
                            </EmbeddedCheckoutProvider>
                        }
                    </Box>
                    </Stack>
                </CardBody>
            </Card>
            <EditProfileModal isOpen={isProfileOpen} onClose={onProfileClose} />
        </Box>
    )
}