import React, { useState, useContext } from 'react';
import { Box, Heading, Input, Button, Text, FormControl, FormLabel, useToast } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { AWSS3StoreFile } from '../../common/AWSS3StoreFile';
import { constructBatch } from '../../common/Constructors';
import { humanToUnixMs } from '../../common/Utils';
// import { publishBatch } from '../../common/Api';
import AuthContext from '../../common/AuthContext';

const AWS = require('aws-sdk');

AWS.config.update({
  accessKeyId: process.env.AWS_ACCESS_KEY_ID, 
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY, 
});

const s3 = new AWS.S3();

function PublishBatch() {
  const navigate = useNavigate();
  const toast = useToast();
  const [batchName, setBatchName] = useState('');
  const [csvData, setCsvData] = useState(null);
  const [batchFile, setBatchFile] = useState(null);
  const { projectId } = useParams();

  const { authTokens } = useContext(AuthContext);

  const handleBatchNameChange = (event) => {
    setBatchName(event.target.value);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    setBatchFile(file);

    const reader = new FileReader();

    reader.onload = (e) => {
      const contents = e.target.result;
      const parsedData = parseCsvData(contents);

      let fileName = file.name
      fileName = fileName.replace('.csv', '');

      if (batchName === '') setBatchName(fileName);
      setCsvData(parsedData);
    };

    reader.readAsText(file);
  };

  const parseCsvData = (csvContent) => {
    const rows = csvContent.split('\n');
    const headers = rows[0].split(',');
    const rowCount = rows.length - 1;
    return {
      headers,
      rowCount,
    };
  };

  const handlePublish = async () => {
    if (batchFile) {
      const batchPath = `batch/${projectId}_${batchName}.csv`;
      const response = await AWSS3StoreFile(batchFile, batchPath);

      const currentTimestamp = new Date().toISOString();

      if (response) {
        const batch = constructBatch(
          11, 
          humanToUnixMs(currentTimestamp),
          "PUBLISHED",
          {
            name: batchName,
            batchPath: batchPath,
          }
        );

        // await publishBatch(authTokens, batch)
        // .then(response => {
        //   console.log("publishBatch response: ", response);
        //   console.log("publishBatch response.status: ", response.status);
        //   if(response.status === 201){
        //     console.log("publishBatch response: ", response);
        //     toast({
        //       title: "Batch published successfully!",
        //       description: "Batch published successfully!",
        //       status: "success",
        //       duration: 9000,
        //       isClosable: true,
        //     });

        //     // TODO: create navigation to manage batch page
        //     // navigate('/');
        //   } else {
        //     toast({
        //       title: "Something went wrong while publishing the batch!",
        //       description: "Something went wrong while publishing the batch!",
        //       status: "error",
        //       duration: 9000,
        //       isClosable: true,
        //     });
        //   }
        // })
        // .catch(error => {
        //   console.log("publishBatch catch error: ", error);
        //   toast({
        //     title: "Something went wrong while publishing the batch!",
        //     description: "Something went wrong while publishing the batch!",
        //     status: "error",
        //     duration: 9000,
        //     isClosable: true,
        //   });
        // });
      }
    };
  };

  return (
    <Box p={4}>
      <Heading as="h1" size="xl" mb={4}>
        Publish Batch
      </Heading>

      <Box border="24px outset rgba(128, 128, 128, 0.25)" p={4} borderRadius="md" w="100%">
        <Text fontWeight="bold" mb={6} fontSize="xl" color="#5C9CD5">Batch Summary</Text>

        <FormControl id="exampleBatchFileUpload">
          <FormLabel>Upload Batch File in CSV</FormLabel>
          <Input type="file" accept=".csv" onChange={handleFileUpload}/>
          <Text mt={1} color="gray.600">Drag & drop your Batch file here, or click to select one from your computer.</Text>
        </FormControl>

        <FormControl id="batchName">
          <FormLabel>Batch Name</FormLabel>
          <Input value={batchName} onChange={handleBatchNameChange} />        
          <Text mt={1} color="gray.600">Provide a name for your batch; batch name is only visiable you not to the annotators.</Text>
        </FormControl>
      </Box>

      {csvData && (
        <>
          <Box>
            <Text mb={2}>Number of Rows: {csvData.rowCount}</Text>
            <Text>Column Headers: {csvData.headers.join(', ')}</Text>
          </Box>

          <Button colorScheme="blue" mt={4} onClick={handlePublish}>
            Publish
          </Button>
        </>
      )}
    </Box>
  );
}

export default PublishBatch;