import React, { useState, useEffect, useContext, useRef } from 'react';
import { Heading, Button, Spinner, List, ListItem, ListIcon, Box,  } from "@chakra-ui/react";
import { CheckCircleIcon, InfoOutlineIcon, WarningIcon, WarningTwoIcon, CloseIcon } from "@chakra-ui/icons";
import { BsCheckCircle, BsCircle, BsExclamationCircle, BsClock } from "react-icons/bs";

import { requestForDownloadPresignedUrlRequest, requestForUploadPresignedUrlRequest } from '../../common/Api';
import { useToast } from "@chakra-ui/react"
import { SuccessToastHelper, ErrorToastHelper } from '../../common/ToastHelper';
import AuthContext from '../../common/AuthContext';
import { devlogger } from '../../common/Logger';

const FileStatus = {
  SELECTED: 'selected',
  UPLOADED: 'uploaded',
  GENERATED: 'generated',
  FAILED: 'failed'
};

const DatasetImportPhase = {
  SELECT: 'select',
  UPLOAD: 'upload',
  GENERATE: 'generate',
  LOADING: 'loading',
  FAILED: 'failed'
}

const DataImport = () => {
  // const [selectedFiles, setSelectedFiles] = useState([]);
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  
  // File object: <file-name>: {file: <file-object>, path: <s3-path>, status: <selected-or-uploaded>, uploadUrl: <presigned-upload-url>, downloadUrl: <presigned-download-url>}
  const [ uploadedFileObjects, setUploadedFileObjects ] = useState([]);
  const [readyForGenerate, setReadyForGenerate] = useState(false);

  const [ group, setGroup ] = useState(null);
  const [ expiration, setExpiration ] = useState(null);
  const [trigger, setTrigger] = useState(false);

  const [phase, setPhase] = useState(DatasetImportPhase.SELECT);

  const toast = useToast();

  const { authTokens, user } = useContext(AuthContext);

  useEffect(() => {
    setExpiration(604800);

    setGroup(`${user.user_id}-${Date.now()}`);
  }, []);

  useEffect(() => {
    const allSelected = Object.values(uploadedFileObjects).every(fileObject => fileObject.status === FileStatus.SELECTED);
    setReadyForGenerate(allSelected && Object.keys(uploadedFileObjects).length > 0);
  }, [uploadedFileObjects]);

  const handleFileChange = async (event) => {

    setPhase(DatasetImportPhase.LOADING);

    if (event.target.files.length === 0 || event.target.files === null || event.target.files >= 100) {
      ErrorToastHelper(toast, "Invalid files selection: must be at least 1 file and less than 100 files")

      return;
    }

    const files = [];
    const fileObjects = {};

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      files.push({ "file": file.name, "type": file.type});
      fileObjects[file.name] = { file: file, status: FileStatus.SELECTED, path: '', uploadUrl: '', downloadUrl: '' };
    }
    
    // Upload file presign url max expiration is an hour
    await requestForUploadPresignedUrlRequest(authTokens, group, 3600, files)
    .then((response) => {
      const respondedFiles = response.data.files;
      for (let i = 0; i < respondedFiles.length; i++) {
        const respondedFile = respondedFiles[i];
        const fileObject = fileObjects[respondedFile.file];
        fileObject.uploadUrl = respondedFile.url;
        fileObject.path = respondedFile.path;
      }

      // console.log("fileObjects: ", fileObjects);

      setUploadedFileObjects(fileObjects);
      setPhase(DatasetImportPhase.UPLOAD);
    })
    .catch((error) => {
      ErrorToastHelper(toast, "Failed to request for presigned upload URL")
      setPhase(DatasetImportPhase.FAILED);
    });
  };

  const downloadFile = (content, fileName, contentType) => {
    const a = document.createElement('a');
    const file = new Blob([content], {type: contentType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };

  const handleUpload = async () => {
    setPhase(DatasetImportPhase.LOADING);
    // console.log("handleUpload uploadedFileObjects: ", uploadedFileObjects)
  
    await Promise.all(Object.values(uploadedFileObjects).map(async (fileObject) => {
      // console.log("handleUpload: ", fileObject.file.name);
      // Get use the presigned upload URL from the fileObject to upload to S3
      return fetch(fileObject.uploadUrl, {
        method: 'PUT',
        body: fileObject.file,
        headers: {
          'Content-Type': fileObject.file.type,
        },
      }).then((response) => {
        // console.log("fetch response: ", response);
        fileObject.status = FileStatus.UPLOADED;
        setTrigger(prev => !prev);
      }).catch((error) => {
        // console.log("fetch error: ", error);
        fileObject.status = FileStatus.FAILED;
        setTrigger(prev => !prev);
        ErrorToastHelper(toast, "Failed to upload: " + fileObject.file.name)
      });
    }));

    setPhase(DatasetImportPhase.GENERATE);
    // console.log("handleUpload uploadedFileObjects: ", uploadedFileObjects)
  }

  const handleGenerate = async () => {
    // await handleUpload();

    const files = Object.values(uploadedFileObjects)
      .filter(fileObject => fileObject.status === FileStatus.UPLOADED)
      .map(fileObject => ({ "file": fileObject.file.name, "type": fileObject.file.type, "path": fileObject.path }));

    // Get Download Presigned URL for all files
    devlogger("handleGenerate files", files);

    await requestForDownloadPresignedUrlRequest(authTokens, expiration, files)
    .then((response) => {
      // console.log("requestForDownloadPresignedUrlRequest response: ", response);

      const respondedFiles = response.data.files;
      for (let i = 0; i < respondedFiles.length; i++) {
        const respondedFile = respondedFiles[i];
        const fileObject = uploadedFileObjects[respondedFile.file];
        fileObject.downloadUrl = respondedFile.url;
        fileObject.size = respondedFile.size;
        fileObject.path = respondedFile.path;
        fileObject.status = FileStatus.GENERATED;
      }
    }).catch((error) => {
      // console.log("requestForDownloadPresignedUrlRequest error: ", error);

      ErrorToastHelper(toast, "Failed to request for presigned download URL")
    });

    // console.log("uploadedFileObjects: ", uploadedFileObjects)

    const inputManifest = Object.values(uploadedFileObjects)
      .filter(fileObject => fileObject.status === FileStatus.GENERATED)
      .map(fileObject => {
        let obj = { "source-ref": fileObject.downloadUrl };
        if (fileObject.path) obj['datalance-uri'] = fileObject.path;
        if (fileObject.size) obj['file-size'] = fileObject.size;
        return JSON.stringify(obj);
      })
      .join('\n');

    // console.log("inputManifest: ", inputManifest)
    
    downloadFile(inputManifest, `${group}-input.manifest`, 'application/json');
  };

  const fileInputRef = useRef();

  const handleAddMoreFiles = () => {
    fileInputRef.current.click();
  };

  const renderActionButton = () => {
    switch (phase) {
      case DatasetImportPhase.SELECT:
        return (
          <Button colorScheme="blue" onClick={handleAddMoreFiles}>
            Select Files
          </Button>
        );
      case DatasetImportPhase.UPLOAD:
        return (
          <Button colorScheme="blue" onClick={handleUpload}>
            Upload Files
          </Button>
        );
      case DatasetImportPhase.GENERATE:
        return (
          <Button colorScheme="blue" onClick={handleGenerate}>
            Generate Input Manifest
          </Button>
        );
      case DatasetImportPhase.LOADING:
        return (
          <Spinner />
        );
      default:
        return null;
    }
  }

  return (
    <Box p={8}>
      <Heading mb={6}>Dataset Import</Heading>
      {/* <Input type="file" multiple onChange={handleFileChange}/> */}
      {/* <Button colorScheme="blue" onClick={handleGenerate} isDisabled={!readyForGenerate}>Generate Input Manifest</Button> */}
      <input type="file" multiple onChange={handleFileChange} ref={fileInputRef} style={{ display: 'none' }}/>
      {renderActionButton()}

      <List spacing={3}>
        {Object.values(uploadedFileObjects).map((fileObject, index) => (
          <ListItem key={index}>
            {fileObject.status === FileStatus.SELECTED && <ListIcon as={BsCircle} color="gray.500" />}
            {(fileObject.status === FileStatus.UPLOADED || fileObject.status === FileStatus.GENERATED) && <ListIcon as={BsCheckCircle} color="green.500" />}
            {fileObject.status === FileStatus.FAILED && <ListIcon as={BsExclamationCircle} color="red.500" />}
            {![FileStatus.SELECTED, FileStatus.UPLOADED, FileStatus.GENERATED, FileStatus.FAILED].includes(fileObject.status) && <ListIcon as={BsCircle} color="yellow.500" />}
            {fileObject.file.name}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default DataImport;