import React, { useState, useContext } from 'react';
import {
  Flex,
  Box,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'

import AuthContext from '../../common/AuthContext';

function Signup() {
  const [email, setEmail] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');

  const { signupUser, onProfileOpen } = useContext(AuthContext)

  const navigate = useNavigate()

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setEmailError('Invalid email address');
    } else {
      setEmailError('');
    }
  }

  // const validatePassword = (password) => {
  //   const { score } = zxcvbn(password);
  //   return score > 2;
  // }

  const validatePassword = (password) => {
    // Check length
    if (password.length < 8) {
      return false;
    }
  
    // Check for uppercase, lowercase, number, and special character
    if (!/[A-Z]/.test(password) || !/[a-z]/.test(password) || !/[0-9]/.test(password) || !/[^A-Za-z0-9]/.test(password)) {
      return false;
    }
  
    return true;
  }

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);

    if (!validatePassword(newPassword)) {
      setPasswordError('Password is too weak and must contains at least 8 characters, 1 uppercase, 1 lowercase, 1 number, and 1 special character');
    } else {
      setPasswordError('');
    }
  }

  const validateConfirmPassword = (confirmPassword) => {
    return confirmPassword === password;
  }

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);

    if (!validateConfirmPassword(newConfirmPassword)) {
      setConfirmPasswordError('Passwords do not match');
    } else {
      setConfirmPasswordError('');
    }
  }

  return (
    <Flex minHeight="100vh" width="full" align="center" justifyContent="center" bg={useColorModeValue('gray.50', 'gray.800')}>      <Box
      maxW={'420px'}
      w={'full'}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'2xl'}
      rounded={'md'}
      p={6}
      overflow={'hidden'}>
      <Stack spacing={4}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Create a DataLance Tasker Account
        </Heading>
        <FormControl id="access">
          <FormLabel>Access Code</FormLabel>
          <Input
            type="text"
            value={accessCode}
            onChange={(e) => setAccessCode(e.target.value)}
            required
          />
        </FormControl>
        <Text color={'gray.500'}>Enter your details to sign up.</Text>
        <FormControl id="email" isInvalid={!!emailError}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <FormErrorMessage>{emailError}</FormErrorMessage>
        </FormControl>
        <FormControl id="password" isInvalid={!!passwordError}>
          <FormLabel>Choose Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={handlePasswordChange}
            required
          />
          <FormErrorMessage>{passwordError}</FormErrorMessage>
        </FormControl>
        <FormControl id="confirmedPassword" isInvalid={!!confirmPasswordError}>
          <FormLabel>Confirm Password</FormLabel>
          <Input
            type="password"
            value={confirmPassword}
            onChange={handleConfirmPasswordChange}
            required
          />
          <FormErrorMessage>{confirmPasswordError}</FormErrorMessage>
        </FormControl>
        <Stack spacing={10}>
          <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            isDisabled={!!emailError || !!passwordError || !!confirmPasswordError || !email || !password || !confirmPassword || !accessCode}
            onClick={async () => {
              await signupUser(email, password, accessCode);
              navigate('/');
              onProfileOpen();
            }}
            >
            Sign Up
          </Button>
        </Stack>
        <Link as={RouterLink} to="/login" color={'blue.400'}>
          Already have an account? Log In
        </Link>
      </Stack>
    </Box>
    </Flex>
  );
}

export default Signup;