import React, { useState, useContext } from 'react';
import {
  Box,
  Flex,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Heading,
  Text,
  useColorModeValue,
  Link,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom'
import { Link as RouterLink } from 'react-router-dom';
import AuthContext from '../../common/AuthContext';

function Login() {
  // const [username, setUsername] = useState('');
  // Note: we are going to use the username as an email
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');

  const { loginUser } = useContext(AuthContext)

  const navigate = useNavigate();

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  }

  const handleEmailChange = (e) => {
    const newEmail = e.target.value;
    setEmail(newEmail);

    if (!validateEmail(newEmail)) {
      setError('Invalid email address');
    } else {
      setError('');
    }
  }

  return (
    <Flex minHeight="100vh" width="full" align="center" justifyContent="center" bg={useColorModeValue('gray.50', 'gray.800')}>
      <Box
      maxW={'420px'}
      w={'full'}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'2xl'}
      rounded={'md'}
      p={6}
      overflow={'hidden'}>
      <Stack spacing={4}>
        <Heading lineHeight={1.1} fontSize={{ base: '2xl', md: '3xl' }}>
          Sign in to your DataLance Tasker account
        </Heading>
        <Text color={'gray.500'}>Enter your login details below.</Text>
        <FormControl id="email" isInvalid={!!error}>
          <FormLabel>Email</FormLabel>
          <Input
            type="email"
            value={email}
            onChange={handleEmailChange}
            required
          />
          <FormErrorMessage>{error}</FormErrorMessage>
        </FormControl>
        <FormControl id="password">
          <FormLabel>Password</FormLabel>
          <Input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </FormControl>
        <Stack spacing={10}>
          <Button
            bg={'blue.400'}
            color={'white'}
            _hover={{
              bg: 'blue.500',
            }}
            isDisabled={error || !email || !password}
            onClick={async () => {
                await loginUser(email, password);
                navigate('/home');
              }}
            >
            Login
          </Button>
        </Stack>
        <Link as={RouterLink} to="/signup" color={'blue.400'}>
            Don't have an account? Sign Up
        </Link>
      </Stack>
    </Box>
    </Flex>
  );
}

export default Login;