import React, { useEffect, useContext } from 'react';

import { 
  FormControl, 
  FormLabel, 
  Text, 
  HStack, 
  Box, 
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  InputGroup,
  InputLeftAddon,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import ProjectContext from '../../common/ProjectContext';

function ProjectModelSettingForm({ renderSubmitButton, renderCancelButton }) {
  const { currentProject, editSetting } = useContext(ProjectContext);

  const getModelTypeOptions = () => {
    const options = [
      {
        label: "Google Gemini",
        value: "gemini",
        target: {
          name: "modelType",
          value: {
            label: "Google Gemini",
            value: "gemini",
          }
        },
      },
      {
        label: "GPT-4",
        value: "gpt4",
        target: {
          name: "modelType",
          value: {
            label: "GPT-4",
            value: "gpt4",
          }
        },
      },
      {
        label: "DataLance In-house Model",
        value: "inhouse",
        target: {
          name: "modelType",
          value: {
            label: "DataLance In-house Model",
            value: "inhouse",
          }
        },
      },
    ]
    return options;
  };

  const handleModelSettingChange = (event) => {
    // console.log("ProjectModelSettingForm handleModelSettingChange event: ", event);

    editSetting("modelSetting", event.target.name, event.target.value);
  }

  useEffect(() => {
    if (currentProject?.setting?.modelSetting?.modelType === undefined || currentProject?.setting?.modelSetting?.modelType === null) {
      editSetting("modelSetting", "modelType", {
        name: "modelType",
        value: {
          label: "Google Gemini",
          value: "gemini",
        }
      });
    }

    if (currentProject?.setting?.modelSetting?.modelPrice === undefined || currentProject?.setting?.modelSetting?.modelPrice === null) {
      editSetting("modelSetting", "modelPrice", 10);
    } 
  });

  return (
    <>
      <Text fontWeight="bold" fontSize="xl" color="#5C9CD5" mb={4} pt={4}>Define your annotator requirement and configuration</Text>

      <FormControl>
        <FormLabel>Model Type</FormLabel>
        <Select
          value={currentProject?.setting?.modelSetting?.modelType}
          name="modelType"
          onChange={handleModelSettingChange}
          height="200px"
          colorScheme="linkedin" // The global color scheme
          options={getModelTypeOptions()}
        />
        <Text mt={1} color="gray.600">Select a type of LLM for your annotation project.</Text>
      </FormControl>

      <FormControl id="modelPrice">
        <FormLabel>Preset Price per Assignment for LLM Model</FormLabel>
        <Box p={0}>
          <InputGroup>
            <InputLeftAddon children="$" />
            <NumberInput
              name='modelPrice'
              step={0.01}
              min={0.01}
              precision={2}
              value={currentProject?.setting?.modelSetting?.modelPrice ? currentProject.setting.modelSetting.modelPrice / 100: 0.10}
              onChange={(event) => {
                // console.log("modelPrice event: ", event);
                // console.log("modelPrice typeof(event): ", typeof(event));

                const newEvent = {
                  target: {
                    name: "modelPrice",
                    value: Math.round(parseFloat(event) * 100),
                  }
                };

                handleModelSettingChange(newEvent);
              }}
              w="120px"
              isDisabled
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </InputGroup>
        </Box>
        <Text mt={1} color="gray.600">This is how much an LLM model will be charging for completing an assignment.</Text>
      </FormControl>

      <HStack spacing={4} mb={8} mt={8}>
        {renderCancelButton ? renderCancelButton(): null}
        {renderSubmitButton ? renderSubmitButton(): null}
      </HStack>
    </>
  )
}

export default ProjectModelSettingForm;