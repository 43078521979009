import { Box, Heading, Collapse, IconButton, Flex, Text, useColorModeValue, Modal, ModalContent, ModalBody, ModalOverlay } from "@chakra-ui/react";
import { ChevronDownIcon, ChevronLeftIcon, CheckIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { SettingStatus } from "../components/ProjectForm";

function ModalFormWrapper({children, title, isOpen, onOpenModal, renderIcon, status}) {
  const borderColor = useColorModeValue("gray.200", "gray.700");

  let statusColor;
  let isDisabled;

  switch (status) {
    case SettingStatus.completed:
      statusColor = 'blue.500';
      isDisabled = false;
      break;
    case SettingStatus.incomplete:
      statusColor = 'yellow.500';
      isDisabled = false;
      break;
    case SettingStatus.locked:
      statusColor = 'gray.500';
      isDisabled = true;
      break;
    case SettingStatus.submitted:
      statusColor = 'green.500';
      isDisabled = false;
      break;
    default:
      statusColor = 'gray.500';
      isDisabled = false;
      break;
  }

  return (
    <Box mb={4} borderWidth="1px" borderRadius="lg" borderColor={borderColor} p={4} bg="gray.50">
      <Flex justify="space-between" align="center" onClick={isDisabled ? null : onOpenModal} cursor={isDisabled ? 'default' : 'pointer'}>
        <Flex align="center">
          {renderIcon()}
          <Heading size="md" ml={4}>{title}</Heading>
          <Text ml={2} mt="2" fontSize="sm" fontStyle="italic" color={statusColor}>
            {status}
          </Text>
        </Flex>
        <IconButton
          aria-label="Toggle form"
          icon={isOpen ? <ChevronDownIcon /> : <ChevronLeftIcon />}
          variant="ghost"
          isRound
          isDisabled={isDisabled}
        />
      </Flex>
      <Modal isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent maxW="60vw">
          <ModalBody>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ModalFormWrapper;