import React, { useContext } from 'react';
import { FormControl, FormLabel, Box, Textarea, Text, HStack } from "@chakra-ui/react";
import ProjectContext from '../../common/ProjectContext';

function ProjectPromptSettingForm({ renderSubmitButton, renderCancelButton }) {
  const { currentProject, editSetting } = useContext(ProjectContext);

  const handlePromptSettingChange = (event) => {
    // console.log("ProjectPromptSettingForm handlePromptSettingChange event: ", event);
    editSetting("promptSetting", event.target.name, event.target.value);
  }

  return (
    <>
      <Text fontWeight="bold" fontSize="xl" color="#5C9CD5" mb={4} pt={4}>Design your Annotation Prompt</Text>

      <FormControl id="prompt" mb={8}>
        <FormLabel>Prompt</FormLabel>
        <Box p={0}>
          <Textarea value={currentProject?.setting?.promptSetting?.prompt} name="prompt" onChange={handlePromptSettingChange} />
        </Box>
        <Text mt={1} color="gray.600">Fill in your desired prompt for your data.</Text>
      </FormControl>

      <HStack spacing={4} mb={8} mt={8}>
        {renderCancelButton ? renderCancelButton(): null}
        {renderSubmitButton ? renderSubmitButton(): null}
      </HStack>
    </>
  )
}

export default ProjectPromptSettingForm;