
import { AddIcon } from '@chakra-ui/icons';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { FiSettings, FiEdit } from 'react-icons/fi';
import { Box, Text, Icon, Button, VStack, Grid, Flex, Menu, MenuButton, MenuList, MenuItem, IconButton } from '@chakra-ui/react';
import { BsCheckCircle, BsCircle, BsExclamationCircle, BsClock } from "react-icons/bs";
import { FiMoreHorizontal } from "react-icons/fi";

import ScaleRapidClassification from '../../assets/images/ScaleRapidClassification.png';
import ScaleRapidNamedEntityRecognitation from '../../assets/images/ScaleRapidNamedEntityRecognitation.png';
import ScaleRapidObjectAndEventDetection from '../../assets/images/ScaleRapidObjectAndEventDetection.png';
import ScaleRapidObjectDetection from '../../assets/images/ScaleRapidObjectDetection.png';
import ScaleRapidSemanticSegmentation from '../../assets/images/ScaleRapidSematicSegmentation.png';
import ScaleRapidTextGeneration from '../../assets/images/ScaleRapidTextGeneration.png';
import ScaleRapidOther from '../../assets/images/ScaleRapidOther.png';

import BoundingBox from '../../assets/images/sagemaker-images/tasktype-bounding-box.png';
import Custom from '../../assets/images/sagemaker-images/tasktype-custom.png';
import ImageClassificationMultilabel from '../../assets/images/sagemaker-images/tasktype-image-classification-multilabel.png';
import ImageClassification from '../../assets/images/sagemaker-images/tasktype-image-classification.png';
import LabelVerification from '../../assets/images/sagemaker-images/tasktype-label-verification.png';
import NamedEntityRecognition from '../../assets/images/sagemaker-images/tasktype-named-entity-recognition.png';
import TextClassificationMultilabel from '../../assets/images/sagemaker-images/tasktype-text-classification-multilabel.png';
import TextGenerationJpeg from '../../assets/images/sagemaker-images/tasktype-text-generation.jpeg';
import TextGeneration from '../../assets/images/sagemaker-images/tasktype-text-generation.png';
import VideoClassification from '../../assets/images/sagemaker-images/tasktype-video-classification.png';
import VideoKeypointObjectTracking from '../../assets/images/sagemaker-images/tasktype-video-keypoint-object-tracking.png';
import VideoObjectDetection from '../../assets/images/sagemaker-images/tasktype-video-object-detection.png';
import VideoPolygonObjectDetection from '../../assets/images/sagemaker-images/tasktype-video-polygon-object-detection.png';
import VideoObjectAndEventDetection from '../../assets/images/sagemaker-images/tasktype-video-object-tracking.png';
import ImageSementicSegmentation from '../../assets/images/sagemaker-images/tasktype-semantic-segmentation.png';
import TextSummarization from '../../assets/images/sagemaker-images/tasktype-text-summarization.png';

function ProjectCard({ project, handleDuplicateProject, handleArchiveProject}) {
  const createProjectCard = (project.id === 0 && project.taskerId === 0);
  const navigate = useNavigate();
  const [previewImageSrc, setPreviewImageSrc] = useState("");

  const getImageForOption = (value) => {
    // const imageMap = {
    //   "classification": ScaleRapidClassification,
    //   "namedEntityRecognition": ScaleRapidNamedEntityRecognitation,
    //   "objectAndEventDetection": ScaleRapidObjectAndEventDetection,
    //   "objectDetection": ScaleRapidObjectDetection,
    //   "semanticSegmentation": ScaleRapidSemanticSegmentation,
    //   "textGeneration": ScaleRapidTextGeneration,
    //   "other": ScaleRapidOther,
    // };

    const imageMap = {
      "classification": ImageClassification,
      "namedEntityRecognition": NamedEntityRecognition,
      "objectAndEventDetection": ImageClassificationMultilabel,
      "objectDetection": VideoPolygonObjectDetection,
      "semanticSegmentation": ImageSementicSegmentation,
      "textGeneration": TextSummarization,
      "other": Custom,
    };
  
    const image = imageMap[value];
    if (!image) {
      return Custom
    }
  
    return image;
  }

  useEffect(() => {
    const image = getImageForOption(project.setting?.basicSetting?.keyWords.value);
    setPreviewImageSrc(image);

  }, [project.setting.basicSetting.keyWords]);

  // const renderStageIcon = (stage) => {
  //   switch (stage) {
  //     case 'READY':
  //       return (
  //         <Flex align="center">
  //           <Text color="gray.600" mr={2}>Ready</Text>
  //           <Icon as={BsCircle} color="gray.600" />
  //         </Flex>
  //       );
  //     case 'IN_PROGRESS':
  //       return (
  //         <Flex align="center">
  //           <Text color="blue.600" mr={2}>In Progress</Text>
  //           <Icon as={BsClock} color="blue.600" />
  //         </Flex>
  //       );
  //     case 'COMPLETED':
  //       return (
  //         <Flex align="center">
  //           <Text color="green.600" mr={2}>Completed</Text>
  //           <Icon as={BsCheckCircle} color="green.600" />
  //         </Flex>
  //       );
  //     default:
  //       return (
  //         <Flex align="center">
  //           <Text color="yellow.600" mr={2}>Need Review</Text>
  //           <Icon as={BsExclamationCircle} color="yellow.600" />
  //         </Flex>
  //       );
  //   }
  // };

  const renderStageIcon = (stage) => {
    switch (stage) {
      case 'DRAFT':
        return (
          <Flex align="center">
            <Text color="blue.600" mr={2} mb={0}>Draft</Text>
            <Icon as={BsClock} color="blue.600" />
          </Flex>
        );
      case 'PUBLISHED':
        return (
          <Flex align="center">
            <Text color="green.600" mr={2} mb={0}>Published</Text>
            <Icon as={BsCheckCircle} color="green.600" />
          </Flex>
        );
      case 'ARCHIVED':
        return (
          <Flex align="center">
            <Text color="gray.600" mr={2} mb={0}>Archived</Text>
            <Icon as={BsCircle} color="gray.600" />
          </Flex>
        );
      default:
        return (
          // Default state when none is given or can't find the state
          <Flex align="center">
            <Text color="yellow.600" mr={2} mb={0}>Pending</Text>
            <Icon as={BsExclamationCircle} color="yellow.600" />
          </Flex>
        );
    }
  };
  
  const renderCreateProjectCard = () => {
    return (
      <Box
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={4}
        textAlign="center"
        display="flex"
        alignItems="center"
        justifyContent="center"
        cursor="pointer" // Make it obvious it's clickable
        onClick={() => navigate('/create-project')} // Navigate to CreateProject page
      >
        <VStack spacing={4}>
          <AddIcon boxSize={8} />
          <Text fontSize="2xl">Create Project</Text>
        </VStack>
      </Box>
    );
  };

  // TODO: Add a preview image for the project
  // TODO: Add a status icon for the project
  // TODO: Add timestamp for created date

  const renderProjectCard = () => {
    return (
      <Box 
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={4}
        key={project.id}
        boxShadow="xl"
        position="relative"
        mb={4}
        minW="500px"
      >
        {previewImageSrc && (
          <Box position="relative" mr={4}>
            <img
              src={previewImageSrc}
              alt="Preview"
              style={{
                width: "100%",
                height: "auto",
                objectFit: "cover",
                objectPosition: "top",
                maxHeight: "350px", // Adjust the maximum height as needed
                borderRadius: "10px",
              }}
            />
          </Box>
        )}
        <Box bg="gray.100" p={2} mt={4} mb={4} borderRadius="lg" display="flex" justifyContent="space-between" alignItems="center">
          <Flex direction="column" align="start">
            <Text fontSize="lg" mb={0}>{project?.setting?.basicSetting?.title}</Text>
            <Text fontSize="md" mb={0} color="gray.500">{project?.setting?.basicSetting?.keyWords?.label}</Text>
          </Flex>
          <Flex direction="column" align="end">
            {renderStageIcon(project?.status)}
            <Text fontSize="md" mb={0} color="gray.500">
              {new Date(project?.createdTimestamp).toLocaleString()}
            </Text>
          </Flex>
        </Box>
        <Grid gap={4} alignItems="center">
          <Box>
            <Text mr={6} mb={4}>{project?.setting?.basicSetting?.description}</Text>
          </Box>
        </Grid>
        <Flex justifyContent="center">
          <Button colorScheme="blue" variant="outline" size="md" w="auto" minW="150px" mb={4} mr={6} leftIcon={<FiSettings />} onClick={() => navigate(`/project/${project.id}`)}>Edit Project</Button>
          <Button colorScheme="blue" variant="outline" size="md" w="auto" minW="150px" mb={4} mr={6} leftIcon={<FiEdit />} onClick={() => navigate(`/manage-samples/${project.id}`)}>Manage Samples</Button>
          <Menu>
            <MenuButton as={IconButton} icon={<FiMoreHorizontal />} variant="outline" colorScheme="blue" size="md" w="auto" minW="75px" mb={4} mr={6} />
            <MenuList placement="bottom" preventOverflow={false}>
              <MenuItem onClick={() => handleDuplicateProject(project.id)}>Duplicate</MenuItem>
              <MenuItem onClick={() => handleArchiveProject(project.id)}>Archive</MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </Box>
    );
  };

  return (
    createProjectCard ? renderCreateProjectCard(project) : renderProjectCard(project)
  );
}

export default ProjectCard;