import { createContext, useState, useEffect } from 'react'

/**
 * Create a new project object.
 * @param {bigint} taskerId 
 * @param {bigint} lastEditedTimestamp
 * @param {bigint} createdTimestamp
 * @param {string} status
 * @param {object} setting
 * @returns 
 */
export const constructProject = (
  taskerId,
  lastEditedTimestamp,
  createdTimestamp,
  status,
  setting,
) => {
  return {
    taskerId: taskerId,
    lastEditedTimestamp: lastEditedTimestamp,
    createdTimestamp: createdTimestamp,
    status: status,
    setting: setting,
  };
}

/**
 * Create a new batch object.
 * @param {bigint} projectId
 * @param {bigint} createdTimestamp
 * @param {string} status
 * @param {object} metadata
 */
export const constructBatch = (
  projectId,
  createdTimestamp,
  status,
  metadata,
) => {
  return {
    projectId: projectId,
    createdTimestamp: createdTimestamp,
    status: status,
    metadata: metadata,
  };
}
