import { Navigate } from 'react-router-dom'
import { useContext } from 'react';
import AuthContext from './AuthContext';
import Navbar from '../components/Navbar';

const PrivateRoute = ({children, ...rest}) => {
  const { user } = useContext(AuthContext);

  const renderChildrenWithNavbar = () => {
    return (
      <>
        <Navbar />
        {children}
      </>
    )
  }

  return (
    !user ? <Navigate to='/login'/> : renderChildrenWithNavbar()
  )
}

export default PrivateRoute;