import { createContext, useState, useEffect, useContext } from 'react'
import { fetchProjectsByTaskerRequest, createNewProjectRequest, updateProjectSettingRequest, fetchTasksByProjectRequest, publishProjectRequest } from './Api';
import AuthContext from './AuthContext';
import { useNavigate } from 'react-router-dom';
import { useToast } from "@chakra-ui/react"
import { SuccessToastHelper, ErrorToastHelper } from './ToastHelper';
import { devlogger } from './Logger';

const ProjectContext = createContext()

export default ProjectContext;

const emptyBasicSetting = {
  title: null,
  description: null,
  keyWords: null,
  containsAdultContent: 0,
};

const emptyAnnotatorSetting = {
  annotatorType: null,
  annotatorReward: null,
  annotatorAssignment: null,
  annotatorTimeAllotted: null,
  annotatorTaskExpiresIn: null,
};

const defaultAnnotatorSetting = {
  annotatorType: {
    "label": "Amazon MTurk Annotator",
    "value": "mturk"
  },
  annotatorReward: 20,
  annotatorAssignment: 1,
  annotatorTimeAllotted: 30,
  annotatorTaskExpiresIn: 7,
};

const emptyModelSetting = {
  modelType: null,
  modelPrice: null,
};

const emptyBatchSetting = {
  batchFilePath: null,
  dataCount: null,
  firstData: null,
};

const emptyLayoutSetting = {
  layoutFilePath: null,
};

const emptyPromptSetting = {
  prompt: null
};

const emptyConfirmationSetting = {
  paid: false,
};

const emptyProject = {
  id: null,
  taskerId: null,
  lastEditedTimestamp: null,
  createdTimestamp: null,
  status: null,
  setting: {
    basicSetting: emptyBasicSetting,
    batchSetting: emptyBatchSetting,
    confirmationSetting: emptyConfirmationSetting
  },
}

export const ProjectProvider = ({ children }) => {

  // Note: we should not store the projects and currentProject in the local storage

  // const [projects, setProjects] = useState(() => {
  //   // Get initial value from localStorage or set a default
  //   const savedValue = localStorage.getItem('projects');
  //   return savedValue !== null ? JSON.parse(savedValue) : [];
  // });

  // useEffect(() => {
  //   // Persist state changes to localStorage
  //   localStorage.setItem('projects', JSON.stringify(projects));
  // }, [projects]);

  // const [currentProject, setCurrentProject] = useState(() => {
  //   // Get initial value from localStorage or set a default
  //   const savedValue = localStorage.getItem('currentProject');
  //   return savedValue !== null ? JSON.parse(savedValue) : emptyProject;
  // });

  // useEffect(() => {
  //   // Persist state changes to localStorage
  //   localStorage.setItem('currentProject', JSON.stringify(currentProject));
  // }, [currentProject]);

  const [projects, setProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(emptyProject);
  const [currentProjectTasks, setCurrentProjectTasks] = useState([]);
  const { authTokens, user } = useContext(AuthContext);

  const navigate = useNavigate();
  const toast = useToast();

  const refreshCurrentProject = () => {
    setCurrentProject(emptyProject);
  }

  // Note: this is the context function to help us fetch the projects and mainly
  // used by the home screen to display the list of projects and also used by
  // the loadProject function to load a single project
  const refreshProjects = async () => {
    return await fetchProjectsByTaskerRequest(authTokens, user.user_id)
    .then(response => {
      if (response.status === 200) {
        devlogger("refreshProjects response.data", response.data)
        setProjects(response.data);
        return response.data;
      } else {
        ErrorToastHelper(toast, "Something went wrong while fetching the projects!")
        return [];
      }
    })
    .catch(error => {
      ErrorToastHelper(toast, "Something went wrong while fetching the projects: " + error)
      return [];
    })
  }

  const loadProject = async (projectId) => {
    let loadedProjects = [...projects];
  
    devlogger("loadProject initial projects", projects);
    devlogger("loadProject loadedProjects", loadedProjects);
    devlogger("loadProject projectId", projectId);
  
    if (projects.length === 0) {
      devlogger("loadProject projects is empty, refreshing projects");
      loadedProjects = await refreshProjects(authTokens, user.user_id);
      devlogger("loadProject loadedProjects after refresh", loadedProjects);
    }
      
    const selectedProject = loadedProjects.find(project => project?.id === Number(projectId));
          
    if (selectedProject) {
      setCurrentProject(selectedProject);
    }
  }

  // Note: this is the context function to help us create a new project 
  // by calling the createNewProject api it will use the current project
  // and authTokens so user would also need to call editSetting to update 
  // the current project setting before calling this function
  const createProject = async (type) => {
    let updatedProject;

    if (type == "llm") {
      updatedProject = {
        ...currentProject,
        setting: {
          ...currentProject.setting,
          modelSetting: emptyModelSetting,
          promptSetting: emptyPromptSetting,
        }
      };
    } else if (type == "hitl") {
      updatedProject = {
        ...currentProject,
        setting: {
          ...currentProject.setting,
          annotatorSetting: emptyAnnotatorSetting,
          layoutSetting: emptyLayoutSetting,
        }
      };
    } else if (type == "quick") {
      updatedProject = {
        ...currentProject,
        setting: {
          ...currentProject.setting,
          annotatorSetting: defaultAnnotatorSetting,
          layoutSetting: emptyLayoutSetting,
        }
      };
    } else if (type == "duplicate") {
      // No need to set any other field because duplicateProject already
      // handle all the new settings for the duplicated project
      updatedProject = {
        ...currentProject,
      };
    } else {
      ErrorToastHelper(toast, "Something went wrong while creating the project!")
    }

    setCurrentProject(updatedProject);
    await createNewProjectRequest(authTokens, updatedProject)
    .then(response => {
      if (response.status === 201) {
        SuccessToastHelper(toast, "Project created successfully!")
        // Note: maybe below logic should be performed in the caller of create project
        // by using load project and navigate
        setCurrentProject(response.data);
        navigate(`/project/${response.data.id}`);
      } else {
        ErrorToastHelper(toast, "Something went wrong while creating the project!")
      }
    })
    .catch(error => {
      ErrorToastHelper(toast, "Something went wrong while creating the project!")
    })
  }

  const syncUpdatedProject = (updatedProject) => {
    // console.log("syncUpdatedProject updatedProject: ", updatedProject);

    setProjects(prevProjects => {
      return prevProjects.map(project =>
        project.id === updatedProject.id ? updatedProject : project
      );
    });
  };

  // Note: use the currentProject to update the project by calling the 
  // updateProjectSetting api and then update the currentProject
  const updateProject = async () => {
    // console.log(currentProject)
    const projectId = currentProject.id;
    const projectSetting = currentProject.setting;
    currentProject.type = currentProject?.setting?.annotatorSetting?.annotationType

    if (!projectId || !projectSetting) {
      ErrorToastHelper(toast, "Something went wrong while updating the project!")
      return;
    }
    
    await updateProjectSettingRequest(authTokens, currentProject)
    .then(response => {
      // console.log("updateProject response: ", response);
      // console.log("updateProject response.data: ", response.data);
      // console.log("updateProject response.status: ", response.status);
      if (response.status === 200) {
        SuccessToastHelper(toast, "Project updated successfully!")
        // console.log("updateProject response.data: ", response.data);
        setCurrentProject(response.data);
        syncUpdatedProject(response.data);
      } else {
        ErrorToastHelper(toast, "Something went wrong while updating the project!")
      }
    })
    .catch(error => {
      ErrorToastHelper(toast, "Something went wrong while updating the project!")
    })
  }

  // Note: this is just a helper function to ensure that other components
  // can update the current project setting without changing the project directly
  const editSetting = (settingType, settingName, settingValue) => {
    setCurrentProject(currentProject => ({
      ...currentProject,
      setting: {
        ...currentProject.setting,
        [settingType]: {
          ...currentProject.setting[settingType],
          [settingName]: settingValue,
        }
      }
    }));
  }

  // const publishBatchForCurrentProject = async () => {
  //   await publishBatchByProjectRequest(authTokens, currentProject)
  //   .then(response => {
  //     console.log("publishBatchByProject response: ", response);
  //     console.log("publishBatchByProject response.status: ", response.status);
  //     if(response.status === 201){
  //       SuccessToastHelper(toast, "Batch published successfully!")
  //     } else {
  //       ErrorToastHelper(toast, "Something went wrong while publishing the batch!")
  //     }
  //   })
  //   .catch(error => {
  //     ErrorToastHelper(toast, "Something went wrong while publishing the batch!")
  //   });
  // }

  const publishProject = async () => {
    await publishProjectRequest(authTokens, currentProject)
    .then(response => {
      devlogger("publishProject response: ", response);
      devlogger("publishProject response.status: ", response.status);
      if(response.status === 201){
        SuccessToastHelper(toast, "Project published successfully!")
      } else {
        ErrorToastHelper(toast, "Something went wrong while publishing the project!")
      }
    }
    )
    .catch(error => {
      ErrorToastHelper(toast, "Something went wrong while publishing the project!")
    });
  }

  // const createCurrentProjectHumanInTheLoopAnnotationJob = async () => {
  //   await createHumanInTheLoopAnnotationJobRequest(authTokens, currentProject)
  //   .then(response => {
  //     console.log("createCurrentProjectHumanInTheLoopAnnotationJob response: ", response);
  //     console.log("createCurrentProjectHumanInTheLoopAnnotationJob response.status: ", response.status);
  //     if(response.status === 201){
  //       SuccessToastHelper(toast, "Human in the loop annotation job created successfully!")
  //     } else {
  //       ErrorToastHelper(toast, "Something went wrong while creating the human in the loop annotation job!")
  //     }
  //   })
  //   .catch(error => {
  //     ErrorToastHelper(toast, "Something went wrong while creating the human in the loop annotation job!")
  //   });
  // }

  const refreshCurrentProjectTasks = async () => {
    const initialProjectId = currentProject.id;

    await fetchTasksByProjectRequest(authTokens, initialProjectId)
    .then(response => {
      // console.log("fetchTasksByProject response: ", response);
      // console.log("fetchTasksByProject response.data: ", response.data);
      // console.log("fetchTasksByProject response.status: ", response.status);
      if (response.status === 200) {
        if (currentProject.id === initialProjectId) {
          setCurrentProjectTasks(response.data.tasks);
        }
      } else {
        SuccessToastHelper(toast, "Something went wrong while fetching the batch")
      }
    })
    .catch(error => {
      ErrorToastHelper(toast, "Something went wrong while fetching the batch")
    })
  }

  // Here is a function to duplicate a project by calling the create project api with
  // prefilled setting from the target project with id, it also set the current project
  // to the duplicated project and navigate to the project page
  const duplicateProject = async (projectId) => {
    devlogger("duplicateProject projectId: ", projectId);

    const selectedProject = projects.find(project => project?.id === Number(projectId));

    devlogger("duplicateProject selectedProject: ", selectedProject);

    if (!selectedProject) {
      ErrorToastHelper(toast, "Something went wrong while duplicating the project!")
      return;
    }

    const duplicatedProject = {
      ...selectedProject,
      id: null,
      status: "DRAFT",
      setting: {
        ...selectedProject.setting,
        basicSetting: {
          ...selectedProject.setting.basicSetting,
          title: "Copy of " + selectedProject.setting.basicSetting.title,
        },
        confirmationSetting: null,
      }
    };

    devlogger("duplicateProject duplicatedProject: ", duplicatedProject);

    setCurrentProject(duplicatedProject);
  }

  const archiveProject = async (projectId) => {
    const selectedProject = projects.find(project => project?.id === Number(projectId));

    if (!selectedProject) {
      ErrorToastHelper(toast, "Something went wrong while searching for the project!")
      return;
    }

    selectedProject.status = "ARCHIVED";
    await updateProjectSettingRequest(authTokens, selectedProject)
    .then(response => {
      // console.log("updateProject response: ", response);
      // console.log("updateProject response.data: ", response.data);
      // console.log("updateProject response.status: ", response.status);
      if (response.status === 200) {
        SuccessToastHelper(toast, "Project updated successfully!")
        // console.log("updateProject response.data: ", response.data);
        setCurrentProject(response.data);
        syncUpdatedProject(response.data);
      } else {
        ErrorToastHelper(toast, "Something went wrong while updating the project!")
      }
    })
    .catch(error => {
      ErrorToastHelper(toast, "Something went wrong while updating the project!")
    })

    devlogger("archiveProject selectedProject", selectedProject);
  }

  const contextData = {
    projects:projects,
    currentProject:currentProject,
    currentProjectTasks:currentProjectTasks,
    refreshProjects:refreshProjects,
    refreshCurrentProject:refreshCurrentProject,
    refreshCurrentProjectTasks:refreshCurrentProjectTasks,
    setCurrentProjectTasks,
    loadProject:loadProject,
    createProject:createProject,
    duplicateProject:duplicateProject,
    archiveProject:archiveProject,
    updateProject:updateProject,
    publishProject:publishProject,
    editSetting:editSetting,
    // publishBatchForCurrentProject: publishBatchForCurrentProject,
    // createCurrentProjectHumanInTheLoopAnnotationJob: createCurrentProjectHumanInTheLoopAnnotationJob,
  }

  return (
    <ProjectContext.Provider value={contextData}>
      {children}
    </ProjectContext.Provider>
  )
}