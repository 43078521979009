import React, { useState, useEffect, useCallback, useContext, useMemo } from 'react';
import debounce from 'lodash.debounce';
import { LockIcon } from "@chakra-ui/icons";
import { useToast, Box, Grid, Heading, Button, Flex, Spacer, useDisclosure, ModalHeader, ModalCloseButton, ModalBody, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Modal, ModalOverlay, ModalContent, FormControl, FormLabel, Input, ModalFooter } from '@chakra-ui/react';
// import CodeMirror from '@uiw/react-codemirror';
// import {UnControlled as CodeMirror} from 'react-codemirror2'
// import {githubLight} from '@uiw/codemirror-theme-github';
// import { html } from "@codemirror/lang-html";
// import { json } from "@codemirror/lang-json";
import AuthContext from '../../common/AuthContext';
import { renderPreviewLayoutRequest, uploadLayoutToS3, fetchLayoutsFromS3 } from '../../common/Api';
import { devlogger } from '../../common/Logger';
// import { EditorView } from "@codemirror/view";
import Editor from "@monaco-editor/react";
import { saveAs } from 'file-saver';
import { IMAGE_BOUNDING_BOX_LAYOUT, IMAGE_CLASSIFICATION_LAYOUT, IMAGE_SEMANTIC_SEGMENTATION_LAYOUT, IMAGE_TEXT_GENERATION_LAYOUT, VIDEO_CLASSIFICATION_LAYOUT, VIDEO_TEXT_GENERATION_LAYOUT, MESH_CLASSIFICATION_LAYOUT, MESH_TEXT_GENERATION_LAYOUT, GAUSSIAN_SPLAT_TEXT_GENERATION_LAYOUT } from './layouts';
import { Select } from "chakra-react-select";
import { UploadFile, DownloadFile } from '../../common/PresignUrlHelper';


export const templateOptions = [
  {
    label: "Image Classification",
    value: IMAGE_CLASSIFICATION_LAYOUT,
  },
  {
    label: "Image Text Generation",
    value: IMAGE_TEXT_GENERATION_LAYOUT,
  },
  {
    label: "Image Bounding Box",
    value: IMAGE_BOUNDING_BOX_LAYOUT,
  },
  {
    label: "Image Semantic Segmentation",
    value: IMAGE_SEMANTIC_SEGMENTATION_LAYOUT,
  },
  {
    label: "Video Classification",
    value: VIDEO_CLASSIFICATION_LAYOUT,
  },
  {
    label: "Video Text Generation",
    value: VIDEO_TEXT_GENERATION_LAYOUT,
  },
  {
    label: "Mesh Classification",
    value: MESH_CLASSIFICATION_LAYOUT,
  },
  {
    label: "Mesh Text Generation",
    value: MESH_TEXT_GENERATION_LAYOUT,
  },
  {
    label: "Gaussian Splat Text Generation",
    value: GAUSSIAN_SPLAT_TEXT_GENERATION_LAYOUT,
  }
];

const LayoutBuilder = () => {
  const [templateOption, setTemplateOption] = useState(null);
  const [code, setCode] = useState("");
  const [input, setInput] = useState("");

  const [layoutFileWithData, setLayoutFileWithData] = useState(null);
  const { authTokens, user } = useContext(AuthContext);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [description, setDescription] = useState("");

  const featureFlagsObj = localStorage.getItem('featureFlags');
  const featureFlags = JSON.parse(featureFlagsObj);
  const isCustomizable = featureFlags?.customization;

  const toast = useToast();

  // do api call to get all layouts stored
  const [layoutMap, setLayoutMap] = useState(new Map());

  // options: https://react-select.com/home#getting-started

  const [options, setOptions] = useState([]);

  async function getLayouts() {

    let response = await fetchLayoutsFromS3(authTokens, user);

    let layoutData = response.data;

    // this only works if we double parse settings for whatever reason
    let layouts = layoutData.map((x) => { x.setting = JSON.parse(x.setting); return x; });

    let newLayoutMap = new Map();

    let newOptions = [];

    devlogger("layouts", layouts);

    layouts.forEach((layout) => {
      newLayoutMap.set(layout.id, layout);

      const date = new Date(layout.createdTimestamp);
      const layoutCreatedTimestamp = date.toLocaleString();

      devlogger("layoutCreatedTimestamp", layoutCreatedTimestamp);

      newOptions = [...newOptions, {value: layout.id, label: layout.setting.title + " (" + layoutCreatedTimestamp + ")"}];
    });

    devlogger("newLayoutMap", newLayoutMap);

    devlogger("options", newOptions);

    setOptions(newOptions);

    setLayoutMap(newLayoutMap);

    return;

  };

  useEffect(() => {
    getLayouts();
  }, []);

  const [title, setTitle] = React.useState('');
  const handleTitleInputChange = (event) => {
    setTitle(event.target.value);
    devlogger("value", event.target.value);
  };


  const handleSelectChange = async (option, action) => {
    const layoutId = option.value;
    devlogger("layoutId", layoutId);
    let layout;
    // for some reason, doing layoutMap[layoutId] DOES NOT WORK, so we have to do it like this
    layoutMap.forEach((val, key) => {
      if (key === layoutId) {
          layout = val;
      }
    });
    devlogger("layout", layout);
    devlogger("handleSelectChange event", action);
    setInput(layout.setting.input);
    setTitle(layout.setting.title);

    const path = layout.setting.layout;
    const parts = path.split('/');
    const file = parts.pop();
    const type = "text/liquid";
    const fetchedLayoutFile = await DownloadFile(file, path, type, authTokens);
    setCode(fetchedLayoutFile);
    
  };

  const handleTemplateSelectChange = (event) => {
    devlogger("handleSelectChange event", event);
    setTemplateOption(event.value);
  };

  const onGenerateTemplate = () => {
    setTitle(templateOption["name"]);
    setCode(templateOption["layout"]);
    setInput(templateOption["input"]);
    // close popup
    onClose();
  };

  const handleRenderPreview = async () => {
    devlogger("handleRenderPreview code", code);
    devlogger("handleRenderPreview input", input);

    if (code && input) {
      await renderPreviewLayoutRequest(authTokens, code, input)
        .then(response => {
          setLayoutFileWithData(response.data);
        })
    }
  }

  const handleDownload = () => {
    const blob = new Blob([code], { type: "text/plain;charset=utf-8" });
    saveAs(blob, "layout.liquid");
  }

  const handleGenerateTemplate = () => {
    onOpen();
  }

  const handleSaveLayout = async () => {
    const blob = new Blob([code], { type: "text/plain;charset=utf-8" });
    // TODO: let user change name of file
    const file = new File([blob], "layout.liquid", { type: "text/plain;charset=utf-8" });
    let path = await UploadFile(file, "text/liquid", authTokens, user);
    devlogger("path", path);

    const response = await uploadLayoutToS3(authTokens, user, title, path, input);

    devlogger("response", response);

    // refresh options
    await getLayouts();
  }

  const iframeElement = useMemo(() => {
    if (layoutFileWithData) {
      devlogger("iframeElement layoutFileWithData", layoutFileWithData);
      devlogger("iframeElement input", input);

      return (
        <iframe id="layout-preview" srcDoc={layoutFileWithData} title="Layout Preview" style={{ width: '100%', height: '100vh', border: 'none' }} sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-downloads allow-presentation" />
      );
    }
  }, [layoutFileWithData]);

  // Debounce the function to limit updates to once every 3 seconds
  // const debouncedHandleRenderPreview = debounce(handleRenderPreview, 3000);

  // useEffect(() => {
  //   if (code && input) {
  //     debouncedHandleRenderPreview();
  //   }
  //   // Clean up the debounced function on unmount
  //   return () => {
  //     debouncedHandleRenderPreview.cancel();
  //   };
  // }, [code, input, debouncedHandleRenderPreview]);

  const debouncedHandleRenderPreview = useCallback(
    debounce(() => handleRenderPreview(code, input), 2000),
    [code, input]
  );

  useEffect(() => {
    if (code && input) {
      debouncedHandleRenderPreview();
    }
    // Clean up the debounced function on unmount
    return () => {
      debouncedHandleRenderPreview.cancel();
    };
  }, [code, input, debouncedHandleRenderPreview]);

  return (
    <Box p={8}>
      <Flex mb={6}>
        <Heading mb={6}>Layout Builder</Heading>
      </Flex>
      <Flex mb={6} size='sm'>
        <Heading size='md'>Select Saved Layouts: </Heading>
        <Select onChange={handleSelectChange} options={options}/> 
        <Spacer></Spacer>
        <Heading size='md'>Generate New Layout from Template</Heading>
        <Button
            colorScheme="blue"
            variant="outline"
            onClick={handleGenerateTemplate}
            ml={2}
            isDisabled={!isCustomizable}
            leftIcon={isCustomizable ? null : <LockIcon />}
          >
            Generate Template
        </Button>
      </Flex>
      <Flex mb={6}>
        <Heading size='lg'>Title: </Heading>
        <Input 
          value={title}
          onChange={handleTitleInputChange}
          placeholder='Write title of layout here' 
        />
        
        <Button
          colorScheme="blue"
          variant="outline"
          onClick={handleSaveLayout}
          ml={2}
          isDisabled={!isCustomizable}
          leftIcon={isCustomizable ? null : <LockIcon />}
        >
          Save Layout
        </Button>
        <Button colorScheme="blue" variant="outline" onClick={handleDownload} ml={2}>
          Download
        </Button>
      </Flex>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <Box w="100%" h="80vh" overflow="hidden">
          <Accordion defaultIndex={[0, 1]} allowMultiple>
            <AccordionItem>
              <h2>
                <AccordionButton bg="gray.200" color="black" _hover={{ bg: "gray.300" }}>
                  <Box flex="1" textAlign="left">
                    Layout Editor (Liquid)
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Editor
                  height="500px" // Adjust this value as needed
                  defaultLanguage="html"
                  value={code}
                  theme="light" // Monaco doesn't have a 'dracula' theme out of the box, use 'vs-dark' or install a custom theme
                  options={{
                    lineNumbers: true,
                    readOnly: false,
                    fontSize: 18,
                  }}
                  onChange={(value, event) => {
                    devlogger("Monaco onChange value", value)
                    devlogger("Monaco onChange event", event)
                    setCode(value);
                  }}
                />
              </AccordionPanel>
            </AccordionItem>
            <AccordionItem>
              <h2>
                <AccordionButton bg="gray.200" color="black" _hover={{ bg: "gray.300" }}>
                  <Box flex="1" textAlign="left">
                    Sample Input
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Editor
                  height="150px" // Adjust this value as needed
                  defaultLanguage="json"
                  value={input}
                  theme="light"
                  options={{
                    lineNumbers: true,
                    readOnly: false,
                    fontSize: 18,
                  }}
                  onChange={(value, event) => {
                    devlogger("Monaco onChange value", value)
                    devlogger("Monaco onChange event", event)
                    setInput(value);
                  }}
                />
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
        <Box>
          <Accordion defaultIndex={[0]} allowMultiple>
            <AccordionItem border="1px" borderColor="gray.200" borderRadius="md" bg="gray.50">
              <h2>
                <AccordionButton bg="gray.200" color="black" _hover={{ bg: "gray.300" }}>
                  <Box flex="1" textAlign="left">
                    Preview
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Box p={2} mt={2}>
                  {layoutFileWithData && (
                    <>
                      {iframeElement}
                    </>
                  )}
                </Box>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Grid>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Template</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl>
              <FormLabel>Select your template!</FormLabel>
              <FormLabel>WARNING: THIS WILL OVERRIDE YOUR CURRENT LAYOUT!</FormLabel>
              <Select
                name="layoutType"
                onChange={handleTemplateSelectChange}
                colorScheme="linkedin"
                options={templateOptions}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="gray" mr={3} onClick={onClose}>
              Close
            </Button>
            <Button colorScheme="blue" mr={3} onClick={onGenerateTemplate}>
              Generate
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LayoutBuilder;