export const ErrorToastHelper = (toast, error) => {
  toast({
    title: "Error",
    description: error,
    status: "error",
    duration: 5000,
    isClosable: true,
  });
}

export const SuccessToastHelper = (toast, message) => {
  toast({
    title: "Success",
    description: message,
    status: "success",
    duration: 5000,
    isClosable: true,
  });
}
