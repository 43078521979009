// import dotenv from 'dotenv';
// dotenv.config();

const AWS = require('aws-sdk');

// TODO: eventually switch away from storing the AWS configuration in frontend 
// and instead use AWS Cognito to authenticate users or AWS presigned URLs
export const AWSS3StoreFile = async (file, filePath) => {
  const bucketName = process.env.REACT_APP_AWS_BUCKET_NAME;

  const awsConfig = {
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  };

  AWS.config.update(awsConfig);

  const s3 = new AWS.S3({
    params: { Bucket: bucketName },
    region: process.env.REACT_APP_AWS_REGION,
  });

  const params = {
    Bucket: bucketName,
    Key: filePath,
    Body: file,
  };

  try {
    // TODO: before production or deployment, make the AWS bucket private
    const upload = s3
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        // console.log(
        //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
        // );
      })
      .promise();
    // await upload;
    // console.log("upload: ", upload);
    return await upload;
  } catch (error) {
    // console.log("Error uploading file to AWS S3: ", error);
    throw new Error("Error uploading file to AWS S3: ", error);
  }
};
