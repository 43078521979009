import React, { useState, useEffect, useContext } from 'react';

import { FormControl, FormLabel, Input, Text, Textarea, HStack, Box, Checkbox } from "@chakra-ui/react";
import { Select } from "chakra-react-select"
import ProjectContext from '../../common/ProjectContext';

function ProjectBasicSettingForm({ renderSubmitButton, renderCancelButton }) {
  const { currentProject, editSetting } = useContext(ProjectContext);

  // Note: for load up the form in the initialization use
  // the default setState varible and do not use useEffect 
  // might run into some infinite loop issue

  const handleBasicSettingChange = (event) => {
    // console.log("ProjectBasicSettingForm handleBasicSettingChange event: ", event);
    editSetting("basicSetting", event.target.name, event.target.value);
  }

  // Note: for now only focus on computer vision and remove the NLP stuff
  const getKeyWordsOrCategoryOptions = () => {
    const options = [
      {
        label: "Classification",
        value: "classification",
        target: {
          name: "keyWords",
          value: {
            label: "Classification",
            value: "classification",
          },
        },
      },
      // {
      //   label: "Named Entity Recognition",
      //   value: "namedEntityRecognition",
      //   target: {
      //     name: "keyWords",
      //     value: {
      //       label: "Named Entity Recognition",
      //       value: "namedEntityRecognition",
      //     },
      //   },
      // },
      {
        label: "Object and Event Detection",
        value: "objectAndEventDetection",
        target: {
          name: "keyWords",
          value: {
            label: "Object and Event Detection",
            value: "objectAndEventDetection",
          },
        },
      },
      {
        label: "Object Detection",
        value: "objectDetection",
        target: {
          name: "keyWords",
          value: {
            label: "Object Detection",
            value: "objectDetection",
          },
        },
      },
      {
        label: "Semantic Segmentation",
        value: "semanticSegmentation",
        target: {
          name: "keyWords",
          value: {
            label: "Semantic Segmentation",
            value: "semanticSegmentation",
          },
        },
      },
      {
        label: "Text Generation",
        value: "textGeneration",
        target: {
          name: "keyWords",
          value: {
            label: "Text Generation",
            value: "textGeneration",
          },
        },
      },
      {
        label: "Other",
        value: "other",
        target: {
          name: "keyWords",
          value: {
            label: "Other",
            value: "other",
          },
        },
      },
    ];
    
    return options;
  };

  return (
    <>
      <Text fontWeight="bold" fontSize="xl" color="#5C9CD5" mb={4} pt={4}>Describe your Project</Text>

      <FormControl id="title" mb={8}>
        <FormLabel>Title</FormLabel>
        <Box p={0}>
          <Input 
            type="text" 
            name="title" 
            value={currentProject?.setting?.basicSetting?.title} 
            onChange={handleBasicSettingChange} 
          />
        </Box>
        <Text mt={1} color="gray.600">Add a title to help keep track of all of your different projects</Text>
      </FormControl>

      <FormControl id="projectDescription" mb={8}>
        <FormLabel>Project Description</FormLabel>
        <Box p={0}>
          <Textarea value={currentProject?.setting?.basicSetting?.description} name="description" onChange={handleBasicSettingChange} />
        </Box>
        <Text mt={1} color="gray.600">Give a bit more detail about this project, so the Annotators would have more context about your goal.</Text>
      </FormControl>

      <FormControl id="keyWords" mb={8}>
        <FormLabel>Key Words or Category</FormLabel>
          <Box p={0}>
            <Select
              value={currentProject?.setting?.basicSetting?.keyWords}
              name="keyWords"
              onChange={handleBasicSettingChange}
              height="200px"
              colorScheme="linkedin" // The global color scheme
              options={getKeyWordsOrCategoryOptions()}
            />
          </Box>
        <Text mt={1} color="gray.600">Provide keywords or category that will help Annotators understand the general requirement of your project.</Text>
      </FormControl>

      <FormControl id="containsAdultContent">
        <Checkbox 
          name="containsAdultContent" 
          isChecked={currentProject?.setting?.basicSetting?.containsAdultContent} 
          onChange={(event) => {
            const newEvent = {
              target: {
                name: "containsAdultContent",
                value: event.target.checked ? 1 : 0
              }
            };

            handleBasicSettingChange(newEvent);
          }}
        >
          This project may contain potentially explicit or offensive content, for example, nudity.
        </Checkbox>
      </FormControl>

      <HStack spacing={4} mb={8} mt={8}>
        {renderCancelButton ? renderCancelButton(): null}
        {renderSubmitButton ? renderSubmitButton(): null}
      </HStack>
    </>
  )
}

export default ProjectBasicSettingForm;