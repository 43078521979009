import React, { useState, useEffect } from 'react';
import { Box, Heading, Button, Table, Thead, Tbody, Tr, Th, Td, Progress, Checkbox, Flex, Icon, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckIcon, MoreVerticalIcon } from '@chakra-ui/icons';
import { FiMoreVertical } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';
import { AWSS3FetchFile } from '../../common/AWSS3FetchFile';

function BatchesTable({ batches }) {
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const projectBatches = batches;
  const { projectId } = useParams();

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const selectableRows = projectBatches
        .filter(batch => (batch.batchStatus === "DONE"))
        .map(batch => batch.id);
      setSelectedRows(selectableRows);
    }
    setSelectAll(!selectAll);
  };

  const handleRowSelect = (id) => {
    setSelectedRows(prevState => {
      if (prevState.includes(id)) {
        return prevState.filter(rowId => rowId !== id);
      } else {
        return [...prevState, id];
      }
    });
  };

  const handleDownload = async (filePath) => {
    try {
      const fileData = await AWSS3FetchFile(filePath);
      const blob = new Blob([fileData]);
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filePath.split('/').pop();
      link.click();
    } catch (error) {
      // console.log(error);
      alert('Error downloading file.');
    }
  };

  return (
    <>
    <Table variant="simple" mt={4}>
        <Thead>
          <Tr>
            <Th>
              <Checkbox 
                isChecked={selectAll}
                onChange={handleSelectAll}
              />
            </Th>
            <Th>Batch Name</Th>
            <Th>Created Date</Th>
            <Th>Uploaded Batch file</Th>
            <Th>Batch Status</Th>
            <Th>Batch Size</Th>
            <Th>Completed</Th>
            <Th>In Review</Th>
            <Th>In Progress</Th>
            <Th>Review acceptance rate</Th>
            <Th>Average annotation time</Th>
            <Th>Average review time</Th>
            <Th>Action</Th>
          </Tr>
        </Thead>
        <Tbody>
          {projectBatches.map(batch => (
            <Tr key={batch.id} _hover={{ bg: "gray.100" }}>
              <Td>
                <Checkbox 
                  isChecked={selectedRows.includes(batch.id)}
                  isDisabled={!(batch.batchStatus === "DONE")}
                  onChange={() => handleRowSelect(batch.id)}
                />
              </Td>
              <Td>
                {(batch.batchStatus === "DONE") && <Icon as={CheckIcon} color="green.500" mr={2} />}
                {batch.name}
              </Td>
              <Td>{batch.createdDate}</Td>
              <Td>
                <button onClick={() => handleDownload(`batch/${projectId}_${batch.uploadedBatchFile}`)}>
                  {batch.uploadedBatchFile}
                </button>
              </Td>
              <Td>{batch.batchStatus}</Td>
              <Td>{batch.batchSize}</Td>
              <Td>{batch.completed}</Td>
              <Td>{batch.inReview}</Td>
              <Td>{batch.inProgress}</Td>
              <Td>{batch.averageAcceptanceRate}</Td>
              <Td>{batch.averageAnnotationTime}</Td>
              <Td>{batch.averageReviewTime}</Td>
              <Td>
              <Menu>
                <MenuButton as={Button} variant="ghost">
                    <FiMoreVertical />
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={() => {}} isDisabled={batch.batchStatus !== "DONE"}>
                    Download Result
                  </MenuItem>
                  <MenuItem onClick={() => {}} isDisabled={batch.batchStatus !== "DONE"}>
                    Approve
                  </MenuItem>
                  <MenuItem onClick={() => {}} isDisabled={batch.batchStatus !== "DONE"}>
                    Reject
                  </MenuItem>
                </MenuList>
               </Menu>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        pageCount={Math.ceil(projectBatches.length / 12)}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        containerClassName={'pagination'}
        activeClassName={'active'}
      />
    </>
  );
}

export default BatchesTable;