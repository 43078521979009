import React, { useState, useEffect, useContext, useMemo } from 'react';

import { FormControl, FormLabel, Input, Text, Textarea, HStack, Box, Checkbox, useToast, Button } from "@chakra-ui/react";
import ProjectContext from '../../common/ProjectContext';
import AuthContext from '../../common/AuthContext';
import { renderPreviewLayoutRequest } from '../../common/Api';

import { devlogger, errlogger } from '../../common/Logger';
import { DownloadFile, UploadFile } from '../../common/PresignUrlHelper';
import { useNavigate } from 'react-router-dom';

function ProjectLayoutSettingForm({ renderSubmitButton, renderCancelButton }) {
  const [layoutFile, setLayoutFile] = useState(null);
  const [layoutFileWithData, setLayoutFileWithData] = useState(null);

  const { currentProject, editSetting } = useContext(ProjectContext);
  const { authTokens, user } = useContext(AuthContext);
  const navigate = useNavigate();

  const toast = useToast();

  const type = "text/liquid";

  // Note: for updating the layout file when the layout file path is updated
  useEffect(() => {
    const path = currentProject?.setting?.layoutSetting?.layoutFilePath;

    if (path) {
      const fetchData = async () => {
        const parts = path.split('/');
        const file = parts.pop();
        const fetchedLayoutFile = await DownloadFile(file, path, type, authTokens);

        devlogger("fetchData ProjectLayoutSettingForm useEffect fetchedLayoutFile", fetchedLayoutFile);

        setLayoutFile(fetchedLayoutFile);
      };

      fetchData();
    } else {
      errlogger("fetchData", "ProjectlayoutSettingForm useEffect currentProject?.setting?.layoutSetting?.layoutFilePath is null");
    }
  }, [currentProject?.setting?.layoutSetting?.layoutFilePath, currentProject]);

  // Note: for updating the layout file with data or iframe display
  useEffect(() => {
    const updateLayoutFileWithData = async (layoutFile, firstData) => {
      await renderPreviewLayoutRequest(authTokens, layoutFile, firstData)
      .then(response => {
        // console.log("renderPreviewLayoutRequest response: ", response);
        setLayoutFileWithData(response.data);
      })
    }

    if (layoutFile && currentProject?.setting?.batchSetting?.firstData) {      
      updateLayoutFileWithData(layoutFile, currentProject?.setting?.batchSetting?.firstData);
    } else {
      // console.log("layoutFile or firstData is null");
    }

  },[layoutFile]);


  const handleLayoutSettingChange = (event) => {
    // console.log("ProjectLayoutSettingForm handleLayoutSettingChange event: ", event);

    editSetting("layoutSetting", event.target.name, event.target.value);
  }

  const handleStoreLayoutFile = async (layoutFile) => {
    const savedLayoutFilePath = await UploadFile(layoutFile, type, authTokens, user);
    devlogger("ProjectLayoutSettingForm handleStoreLayoutFile savedLayoutFilePath", savedLayoutFilePath);

    if (savedLayoutFilePath) {
      return savedLayoutFilePath;
    } else {
      console.error("There was an error saving the layout file!");
      toast({
        title: "An error occurred",
        description: "There was an error saving the layout file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return null;
    }
  };

  const iframeElement = useMemo(() => {
    if (layoutFileWithData) {
      return (
        <iframe id="layout-preview" srcDoc={layoutFileWithData} title="Layout Preview" style={{ width: '100%', height: '100vh', border: 'none' }} sandbox="allow-forms allow-modals allow-pointer-lock allow-popups allow-same-origin allow-scripts allow-top-navigation-by-user-activation allow-downloads allow-presentation"/>
      );
    }
  }, [layoutFileWithData]);

  return (
    <>
      <Text fontWeight="bold" fontSize="xl" color="#5C9CD5" mb={4} pt={4}>Design your annotation layout</Text>

      <FormControl id="layoutFileUpload">
        <FormLabel>Upload Layout File in Liquid</FormLabel>
        <Input type="file" accept=".liquid" p={1} onChange={async (event) => {
          const layoutFilePath = await handleStoreLayoutFile(event.target.files[0]);
          const newEvent = {
            target: {
              name: "layoutFilePath",
              value: layoutFilePath
            }
          }
          handleLayoutSettingChange(newEvent);
        }}/>
        {currentProject?.setting?.layoutSetting?.layoutFilePath && <Text color="blue.500" mt={1}>Selected Layout File: {currentProject?.setting?.layoutSetting?.layoutFilePath.split('/').pop().replace(`${currentProject.id}_`, '')}</Text>}
        <Text mt={1} color="gray.600">Drag & drop your Layout file here, or click to select one from your computer. If you don't have a layout file, you can create one  
          <span 
            onClick={() => navigate('/layout-builder')} 
            style={{ color: '#3182CE', textDecoration: 'underline', cursor: 'pointer', paddingLeft: '5px' }}
          >
            here
          </span>
        </Text>
      </FormControl>

      {layoutFileWithData && (
        <>
          {iframeElement}
        </>
      )}

      <HStack spacing={4} mb={8} mt={8}>
        {renderCancelButton ? renderCancelButton(): null}
        {renderSubmitButton ? renderSubmitButton(): null}
      </HStack>
    </>
  )
}

export default ProjectLayoutSettingForm;