import React, { useState, useEffect } from 'react';
import { Box, Heading, Button, Table, Thead, Tbody, Tr, Th, Td, Progress, Checkbox, Flex, Icon, Menu, MenuButton, MenuList, MenuItem } from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { CheckIcon, MoreVerticalIcon } from '@chakra-ui/icons';
import { FiMoreVertical } from 'react-icons/fi';
import ReactPaginate from 'react-paginate';
import BatchesTable from '../BatchTable';

function ManageBatches() {
  const navigate = useNavigate();
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [batches, setBatches] = useState(null);

  const fetchBatches = async (projectId) => {
    // Mock data
    return [
      {
        id: 1,
        name: "Batch 1",
        createdDate: "2023-01-01",
        uploadedBatchFile: "example.csv",
        batchStatus: "WIP",
        batchSize: 100,
        completed: 30,
        inReview: 20,
        inProgress: 50,
        averageAcceptanceRate: 80,
        averageAnnotationTime: 120,
        averageReviewTime: 30,
      },
      {
        id: 2,
        name: "Batch 2",
        createdDate: "2023-01-01",
        uploadedBatchFile: "example.csv",
        batchStatus: "DONE",
        batchSize: 100,
        completed: 100,
        inReview: 0,
        inProgress: 0,
        averageAcceptanceRate: 80,
        averageAnnotationTime: 120,
        averageReviewTime: 30,
      }
    ];
  };

  const fetchProject = async (projectId) => {
    // Mock data
    return {
      title: "test project"
    };
  }

  useEffect(() => {
    fetchProject(projectId).then(data => setProject(data));

    fetchBatches(projectId).then(data => setBatches(data));
  }
  , [projectId]);

  if (!project || !batches) return <div>Loading...</div>;

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={6} pt={6}>
        <Heading as="h1" size="xl">
          {project.title} Batches
        </Heading>
        <Box>
          <Button onClick={() => navigate('/project-settings')} mr={2}>Project Settings</Button>
          <Button onClick={() => {}} mr={2}>Download Result</Button>
          <Button onClick={() => navigate(`/publish-batch/${projectId}`)}>Publish Batches</Button>
        </Box>
      </Flex>
      <BatchesTable batches={batches} />
    </Box>
  );
}

export default ManageBatches;
