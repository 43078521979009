import { useNavigate, useParams } from 'react-router-dom';
import React, { useState, useEffect, useContext } from 'react';
import { Box, Heading, Button, useToast } from '@chakra-ui/react';
import { BsGearFill, BsPeopleFill, BsCheckCircleFill, BsClock, BsCircleFill } from 'react-icons/bs';

import ProjectContext from '../../common/ProjectContext';
import ModalFormWrapper from '../../common/ModalFormWrapper';
import ProjectBasicSettingForm from './ProjectBasicSettingForm';
import ProjectBatchSettingForm from './ProjectBatchSettingForm';
import ProjectPromptSettingForm from './ProjectPromptSettingForm';
import ProjectModelSettingForm from './ProjectModelSettingForm';
import ProjectConfirmationSettingForm from './ProjectConfirmationSettingForm';
import ProjectLayoutSettingForm from './ProjectLayoutSettingForm';
import ProjectAnnotatorSettingForm from './ProjectAnnotatorSettingForm';
import { devlogger } from '../../common/Logger';

export const SettingStatus = {
  incomplete: "Incomplete",
  completed: "Completed",
  submitted: "Submitted",
  locked: "Locked",
};

function ProjectForm() {
  const { projectId } = useParams();
  const toast = useToast();
  const navigate = useNavigate();

  const featureFlagsObj = localStorage.getItem('featureFlags');
  const featureFlags = JSON.parse(featureFlagsObj);
  const isCustomizable = featureFlags?.customization;

  const [untoggledForm, setUntoggledForm] = useState("");
  const [basicSettingStatus, setBasicSettingStatus] = useState(SettingStatus.incomplete);
  const [annotatorSettingStatus, setAnnotatorSettingStatus] = useState(SettingStatus.incomplete);
  const [modelSettingStatus, setModelSettingStatus] = useState(SettingStatus.incomplete);
  const [batchSettingStatus, setBatchSettingStatus] = useState(SettingStatus.incomplete);
  const [layoutSettingStatus, setLayoutSettingStatus] = useState(SettingStatus.locked);
  const [promptSettingStatus, setPromptSettingStatus] = useState(SettingStatus.locked);
  const [confirmationSettingStatus, setConfirmationSettingStatus] = useState(SettingStatus.locked);

  // TODO: add another use context method for LLM project
  const { loadProject, currentProject, projects, updateProject, publishProject} = useContext(ProjectContext);

  useEffect(() => {
    devlogger("ProjectForm projectId: ", projectId);

    if (projectId) {
      loadProject(projectId);
    }
  }, [projectId]);

  useEffect(() => {
    devlogger("ProjectForm")
    devlogger("projectId: ", projectId);
    devlogger("[] currentProject: ", currentProject);

    if (checkBasicSettingValidity() && currentProject?.status !== "PUBLISHED") {
      setBasicSettingStatus(SettingStatus.completed);
    }
    if (checkAnnotatorSettingValidity() && currentProject?.status !== "PUBLISHED") {
      setAnnotatorSettingStatus(SettingStatus.completed);
    }
    if (checkModelSettingValidity() && currentProject?.status !== "PUBLISHED") {
      setModelSettingStatus(SettingStatus.completed);
    }
    if (checkLayoutSettingValidity() && currentProject?.status !== "PUBLISHED") {
      setLayoutSettingStatus(SettingStatus.completed);
    }
    if (checkPromptSettingValidity() && currentProject?.status !== "PUBLISHED") {
      setPromptSettingStatus(SettingStatus.completed);
    }
    if (checkBatchSettingValidity() && currentProject?.status !== "PUBLISHED") {
      setBatchSettingStatus(SettingStatus.completed);
    }
    // Need to check everytime after the current project is loaded
  }, [currentProject, projects]);  

  useEffect(() => {
    devlogger("statcurrentProject?.statusus currentProject: ", currentProject);
    // Set all status to submitted if the project has already been started and prevent further editing
    if (currentProject?.status === "PUBLISHED") {
      setConfirmationSettingStatus(SettingStatus.submitted);
      setBasicSettingStatus(SettingStatus.submitted);
      setAnnotatorSettingStatus(SettingStatus.submitted);
      setLayoutSettingStatus(SettingStatus.submitted);
      setBatchSettingStatus(SettingStatus.submitted);
    }
  }, [currentProject?.status]);

  useEffect(() => {
    // Unlock confirmation setting if all the previous settings for either llm or hitl are completed
    if (
      basicSettingStatus === SettingStatus.completed &&
      batchSettingStatus === SettingStatus.completed &&
      confirmationSettingStatus === SettingStatus.locked &&
      (
        (annotatorSettingStatus === SettingStatus.completed && layoutSettingStatus === SettingStatus.completed) ||
        (modelSettingStatus === SettingStatus.completed && promptSettingStatus === SettingStatus.completed)
      )
    ) {
      setConfirmationSettingStatus(SettingStatus.incomplete);
    }
  }, [basicSettingStatus, annotatorSettingStatus, modelSettingStatus, layoutSettingStatus, promptSettingStatus, batchSettingStatus, confirmationSettingStatus]);

  useEffect(() => {
    // Unlock layout setting if batch setting is completed
    if (batchSettingStatus === SettingStatus.completed && layoutSettingStatus === SettingStatus.locked) {
      setLayoutSettingStatus(SettingStatus.incomplete);
    }

    // Unlock prompt setting if batch setting is completed
    if (batchSettingStatus === SettingStatus.completed && promptSettingStatus === SettingStatus.locked) {
      setPromptSettingStatus(SettingStatus.incomplete);
    }
  }, [batchSettingStatus]);

  // Note: below are helper functions for basic setting
  const checkBasicSettingValidity = () => {
    // console.log("checkBasicSettingValidity currentProject: ", currentProject);
    if (currentProject?.setting?.basicSetting?.title &&
      currentProject?.setting?.basicSetting?.keyWords &&
      currentProject?.setting?.basicSetting?.description) {
      return true;
    } else {  
      return false;
    }
  }

  const handleUpdateProjectBasicSetting = async () => {
    if (checkBasicSettingValidity()) {
      // await updateProject()
      setUntoggledForm("");
      setBasicSettingStatus(SettingStatus.completed);
    } else {
      // console.log("handleUpdateProjectBasicSetting currentProject: ", currentProject);
      // console.log("handleUpdateProjectBasicSetting isUpdatedProjectBasicSettingValid: ", checkBasicSettingValidity());
      toast({
        title: "Basic Setting is not valid",
        description: "Please make sure you have filled in all the required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  };

  // Note: below are helper functions for annotator setting
  const checkAnnotatorSettingValidity = () => {
    // console.log("checkAnnotatorSettingValidity currentProject: ", currentProject);
    if (currentProject?.setting?.annotatorSetting?.annotatorType &&
      currentProject?.setting?.annotatorSetting?.annotatorReward &&
      currentProject?.setting?.annotatorSetting?.annotatorAssignment &&
      currentProject?.setting?.annotatorSetting?.annotatorTimeAllotted &&
      currentProject?.setting?.annotatorSetting?.annotatorTaskExpiresIn) {
      return true;
    } else {
      return false;
    }
  }

  const handleUpdateProjectAnnotatorSetting = async () => {
    if (checkAnnotatorSettingValidity()) {
      await updateProject()
      setUntoggledForm("");
      setAnnotatorSettingStatus(SettingStatus.completed);
    } else {
      // console.log("handleUpdateProjectAnnotatorSetting currentProject: ", currentProject);
      // console.log("handleUpdateProjectAnnotatorSetting isUpdatedProjectAnnotatorSettingValid: ", checkAnnotatorSettingValidity());
      toast({
        title: "Annotator Setting is not valid",
        description: "Please make sure you have filled in all the required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  };

  // Note: below are helper functions for model setting
  const checkModelSettingValidity = () => {
    // console.log("checkModelSettingValidity currentProject: ", currentProject);
    if (currentProject?.setting?.modelSetting?.modelType &&
      currentProject?.setting?.modelSetting?.modelPrice) {
      return true;
    } else {
      return false;
    }
  }

  const handleUpdateProjectModelSetting = async () => {
    if (checkModelSettingValidity()) {
      await updateProject()
      setUntoggledForm("");
      setModelSettingStatus(SettingStatus.completed);
    } else {
      // console.log("handleUpdateProjectModelSetting currentProject: ", currentProject);
      // console.log("handleUpdateProjectModelSetting isUpdatedProjectAnnotatorSettingValid: ", checkModelSettingValidity());
      toast({
        title: "Model Setting is not valid",
        description: "Please make sure you have filled in all the required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  };

  // Note: below are helper functions for layout setting
  const checkLayoutSettingValidity = () => {
    // console.log("checkLayoutSettingValidity currentProject: ", currentProject);
    if (currentProject?.setting?.layoutSetting?.layoutFilePath) {
      return true;
    } else {
      return false;
    }
  }

  const handleUpdateProjectLayoutSetting = async () => {
    if (checkLayoutSettingValidity()) {
      await updateProject()
      setUntoggledForm("");
      setLayoutSettingStatus(SettingStatus.completed);
    } else {
      // console.log("handleUpdateProjectLayoutSetting currentProject: ", currentProject);
      // console.log("handleUpdateProjectLayoutSetting isUpdatedProjectLayoutSettingValid: ", checkLayoutSettingValidity());
      toast({
        title: "Layout Setting is not valid",
        description: "Please make sure you have filled in all the required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  };

  // Note: below are helper functions for prompt setting
  const checkPromptSettingValidity = () => {
    // console.log("checkPromptSettingValidity currentProject: ", currentProject);
    if (currentProject?.setting?.promptSetting?.prompt) {
      return true;
    } else {
      return false;
    }
  }

  const handleUpdateProjectPromptSetting = async () => {
    if (checkPromptSettingValidity()) {
      await updateProject()
      setUntoggledForm("");
      setLayoutSettingStatus(SettingStatus.completed);
    } else {
      // console.log("handleUpdateProjectPromptSetting currentProject: ", currentProject);
      // console.log("handleUpdateProjectPromptSetting isUpdatedProjectPromptSettingValid: ", checkPromptSettingValidity());
      toast({
        title: "Prompt Setting is not valid",
        description: "Please make sure you have filled in all the required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  };

  // Note: below are helper functions for batch setting
  const checkBatchSettingValidity = () => {
    // console.log("checkBatchSettingValidity currentProject: ", currentProject);
    if (currentProject?.setting?.batchSetting?.batchFilePath &&
      currentProject?.setting?.batchSetting?.dataCount &&
      currentProject?.setting?.batchSetting?.firstData) {
      return true;
    } else {
      return false;
    }
  }

  const handleUpdateProjectBatchSetting = async () => {
    if (checkBatchSettingValidity()) {
      await updateProject()
      setUntoggledForm("");
      setBatchSettingStatus(SettingStatus.completed);
    } else {
      // console.log("handleUpdateProjectBatchSetting currentProject: ", currentProject);
      // console.log("handleUpdateProjectBatchSetting isUpdatedProjectBatchSettingValid: ", checkBatchSettingValidity());
      toast({
        title: "Batch Setting is not valid",
        description: "Please make sure you have filled in all the required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  // Note: below are helper functions for confirmation setting
  const checkConfirmationSettingValidity = () => {
    // console.log("checkConfirmationSettingValidity currentProject: ", currentProject);
    return Boolean(currentProject?.setting?.confirmationSetting?.paid)
  }

  const handleUpdateProjectConfirmationSetting = async () => {
    if (checkConfirmationSettingValidity()) {
      await updateProject();
      // await publishBatchForCurrentProject();
      // TODO: add support for creating LLM annotation job
      await publishProject();
      setUntoggledForm("");
      setConfirmationSettingStatus(SettingStatus.completed);
    } else {
      // console.log("handleUpdateProjectConfirmationSetting currentProject: ", currentProject);
      // console.log("handleUpdateProjectConfirmationSetting isUpdatedProjectConfirmationSettingValid: ", checkConfirmationSettingValidity());
      toast({
        title: "Confirmation Setting is not valid",
        description: "Please make sure you have filled in all the required fields",
        status: "error",
        duration: 5000,
        isClosable: true,
      })
    }
  }

  // Note: below are helper functions for general project form
  const intializeUntoggleHandler = (type) => {
    return () => {
      // Note: load currentProject form projects would reset the project setting if not saved
      loadProject(projectId);
      setUntoggledForm(type);
    }
  };

  const cancelHandler = () => {
    // Note: load currentProject form projects would reset the project setting if not saved
    loadProject(projectId);
    setUntoggledForm("");
  };

  return (
    <Box p={8}>
      <Heading mb={6}>Project Details</Heading>
      <ModalFormWrapper title="Project Basic Setting" isOpen={untoggledForm === "basic"} onOpenModal={intializeUntoggleHandler("basic")} renderIcon={() => <BsGearFill size="1.5em"/>} status={basicSettingStatus}>
        <ProjectBasicSettingForm 
          renderSubmitButton={() => <Button colorScheme="blue" onClick={handleUpdateProjectBasicSetting} isDisabled={basicSettingStatus === SettingStatus.submitted} mr={2} ml={2} w="180px">Save</Button>}
          renderCancelButton={() => <Button colorScheme="gray" onClick={cancelHandler} mr={2} ml={2} w="180px">Cancel</Button>}
        />
      </ModalFormWrapper>

      {isCustomizable && currentProject?.setting?.annotatorSetting && (
        <ModalFormWrapper title="Annotator Setting" isOpen={untoggledForm === "annotator"} onOpenModal={intializeUntoggleHandler("annotator")} renderIcon={() => <BsPeopleFill size="1.5em"/>} status={annotatorSettingStatus}>
          <ProjectAnnotatorSettingForm 
            renderSubmitButton={() => <Button colorScheme="blue" onClick={handleUpdateProjectAnnotatorSetting} isDisabled={annotatorSettingStatus === SettingStatus.submitted} mr={2} ml={2} w="180px">Save</Button>}
            renderCancelButton={() => <Button colorScheme="gray" onClick={cancelHandler} mr={2} ml={2} w="180px">Cancel</Button>}
          />
        </ModalFormWrapper>
      )}

      {currentProject?.setting?.modelSetting && (
        <ModalFormWrapper title="Model Setting" isOpen={untoggledForm === "model"} onOpenModal={intializeUntoggleHandler("model")} renderIcon={() => <BsPeopleFill size="1.5em"/>} status={modelSettingStatus}>
          <ProjectModelSettingForm 
            renderSubmitButton={() => <Button colorScheme="blue" onClick={handleUpdateProjectModelSetting} isDisabled={modelSettingStatus === SettingStatus.submitted} mr={2} ml={2} w="180px">Save</Button>}
            renderCancelButton={() => <Button colorScheme="gray" onClick={cancelHandler} mr={2} ml={2} w="180px">Cancel</Button>}
          />
        </ModalFormWrapper>
      )}

      <ModalFormWrapper title="Batch Setting" isOpen={untoggledForm === "batch"} onOpenModal={intializeUntoggleHandler("batch")} renderIcon={() => <BsClock size="1.5em"/>} status={batchSettingStatus}>
        <ProjectBatchSettingForm 
          renderSubmitButton={() => <Button colorScheme="blue" onClick={handleUpdateProjectBatchSetting} isDisabled={batchSettingStatus === SettingStatus.submitted} mr={2} ml={2} w="180px">Save</Button>}
          renderCancelButton={() => <Button colorScheme="gray" onClick={cancelHandler} mr={2} ml={2} w="180px">Cancel</Button>}
        />
      </ModalFormWrapper>

      {currentProject?.setting?.layoutSetting && (
        <ModalFormWrapper title="Layout Setting" isOpen={untoggledForm === "layout"} onOpenModal={intializeUntoggleHandler("layout")} renderIcon={() => <BsCheckCircleFill size="1.5em"/>} status={layoutSettingStatus}>
          <ProjectLayoutSettingForm 
            renderSubmitButton={() => <Button colorScheme="blue" onClick={handleUpdateProjectLayoutSetting} isDisabled={layoutSettingStatus === SettingStatus.submitted} mr={2} ml={2} w="180px">Save</Button>}
            renderCancelButton={() => <Button colorScheme="gray" onClick={cancelHandler} mr={2} ml={2} w="180px">Cancel</Button>}
          />
        </ModalFormWrapper>
      )}

      {currentProject?.setting?.promptSetting && (
        <ModalFormWrapper title="Prompt Setting" isOpen={untoggledForm === "prompt"} onOpenModal={intializeUntoggleHandler("prompt")} renderIcon={() => <BsCheckCircleFill size="1.5em"/>} status={promptSettingStatus}>
          <ProjectPromptSettingForm 
            renderSubmitButton={() => <Button colorScheme="blue" onClick={handleUpdateProjectPromptSetting} isDisabled={promptSettingStatus === SettingStatus.submitted} mr={2} ml={2} w="180px">Save</Button>}
            renderCancelButton={() => <Button colorScheme="gray" onClick={cancelHandler} mr={2} ml={2} w="180px">Cancel</Button>}
          />
        </ModalFormWrapper>
      )}

      <ModalFormWrapper title="Confirmation Setting" isOpen={untoggledForm === "confirmation"} onOpenModal={intializeUntoggleHandler("confirmation")} renderIcon={() => <BsCircleFill size="1.5em"/>} status={confirmationSettingStatus}>
        <ProjectConfirmationSettingForm 
          renderSubmitButton={() => <Button colorScheme="blue" onClick={handleUpdateProjectConfirmationSetting} isDisabled={confirmationSettingStatus === SettingStatus.submitted} mr={2} ml={2} w="180px">Submit</Button>}
          renderCancelButton={() => <Button colorScheme="gray" onClick={cancelHandler} mr={2} ml={2} w="180px">Cancel</Button>}
        />
      </ModalFormWrapper>
      <Button colorScheme="blue" onClick={() => navigate("/home")} mr={2} w="180px">
        Done
      </Button>
    </Box>
  )
}

export default ProjectForm;