import "./assets/css/flaticon.min.css"
import "./assets/css/fontawesome-5.14.0.min.css"
import "./assets/css/bootstrap.min.css"
import "./assets/css/magnific-popup.min.css"
import "./assets/css/nice-select.min.css"
import "./assets/css/style.css"
import { useNavigate } from 'react-router-dom'

export default function Landing() {
    const navigate = useNavigate();

    return(
        <div className="page-wrapper">

            {/* <!-- main header --> */}
            <header className="main-header menu-absolute no-border">
                {/* <!--Header-Top--> */}
                <div className="header-top-wrap bgc-primary">
                    <div className="container container-1660">
                        <div className="header-top">
                            <div className="row align-items-center">
                                    <div className="col-xl-3 col-md-6">
                                    <div className="top-left text-center text-md-start">
                                            <ul>
                                                {/* <li>
                                                    <div className="menu-sidebar pb-1">
                                                        <button>
                                                            <img src={require("./assets/images/shapes/toggler.png")} alt="menu"/>
                                                        </button>
                                                    </div>
                                                </li> */}
                                                <li><a href="mailto:founders@datalance.io">founders@datalance.io <i className="fal fa-arrow-right"></i></a></li>
                                            </ul>
                                    </div>
                                    </div>
                                    <div className="col-xl-5 d-xl-block d-none">
                                        <div className="top-middle text-center">
                                            Get $10 in credit upon signup!
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-md-6">
                                        <div className="top-right text-center text-md-end">
                                            {/* <ul>
                                                <li>
                                                    <div className="social-icons">
                                                        <a href="#"><i className="fab fa-twitter"></i></a>
                                                        <a href="#"><i className="fab fa-facebook-f"></i></a>
                                                        <a href="#"><i className="fab fa-instagram"></i></a>
                                                        <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                                    </div>
                                                </li>
                                            </ul> */}
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <!-- Start Header-Upper--> */}
                <div className="header-upper">
                    <div className="container container-1660 clearfix">

                        <div className="header-inner py-20 rel d-flex align-items-center">
                            <div className="logo-outer">
                                <div className="logo">
                                    <img className="half-size" src={require("./assets/images/logos/logo-dark-with-word.png")} alt="Logo" title="Logo"/>
                                </div>
                            </div>

                            <div className="nav-outer ms-lg-auto clearfix">
                                {/* <!-- Main Menu --> */}
                                <nav className="main-menu navbar-expand-lg">
                                    <div className="navbar-header py-10">
                                    <div className="mobile-logo">
                                        <img src={require("./assets/images/logos/logo-dark-with-word.png")} alt="Logo" title="Logo"/>
                                    </div>
                                    
                                        {/* <!-- Toggle Button --> */}
                                        <button type="button" className="navbar-toggle" data-bs-toggle="collapse" data-bs-target=".navbar-collapse">
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                            <span className="icon-bar"></span>
                                        </button>
                                    </div>

                                    <div className="navbar-collapse collapse clearfix">
                                        <ul className="navigation clearfix">
                                            {/* <li className="dropdown"><a href="#">Home</a>
                                                <ul>
                                                    <li><a href="index.html">Business</a></li>
                                                    <li><a href="index2.html">Lead Capture</a></li>
                                                    <li><a href="index3.html">Software Landing</a></li>
                                                    <li><a href="index4.html">E-learning</a></li>
                                                    <li><a href="index5.html">Saas Landing</a></li>
                                                    <li><a href="index6.html">AI Software</a></li>
                                                    <li><a href="index7.html">WebSite Builder</a></li>
                                                    <li><a href="index9.html">Chatbot</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a href="#">pages</a>
                                                <ul>
                                                    <li><a href="about.html">About Us</a></li>
                                                    <li><a href="faqs.html">faqs</a></li>
                                                    <li><a href="team.html">Team Members</a></li>
                                                    <li><a href="pricing.html">Pricing Plan</a></li>
                                                    <li><a href="contact.html">Contact us</a></li>
                                                    <li><a href="coming-soon.html">Coming Soon</a></li>
                                                    <li><a href="404.html">404 error</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a href="#">Services</a>
                                                <ul>
                                                    <li><a href="services.html">Our Services</a></li>
                                                    <li><a href="service-details.html">Service Details</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a href="#">Shop</a>
                                                <ul>
                                                    <li><a href="shop.html">our Products</a></li>
                                                    <li><a href="product-details.html">Product Details</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a href="#">Projects</a>
                                                <ul>
                                                    <li><a href="projects.html">Project Grid</a></li>
                                                    <li><a href="project-list.html">Project List</a></li>
                                                    <li><a href="project-masonry.html">Project Masonry</a></li>
                                                    <li><a href="project-details.html">Project Details</a></li>
                                                </ul>
                                            </li>
                                            <li className="dropdown"><a href="#">blog</a>
                                                <ul>
                                                    <li><a href="blog.html">blog standard</a></li>
                                                    <li><a href="blog-details.html">blog details</a></li>
                                                </ul>
                                            </li> */}
                                        </ul>
                                    </div>

                                </nav>
                                {/* <!-- Main Menu End--> */}
                            </div>
                            
                            {/* <!-- Nav Search --> */}
                            {/* <div className="nav-search ms-xl-2 ms-4 me-xl-auto py-10">
                                <button className="far fa-search"></button>
                                <form action="#" className="hide">
                                    <input type="text" placeholder="Search" className="searchbox" required=""/>
                                    <button type="submit" className="searchbutton far fa-search"></button>
                                </form>
                            </div> */}
                            
                            {/* <!-- Menu Button --> */}
                            <div className="menu-btns ms-lg-auto">
                                <a onClick={() => navigate('/login')} className="theme-btn style-two">Log In <i className="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="bg-lines">
                    <span></span><span></span>
                    <span></span><span></span>
                    </div> */}
                </div>
                {/* <!--End Header Upper--> */}
            </header>
            
            {/* <!-- Hero area start --> */}
            <section className="hero-area-six bgc-primary pt-215 rpt-150 pb-100 rel z-1">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-9 col-lg-11">
                            <div className="hero-content style-five text-center">
                                <span className="subtitle-one mb-20"><i className="fas fa-rocket-launch"></i> End to End Labeling Platform for Computer Vision</span>
                                <h1>Fast, Convienient Data Labeling for Computer Vision with <span>Datalance</span></h1>
                                <div className="row justify-content-center pt-5 rpt-0 pb-25">
                                    <div className="col-xl-7 col-lg-9">
                                    <p><strong>5x Your Computer Vision Labeling:</strong> Support for all formats including images and videos. Customize your interface or request one from us. Our built-in quality assurance ensures production-ready labeling for faster, precise project outcomes </p>
                                    </div>
                                </div>
                                <a onClick={() => navigate('/signup')} className="theme-btn">Sign Up For Free <i className="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero-bg-shapes">
                    <div className="shape one">
                        <img src={require("./assets/images/shapes/tooltip-shape1.png")} alt="Shape"/>
                    </div>
                    <div className="shape two">
                        <img src={require("./assets/images/shapes/tooltip-shape3.png")} alt="Shape"/>
                    </div>
                </div>
                <div className="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section>
            {/* <!-- Hero area End --> */}
            
            
            {/* <!-- Hero Image Part End --> */}
            {/* Note: this is where the landing page image go */}
            {/* <div className="hero-image-area style-two text-center bgp-bottom rel z-1">
                <div className="container container-1370">
                    <img src={require("./assets/images/hero/hero-six.png")} alt="Hero"/>
                </div>
            </div> */}
            {/* <!-- Hero Image Part End --> */}
        
        
            {/* <!-- Tooltip Area Start --> */}
            {/* <section className="tooltip-area-one bgc-navyblue rel z-1 pt-130 rpt-100">
                <div className="container">
                    <div className="section-title text-white text-center mb-115">
                        <h4>We’ve 1253+ Global Clients & lot’s of Project Complete</h4>
                    </div>
                    <div className="tooltip-style-two">
                        <div className="tooltip-item">
                            <div className="tooltip-btn">
                                <img src={require("./assets/images/tooltips/tooltip-icon8.png")} alt="Icon"/>
                            </div>
                            <div className="tooltip-content">
                                <div className="tooltip-text">Dropbox</div>
                            </div>
                        </div>
                        <div className="tooltip-item">
                            <div className="tooltip-btn">
                                <img src={require("./assets/images/tooltips/tooltip-icon2.png")} alt="Icon"/>
                            </div>
                            <div className="tooltip-content">
                                <div className="tooltip-text">LinkedIn</div>
                            </div>
                        </div>
                        <div className="tooltip-item">
                            <div className="tooltip-btn">
                                <img src={require("./assets/images/tooltips/tooltip-icon3.png")} alt="Icon"/>
                            </div>
                            <div className="tooltip-content">
                                <div className="tooltip-text">Skack</div>
                            </div>
                        </div>
                        <div className="tooltip-item">
                            <div className="tooltip-btn">
                                <img src={require("./assets/images/tooltips/tooltip-icon4.png")} alt="Icon"/>
                            </div>
                            <div className="tooltip-content">
                                <div className="tooltip-text">Symbol</div>
                            </div>
                        </div>
                        <div className="tooltip-item">
                            <div className="tooltip-btn">
                                <img src={require("./assets/images/tooltips/tooltip-icon5.png")} alt="Icon"/>
                            </div>
                            <div className="tooltip-content">
                                <div className="tooltip-text">Shopify</div>
                            </div>
                        </div>
                        <div className="tooltip-item">
                            <div className="tooltip-btn">
                                <img src={require("./assets/images/tooltips/tooltip-icon9.png")} alt="Icon"/>
                            </div>
                            <div className="tooltip-content">
                                <div className="tooltip-text">Notion</div>
                            </div>
                        </div>
                        <div className="tooltip-item">
                            <div className="tooltip-btn">
                                <img src={require("./assets/images/tooltips/tooltip-icon10.png")} alt="Icon"/>
                            </div>
                            <div className="tooltip-content">
                                <div className="tooltip-text">Twitch</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="tooltip-shapes">
                    <div className="shape one">
                        <img src={require("./assets/images/shapes/tooltip-shape1.png")} alt="Shape"/>
                    </div>
                    <div className="shape two">
                        <img src={require("./assets/images/shapes/tooltip-shape2.png")} alt="Shape"/>
                    </div>
                    <div className="shape three">
                        <img src={require("./assets/images/shapes/tooltip-shape3.png")} alt="Shape"/>
                    </div>
                    <div className="shape four">
                        <img src={require("./assets/images/shapes/tooltip-shape4.png")} alt="Shape"/>
                    </div>
                </div>
            </section> */}
            {/* <!-- Tooltip Area End --> */}
            
            
            {/* <!-- Numbered box Area Start --> */}
            {/* Note: explanation */}
            {/* <section className="numbered-box-area rel pt-130 rpt-100 rel z-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9 col-md-11">
                            <div className="section-title text-center mb-60">
                                <h2>Introducing Datalance: The Ultimate Platform for AI Innovation</h2>
                                <p>Collect, process, and enrich data with efficiency, turning raw information into ready-to-use datasets for your AI models</p>
                            </div>
                        </div>
                    </div> */}
                    {/* <div className="row">
                        <div className="col-xl-4 col-md-6">
                            <div className="numbered-box style-three">
                                <div className="number">01</div>
                                <div className="content">
                                    <h4><a href="service-details.html">Live Text Editor</a></h4>
                                    <p>A live text editor is dynamic & interactive software tool that allows users to create edit, manipulate text-based</p>
                                    <a href="service-details.html" className="read-more">Read More <i className="far fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="numbered-box style-three bg-two">
                                <div className="number">02</div>
                                <div className="content">
                                    <h4><a href="service-details.html">Track Analytics</a></h4>
                                    <p>Track analytics refers to the process of collecting and analyzing data related to the performance and usage</p>
                                    <a href="service-details.html" className="read-more">Read More <i className="far fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6">
                            <div className="numbered-box style-three bg-three">
                                <div className="number">03</div>
                                <div className="content">
                                    <h4><a href="service-details.html">Security & Compliance</a></h4>
                                    <p>Security and compliance are essentials pillars of any organization's strat protect its data, ./assets, and reputation</p>
                                    <a href="service-details.html" className="read-more">Read More <i className="far fa-arrow-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div> */}
                {/* </div>
            </section> */}
            {/* <!-- Numbered box Area End --> */}
            
            
            {/* <!-- Generative AI Area Start --> */}
            {/* Note: Features lists */}

            {/* <section className="generative-ai-area pt-100 rpt-65 rel z-1">
                <div className="container">
                    <div className="row justify-content-between mb-45 rmb-90">
                        <div className="col-xl-5 col-lg-7 col-md-8">
                            <div className="section-title mb-40">
                                <h2>Empower your data with Datalance</h2>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-5 text-lg-end">
                            <a className="theme-btn" onClick={() => navigate('/signup')}>Get Started Now <i className="far fa-arrow-right"></i></a>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="iconic-box style-ten">
                                <div className="icon">
                                    <i className="flaticon-machine-learning-1"></i>
                                </div>
                                <div className="content">
                                    <h5>Advanced Annotation Capabilities</h5>
                                    <p>Our sophisticated labeling tools are designed for precision and speed, enabling your team to focus on innovation rather than data prep.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="iconic-box style-ten">
                                <div className="icon">
                                    <i className="flaticon-payment-gateway"></i>
                                </div>
                                <div className="content">
                                    <h5>End to End Platform</h5>
                                    <p>This is your comprehensive end-to-end platform, catering to all your annotation needs</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="iconic-box style-ten">
                                <div className="icon">
                                    <i className="flaticon-speak"></i>
                                </div>
                                <div className="content">
                                    <h5>Rigorous Evaluation and Feedback Loop</h5>
                                    <p>Validate label accuracy and model performance with ease. Our platform facilitates a continuous improvement cycle for your AI projects.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="iconic-box style-ten">
                                <div className="icon">
                                    <i className="flaticon-customer-service"></i>
                                </div>
                                <div className="content">
                                    <h5>Secure Payment</h5>
                                    <p>Payment gateways are secured online platforms integrated with Stripe API</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- Generative AI Area End --> */}
            
            
            {/* <!-- Artificial Intelligenc Area Start --> */}
            {/* <section className="artificial-intelligence-area rel pt-50 rpt-20 pb-130 rpb-100 z-2">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9 col-md-11">
                            <div className="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                                <h2>Scale Generative artificial intelligence Platform</h2>
                                <p>Sed ut perspiciatis unde omnis iste sit voluptatem accusantium doloremque laudantium rem aperiam eaqups quae ab illo inventore veritatis et quasi architecto</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container container-1070">
                    <div className="row pb-30 rpb-65 gap-100 align-items-center">
                        <div className="col-lg-6 order-lg-2">
                            <div className="ai-image" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
                                <img src="./assets/images/artificial/artificial1.png" alt="Artificial"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ai-content mb-40 rmb-0" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
                                <span className="subtitle">Custom LLM</span>
                                <h3>Customize Large Language Models</h3>
                                <p>Customizing large language models involves fine-tuning their pre-trained parameters to adapt them to specific tasks or domains, enhancing their performance relevance in specialized applications. This process enables organizations to leverage</p>
                                <a href="about.html" className="theme-btn style-two mt-25">Read More <i className="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                    <div className="row gap-100 align-items-center">
                        <div className="col-lg-6">
                            <div className="ai-image rmb-55" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
                                <img src="./assets/images/artificial/artificial2.png" alt="Artificial"/>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="ai-content" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
                                <span className="subtitle">Edit & Customizations</span>
                                <h3>Data Curation & Customizations</h3>
                                <p>Data curation involves the careful selection, organization, and maintenance of data to ensure its quality, relevance</p>
                                <ul className="icon-list style-two mt-25 mb-20">
                                    <li><i className="fas fa-check"></i> Connect with leads with zero hassle.</li>
                                    <li><i className="fas fa-check"></i> Take quick payments direct from meetings</li>
                                </ul>
                                <a href="about.html" className="theme-btn style-three mt-25">Read More <i className="far fa-arrow-right"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-lines">
                <span></span><span></span>
                <span></span><span></span>
                </div>
            </section> */}
            {/* <!-- Artificial Intelligenc Area End --> */}
            
            
            {/* <!-- Counter Area Start --> */}
            {/* <section className="counter-area-six bgc-primary rel pt-130 rpt-100">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="section-title text-center text-white mb-60" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500" data-aos-offset="50">
                                <h2>200,000+ Growing startups use Slate as their single source of truth.</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center gap-70">
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="counter-item style-three counter-text-wrap" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                                <span className="count-text k-plus" data-speed="3000" data-stop="25">0</span>
                                <hr/>
                                <span className="counter-title">100% Satisficed Clients</span>
                                <div className="bg"></div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="counter-item style-three counter-text-wrap" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500" data-aos-offset="50">
                                <span className="count-text percent" data-speed="3000" data-stop="83">0</span>
                                <hr/>
                                <span className="counter-title">83% Lead Collection From Global Clients</span>
                                <div className="bg"></div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="counter-item style-three counter-text-wrap" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500" data-aos-offset="50">
                                <span className="count-text m-plus" data-speed="3000" data-stop="25">0</span>
                                <hr/>
                                <span className="counter-title">20m+ Data Collection From Various Email</span>
                                <div className="bg"></div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-md-6">
                            <div className="counter-item style-three counter-text-wrap" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500" data-aos-offset="50">
                                <span className="count-text k-plus" data-speed="3000" data-stop="50">0</span>
                                <hr/>
                                <span className="counter-title">Task Complete For Global Clients</span>
                                <div className="bg"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            {/* <!-- Counter Area End --> */}
            
            
            {/* <!-- Work Process Area Start --> */}
            {/* <section className="work-process-area rel z-1 text-white bgc-primary pt-90 rpt-60 pb-80 rpb-50">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10">
                            <div className="section-title text-center mb-55" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                                <h2>Improve your writing with artificial intelligence Platform</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row no-gap justify-content-center" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500" data-aos-offset="50">
                        <div className="col-lg-4 col-sm-6">
                            <div className="work-process-item">
                                <span className="number">1</span>
                                <h5>Sing Up Or Create your account Full Free</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="work-process-item with-arrow">
                                <span className="number">2</span>
                                <h5>Write what your want? or write something you want</h5>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="work-process-item">
                                <span className="number">3</span>
                                <h5>Get Output or results</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="work-process-shapes">
                    <div className="shape one">
                        <img src="./assets/images/shapes/work-process1.png" alt="Shape"/>
                    </div>
                    <div className="shape two">
                        <img src="./assets/images/shapes/work-process2.png" alt="Shape"/>
                    </div>
                </div>
            </section> */}
            {/* <!-- Work Process Area End --> */}
            
            
            {/* <!-- Work Process Image Area Start --> */}
            {/* <div className="work-process-image-area rel z-1 text-center bgc-primary">
                <div className="container">
                    <div className="image ms-5" data-aos="zoom-in-up" data-aos-duration="1500" data-aos-offset="50">
                        <img src="./assets/images/counter/work-process.png" alt="Work Process"/>
                    </div>
                </div>
                <div className="work-process-shapes">
                    <div className="shape one">
                        <img src="./assets/images/shapes/work-process3.png" alt="Shape"/>
                    </div>
                    <div className="shape two">
                        <img src="./assets/images/shapes/work-process4.png" alt="Shape"/>
                    </div>
                </div>
            </div> */}
            {/* <!-- Work Process Image Area End --> */}
            
            
            {/* <!-- Pricing Area Start --> */}
            {/* <section className="pricing-area-six bgc-lighter overflow-hidden rel z-1 pt-125 rpt-95 pb-100 rpb-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9 col-md-11">
                            <div className="section-title text-center mb-60" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                                <h2>Easy to Start, Easy to Scale Website Builder Plans</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-md-6 col-sm-10" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                            <div className="pricing-item style-five">
                                <div className="title-price">
                                    <h5 className="title">Regular</h5>
                                    <div className="price"><span className="prev">$</span>15.<span className="next">/m</span></div>
                                </div>
                                <hr/>
                                <div className="text">Data curation involve the careful election organization, and maintenance</div>
                                <ul className="icon-list">
                                    <li><i className="fal fa-check"></i> 2 Limited sites available</li>
                                    <li><i className="fal fa-check"></i> 1 GB storage per site</li>
                                    <li><i className="fal fa-check"></i> Up to 5 pages per site</li>
                                    <li className="hide"><i className="fal fa-check"></i> Free SSL for custom domain</li>
                                    <li className="hide"><i className="fal fa-check"></i> Connect custom domain</li>
                                </ul>
                                <a href="#" className="theme-btn style-two">Choose Package <i className="far fa-arrow-right"></i></a>
                                <div className="footer-text">No credit card required</div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 col-sm-10" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500" data-aos-offset="50">
                            <div className="pricing-item style-five">
                                <div className="title-price">
                                    <h5 className="title color-two">Standard</h5>
                                    <div className="price"><span className="prev">$</span>35.<span className="next">/m</span></div>
                                </div>
                                <hr/>
                                <div className="text">Data curation involve the careful election organization, and maintenance</div>
                                <ul className="icon-list">
                                    <li><i className="fal fa-check"></i> 2 Limited sites available</li>
                                    <li><i className="fal fa-check"></i> 1 GB storage per site</li>
                                    <li><i className="fal fa-check"></i> Up to 5 pages per site</li>
                                    <li><i className="fal fa-check"></i> Free SSL for custom domain</li>
                                    <li className="hide"><i className="fal fa-check"></i> Connect custom domain</li>
                                </ul>
                                <a href="#" className="theme-btn style-two">Choose Package <i className="far fa-arrow-right"></i></a>
                                <div className="footer-text">No credit card required</div>
                            </div>
                        </div>
                        <div className="col-xl-4 col-md-6 col-sm-10" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500" data-aos-offset="50">
                            <div className="pricing-item style-five">
                                <div className="title-price">
                                    <h5 className="title color-four">Diamond</h5>
                                    <div className="price"><span className="prev">$</span>98.<span className="next">/m</span></div>
                                </div>
                                <hr/>
                                <div className="text">Data curation involve the careful election organization, and maintenance</div>
                                <ul className="icon-list">
                                    <li><i className="fal fa-check"></i> 2 Limited sites available</li>
                                    <li><i className="fal fa-check"></i> 1 GB storage per site</li>
                                    <li><i className="fal fa-check"></i> Up to 5 pages per site</li>
                                    <li><i className="fal fa-check"></i> Free SSL for custom domain</li>
                                    <li><i className="fal fa-check"></i> Connect custom domain</li>
                                </ul>
                                <a href="#" className="theme-btn style-two">Choose Package <i className="far fa-arrow-right"></i></a>
                                <div className="footer-text">No credit card required</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bg-circle-shapes">
                    <span></span><span></span>
                    <span></span><span></span>
                </div>
            </section> */}
            {/* <!-- Pricing Area End --> */}
            
            
            {/* <!-- footer area start --> */}
            {/* <footer className="main-footer footer-one rel z-1 mt-100">
                <div className="container">
                    <div className="footer-newsletter text-white">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-lg-6">
                                <div className="section-title pt-30 rpt-0 rpb-10" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
                                    <h2>Get started today with 7 days free credits ?</h2>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="form-part" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
                                    <form className="newsletter-form" action="#">
                                        <input type="email" placeholder="Email Address" required=""/>
                                        <button type="submit"><b>Sign Up <i className="far fa-arrow-right"></i></b></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row justify-content-between">
                        <div className="col-xl-3 col-lg-4 col-sm-6">
                            <div className="footer-widget widget-about" data-aos="fade-up" data-aos-duration="1500" data-aos-offset="50">
                                <h6 className="footer-title">About Company</h6>
                                <p>Doloremque laudantium tota aperiam eaque abillo inventore architect beatae vitae dicta sunt explicabos</p>
                                <div className="social-style-one">
                                    <a href="#"><i className="fab fa-twitter"></i></a>
                                    <a href="#"><i className="fab fa-facebook-f"></i></a>
                                    <a href="#"><i className="fab fa-instagram"></i></a>
                                    <a href="#"><i className="fab fa-pinterest-p"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 col-sm-6 order-xl-2">
                            <div className="footer-widget widget-contact" data-aos="fade-up" data-aos-delay="200" data-aos-duration="1500" data-aos-offset="50">
                                <h6 className="footer-title">Contact</h6>
                                <ul>
                                    <li><i className="fal fa-map-marker-alt"></i> 55 Main Street, 2nd block Melbourne, Australia</li>
                                    <li><i className="fal fa-envelope"></i> <a href="mailto:support@gmail.com">support@gmail.com</a></li>
                                    <li><i className="fal fa-phone"></i> <a href="callto:+0001234455">+000 (123) 44 55</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="row">
                                <div className="col-sm-4 col-6">
                                    <div className="footer-widget widget-links" data-aos="fade-up" data-aos-delay="50" data-aos-duration="1500" data-aos-offset="50">
                                        <h6 className="footer-title">Resources</h6>
                                        <ul>
                                            <li><a href="shop.html">Product</a></li>
                                            <li><a href="services.html">Services</a></li>
                                            <li><a href="about.html">About Us</a></li>
                                            <li><a href="services.html">Benefits</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-6">
                                    <div className="footer-widget widget-links" data-aos="fade-up" data-aos-delay="100" data-aos-duration="1500" data-aos-offset="50">
                                        <h6 className="footer-title">Quick Link</h6>
                                        <ul>
                                            <li><a href="services.html">Features</a></li>
                                            <li><a href="pricing.html">Pricing Plan</a></li>
                                            <li><a href="about.html">Best Program</a></li>
                                            <li><a href="contact.html">Press Kit</a></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-sm-4 col-6">
                                    <div className="footer-widget widget-links" data-aos="fade-up" data-aos-delay="150" data-aos-duration="1500" data-aos-offset="50">
                                        <h6 className="footer-title">Company</h6>
                                        <ul>
                                            <li><a href="about.html">About</a></li>
                                            <li><a href="team.html">Team Member</a></li>
                                            <li><a href="about.html">Reviews</a></li>
                                            <li><a href="blog.html">Latest News</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="footer-bottom mt-30 py-15">
                        <div className="row align-items-center">
                            <div className="col-xl-4 col-lg-6">
                                <div className="copyright-text pt-10 text-lg-start text-center" data-aos="fade-left" data-aos-duration="1500" data-aos-offset="50">
                                    <p>Copyright @2024, <a href="index.html">Akpager </a> All Rights Reserved</p>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-6">
                                <div className="footer-bottom-menu text-lg-end text-center" data-aos="fade-right" data-aos-duration="1500" data-aos-offset="50">
                                    <ul>
                                        <li><a href="faqs.html">Faqs</a></li>
                                        <li><a href="about.html">Setting</a></li>
                                        <li><a href="about.html">Privacy</a></li>
                                        <li><a href="contact.html">Contact</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer> */}
            {/* <!-- footer area end --> */}
            
            {/* <!-- Scroll Top Button --> */}
            <button className="scroll-top scroll-to-target" data-target="html"><span className="far fa-angle-double-up"></span></button>

        </div>
    )
}