import React, { useState, useEffect, useContext } from 'react';
import { Box, Card, CardHeader, CardBody, Heading, Text, Stack, StackDivider, HStack, Button, useToast, Spinner } from '@chakra-ui/react';
import ProjectContext from '../../common/ProjectContext';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../common/AuthContext';
import { calculateProjectCostRequest, payWithBalanceRequest } from '../../common/Api';
import { devlogger } from '../../common/Logger';

function ProjectConfirmationSettingForm({ renderSubmitButton, renderCancelButton }) {
  const { currentProject, editSetting } = useContext(ProjectContext);
  const { authTokens, profile, refreshProfile } = useContext(AuthContext);
  
  const [paid, setPaid] = useState(currentProject?.setting?.confirmationSetting ? currentProject?.setting?.confirmationSetting.paid : false);

  // const [totalSamples, setTotalSamples] = useState(null);
  // const [costPerSample, setCostPerSample] = useState(null);
  // const [numberOfAssignment, setNumberOfAssignment] = useState(null);
  const [totalCost, setTotalCost] = useState(null);

  const toast = useToast();
  const navigate = useNavigate();

  // TODO: currently this would only support HITL project not LLM 
  //       need to add check for if the project is HITL or LLM and 
  //       set the values accordingly
  // useEffect(() => {
  //   if (currentProject?.setting?.batchSetting?.dataCount) {
  //     setTotalSamples(currentProject?.setting?.batchSetting?.dataCount);
  //   }

  //   if (currentProject?.setting?.annotatorSetting?.annotatorReward) {
  //     setCostPerSample(currentProject?.setting?.annotatorSetting?.annotatorReward);
  //   }

  //   if (currentProject?.setting?.annotatorSetting?.annotatorAssignment) {
  //     setNumberOfAssignment(currentProject?.setting?.annotatorSetting?.annotatorAssignment);
  //   }
  // }, [currentProject]);

  // useEffect(() => {
  //   if (totalSamples && costPerSample && numberOfAssignment) {
  //     setTotalCost(totalSamples * costPerSample * numberOfAssignment);
  //   }
  // }, [totalSamples, costPerSample, numberOfAssignment]);

  useEffect(() => {
    calculateProjectCostRequest(authTokens, currentProject.id)
      .then(response => {
        setTotalCost(response.data.cost);
      }
    ).catch(error => {
      devlogger("calculateProjectCostRequest error", error);
    });
  }, []);

  const onClickPay = async () => {
    if (profile.balance < totalCost) {
      toast({
        title: "Your balance is too low! Please recharge your account",
        description: "Your balance is too low! Please recharge your account",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } else {
      await payWithBalanceRequest(authTokens, profile.userId, currentProject.id, totalCost)
        .then(response => {
          setPaid(response.status === 200);
          editSetting("confirmationSetting", "paid", true);
          refreshProfile();
        }
      ).catch(error => {
        toast({
          title: "Something went wrong with your payment",
          description: "Something went wrong with your payment",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
    }
  }

  return (
    <>
      <Text fontWeight="bold" fontSize="xl" color="#5C9CD5" mb={4} pt={4}>Confirmation Setting</Text>

      <Card mb={4}>
        {/* <CardHeader>
          <Heading size='md'>Payment</Heading>
        </CardHeader> */}

        {/* <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Cost per task
              </Heading>
              <Text pt='2' fontSize='sm'>
                ${(costPerSample / 100).toFixed(2)}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Number of tasks
              </Heading>
              <Text pt='2' fontSize='sm'>
                {totalSamples}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Number of assignment per task
              </Heading>
              <Text pt='2' fontSize='sm'>
                {numberOfAssignment}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Total Cost
              </Heading>
              <Text pt='2' fontSize='sm'>
                ${(totalCost / 100).toFixed(2)}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Balance
              </Heading>
              <Text pt='2' fontSize='sm'>
                ${(profile.balance / 100).toFixed(2)}
              </Text>
            </Box>
            {
              paid ? 
              <Box>
                <Heading size='xs' textTransform='uppercase' color="green">
                  Paid
                </Heading>
              </Box> :
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {
                  profile.balance >= totalCost &&
                  <Button colorScheme="green" onClick={onClickPay} mr={2} w="180px">
                      Pay
                  </Button>
                }
                <Button colorScheme="yellow" onClick={() => navigate("/profile")} mr={2} w="180px">
                    Add to balance
                </Button>
              </Box>
            }
            
          </Stack>
        </CardBody> */}

        <CardBody>
          <Stack divider={<StackDivider />} spacing='4'>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Project Cost
              </Heading>
              
              <Text pt='2' fontSize='sm'>
                {totalCost ? `$${(totalCost / 100).toFixed(2)}` : <Spinner />}
              </Text>
            </Box>
            <Box>
              <Heading size='xs' textTransform='uppercase'>
                Balance
              </Heading>
              <Text pt='2' fontSize='sm'>
                ${(profile.balance / 100).toFixed(2)}
              </Text>
            </Box>
            {
              paid ? 
              <Box>
                <Heading size='xs' textTransform='uppercase' color="green">
                  Paid
                </Heading>
              </Box> :
              <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {
                  profile.balance >= totalCost &&
                  <Button colorScheme="green" onClick={onClickPay} mr={2} w="180px">
                    Pay
                  </Button>
                }
                <Button colorScheme="yellow" onClick={() => navigate("/profile")} mr={2} w="180px">
                    Add to balance
                </Button>
              </Box>
            }
          </Stack>
        </CardBody>
      </Card>

      
      <HStack spacing={4} mb={8} mt={8}>
        {renderCancelButton ? renderCancelButton(): null}
        {
          paid &&
          <>
          {renderSubmitButton ? renderSubmitButton(): null}
          </>
        }
      </HStack>
    </>
  )
}

export default ProjectConfirmationSettingForm;