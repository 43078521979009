import React, { useContext } from 'react';
import { Flex, Input, Avatar, Image, useColorModeValue, Menu, MenuButton, MenuList, MenuItem, Button, Icon } from '@chakra-ui/react';
import { BsGrid3X3GapFill, BsFileEarmarkImage, BsDatabaseAdd, BsEnvelope, BsBoxArrowRight, BsPersonFillGear } from "react-icons/bs";
import { Link, useNavigate } from 'react-router-dom'; // Import the Link component
import AuthContext from '../../common/AuthContext';

const Navbar = () => {
  const { logoutUser } = useContext(AuthContext); 
  const bgColor = useColorModeValue('white', 'gray.900');
  const navigate = useNavigate();

  const featureFlagsObj = localStorage.getItem('featureFlags');
  const featureFlags = JSON.parse(featureFlagsObj);
  const isCustomizable = featureFlags?.customization;

  const renderLoggedInBar = () => {
    return (
      <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      padding="1.5rem"
      bg={bgColor}
      boxShadow="md"
    >
      <Link to="/home"> {/* Wrap the Image component with Link */}
        {/* <Image src="/logo-black.svg" alt="Logo"  height="80px" width="80px" /> */}
        <Image src="/logo-dark-with-word.png" alt="Logo"  height="45px" />
      </Link>
      <Flex align="center">
        <Menu>
          <MenuButton as={Button} variant="unstyled">
            <Icon as={BsGrid3X3GapFill} color="gray.600" boxSize={8} mr={16} mt={1}/>
          </MenuButton>
          <MenuList>
            <MenuItem icon={<Icon as={BsDatabaseAdd} color="gray.600" boxSize={8} />} onClick={() => navigate("/dataset-import")}>Dataset Import</MenuItem>
            <MenuItem icon={<Icon as={BsFileEarmarkImage} color="gray.600" boxSize={8} />} onClick={() => navigate("/layout-builder")}>Layout Builder</MenuItem>
            <MenuItem icon={<Icon as={BsEnvelope} color="gray.600" boxSize={8}/>} onClick={() => navigate("/message")} isDisabled={!isCustomizable}>Message</MenuItem>
          </MenuList>
        </Menu>
        <Input placeholder="Search Project" variant="filled" mr={4} />
        <Menu>
          <MenuButton as={Button} variant="unstyled">
            <Avatar boxSize={8} mt={1}/>
          </MenuButton>
          <MenuList>
            <MenuItem icon={<Icon as={BsPersonFillGear} color="gray.600" boxSize={8} />} onClick={() => navigate("/profile")}>Profile</MenuItem>
            <MenuItem icon={<Icon as={BsBoxArrowRight} color="gray.600" boxSize={8} />} onClick={logoutUser}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </Flex>
    )
  }

  return (
    <>
      {renderLoggedInBar()}
    </>
  )
};

export default Navbar;
