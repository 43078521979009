import React, { useState, useEffect, useContext } from 'react';
import ReactPaginate from 'react-paginate';
import { Box, Button, Heading, Flex, useDisclosure, Menu, MenuButton, MenuList, MenuItem, Spacer } from '@chakra-ui/react';
import ProjectCard from '../ProjectCard';
import ProjectContext from '../../common/ProjectContext';
import CreateProjectModal from '../CreateProjectModal';
import useWindowDimensions from '../Hooks/useWindowDimensions';
import { ChevronDownIcon, LockIcon } from "@chakra-ui/icons";
import { AddIcon } from '@chakra-ui/icons';
import { SimpleGrid } from '@chakra-ui/react';

import './styles.css';
import { devlogger } from '../../common/Logger';

export const PROJECT_STATUS = {
  PUBLISHED: 'Published',
  DRAFT: 'Draft',
  ARCHIVED: 'Archived',
  PENDING: 'Pending',
  ONGOING: 'Ongoing',
};

export default function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [type, setType] = useState(null);
  const [sortType, setSortType] = useState('date');
  const [filter, setFilter] = useState(PROJECT_STATUS.ONGOING);
  const { projects, refreshProjects, refreshCurrentProject, duplicateProject, archiveProject } = useContext(ProjectContext);

  const { width } = useWindowDimensions()

  const [currentPage, setCurrentPage] = useState(0);
  const [projectsPerPage] = useState(6);

  const featureFlagsObj = localStorage.getItem('featureFlags');
  const featureFlags = JSON.parse(featureFlagsObj);
  const isCustomizable = featureFlags?.customization;

  let sortedProjects = [...projects];

  if (sortType === 'date') {
    sortedProjects.sort((a, b) => new Date(b.createdTimestamp) - new Date(a.createdTimestamp));
  } else if (sortType === 'title') {
    sortedProjects.sort((a, b) => a.setting.basicSetting.title.localeCompare(b.setting.basicSetting.title));
  }

  const filterProjectStatus = (project, filter) => {
    if (filter === PROJECT_STATUS.ONGOING) {
      return project.status === "PUBLISHED" || project.status === "DRAFT" || project.status === "PENDING";
    } else if (filter === PROJECT_STATUS.PUBLISHED) {
      return project.status === "PUBLISHED";
    } else if (filter === PROJECT_STATUS.DRAFT) {
      return project.status === "DRAFT";
    } else if (filter === PROJECT_STATUS.ARCHIVED) {
      return project.status === "ARCHIVED";
    } else if (filter === PROJECT_STATUS.PENDING) {
      return project.status !== "PUBLISHED" && project.status !== "DRAFT" && project.status !== "ARCHIVED";
    }
  }

  sortedProjects = sortedProjects.filter(project => filterProjectStatus(project, filter));

  const indexOfLastProject = (currentPage + 1) * projectsPerPage;
  const indexOfFirstProject = indexOfLastProject - projectsPerPage;
  const currentProjects = sortedProjects.slice(indexOfFirstProject, indexOfLastProject);

  // const totalPages = Math.ceil(projects.length / projectsPerPage);
  const totalPages = Math.ceil(sortedProjects.length / projectsPerPage);
  const columns = width > 1500 ? 3 : ( width > 1000 ? 2 : 1)

  // TODO: probably also need a useContext varible or loading 
  // to indicate when the projects are loaded or not
  // if not show a loading icon at first
  useEffect(() => { 
    refreshProjects() 
  }, []);

  const renderProjectCard = (project) => {
    return (
      <ProjectCard project={project} handleDuplicateProject={handleDuplicateProject} handleArchiveProject={handleArchiveProject} />
    )
  };
  
  const handlePageClick = (data) => {
    setCurrentPage(data.selected);
  };

  const handleCreateQuickStartProject = () => {
    // console.log("handleCreateHitlProject: ", isOpen);

    refreshCurrentProject();
    setType("quick")
    onOpen();
  };

  const handleCreateHitlProject = () => {
    // console.log("handleCreateHitlProject: ", isOpen);

    refreshCurrentProject();
    setType("hitl")
    onOpen();
  };

  const handleCreateLlmProject = () => {
    // console.log("handleCreateLlmProject: ", isOpen);

    refreshCurrentProject();
    setType("llm")
    onOpen();
  };

  // These three are helper handle function wrapped around the useContext methods 
  // and also include the onOpen() function to open the modal
  const handleDuplicateProject = (projectId) => {
    devlogger("handleDuplicateProject projectId: ", projectId);

    setType("duplicate")
    duplicateProject(projectId);
    onOpen();
  };

  const handleArchiveProject = (projectId) => {
    devlogger("handleArchiveProject projectId: ", projectId);

    archiveProject(projectId);
  };

  const renderEmptyProjectCard = () => {
    return (
      <Flex direction="column" alignItems="center" justifyContent="center" height="60vh">
        <Heading fontSize="20px">
          You currently have no projects. Create one by clicking 
          <Button variant="link" fontSize="20px" color="blue.500" padding="6px" onClick={handleCreateHitlProject}>Quick Start</Button>
          {/* or 
          <Button variant="link" fontSize="20px" color="blue.500" padding="6px" onClick={handleCreateLlmProject}>Create LLM Annotation Project</Button> */}
        </Heading>
      </Flex>
    )
  }

  const renderProjectCards = () => {
    return (
      <SimpleGrid columns={columns} spacing='40px'>
        {currentProjects.map((project) => renderProjectCard(project))}
      </SimpleGrid>
    )
  }

  return (
    <Box overflowY="auto" p={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading as="h1" size="xl" textAlign="left" mb={6} pt={6}>Your Project</Heading>
        <Spacer />
        <Menu>
          <MenuButton as={Button} leftIcon={<ChevronDownIcon />} size="lg" colorScheme="gray" mr={10}>
            Filter: {filter || PROJECT_STATUS.ONGOING}
          </MenuButton>
          <MenuList>
            <MenuItem value="Ongoing" onClick={() => setFilter(PROJECT_STATUS.ONGOING)}>
              {PROJECT_STATUS.ONGOING}
            </MenuItem>
            <MenuItem value="Published" onClick={() => setFilter(PROJECT_STATUS.PUBLISHED)}>
              {PROJECT_STATUS.PUBLISHED}
            </MenuItem>
            <MenuItem value="Draft" onClick={() => setFilter(PROJECT_STATUS.DRAFT)}>
              {PROJECT_STATUS.DRAFT}
            </MenuItem>
            <MenuItem value="Archived" onClick={() => setFilter(PROJECT_STATUS.ARCHIVED)}>
              {PROJECT_STATUS.ARCHIVED}
            </MenuItem>
            <MenuItem value="Pending" onClick={() => setFilter(PROJECT_STATUS.PENDING)}>
              {PROJECT_STATUS.PENDING}
            </MenuItem>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton as={Button} leftIcon={<ChevronDownIcon />} size="lg" colorScheme="gray" mr={10}>
            Sort By
          </MenuButton>
          <MenuList>
            <MenuItem onClick={() => setSortType('date')}>Creation Date</MenuItem>
            <MenuItem onClick={() => setSortType('title')}>Project Title</MenuItem>
          </MenuList>
        </Menu>
        <Menu>
          <MenuButton as={Button} leftIcon={<AddIcon />} size="lg" colorScheme="blue">
            Create Project
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleCreateQuickStartProject}>
              Quick Start
            </MenuItem>
            {isCustomizable ? (
              <>
                <MenuItem onClick={handleCreateHitlProject}>
                  Customized Pipeline
                </MenuItem>
                <MenuItem onClick={handleCreateLlmProject}>
                  LLM Annotation
                </MenuItem>
              </>
            ) : (
              <>
                <MenuItem isDisabled icon={<LockIcon />}>
                  Customized Pipeline
                </MenuItem>
                <MenuItem isDisabled icon={<LockIcon />}>
                  LLM Annotation
                </MenuItem>
              </>
            )}
          </MenuList>
        </Menu>
      </Flex>
      
      {projects.length > 0 ? (
        renderProjectCards()
      ) : (
        renderEmptyProjectCard()
      )}
      <Box mt={6}>
        <ReactPaginate
          previousLabel={'previous'}
          nextLabel={'next'}
          breakLabel={'...'}
          pageCount={totalPages}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={'pagination'}
          activeClassName={'active'}
        />
      </Box>
      <CreateProjectModal isOpen={isOpen} onClose={onClose} type={type} />
    </Box>
  );
}