import React, { useState, useContext } from 'react';

import { 
  FormControl, 
  FormLabel, 
  Input, 
  HStack, 
} from "@chakra-ui/react";
import AuthContext from '../../common/AuthContext';

function ProfileForm({ renderSubmitButton, renderCancelButton }) {
  const { profile, editProfile } = useContext(AuthContext);

  // console.log("ProfileForm profile: ", profile);

  const handleUserProfileChange = (event) => {
    editProfile(event.target.name, event.target.value);
  }

  return (
    <>
      <FormControl>
        <FormLabel>First Name</FormLabel>
        <Input 
          type="text"
          name="firstName"
          value={profile?.data?.firstName}
          onChange={handleUserProfileChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Last Name</FormLabel>
        <Input 
          type="text"
          name="lastName"
          value={profile?.data?.lastName}
          onChange={handleUserProfileChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Phone Number</FormLabel>
        <Input 
          type="text"
          name="phoneNumber"
          value={profile?.data?.phoneNumber}
          onChange={handleUserProfileChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Company</FormLabel>
        <Input 
          type="text"
          name="company"
          value={profile?.data?.company}
          onChange={handleUserProfileChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Country</FormLabel>
        <Input 
          type="text"
          name="country"
          value={profile?.data?.country}
          onChange={handleUserProfileChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Industry</FormLabel>
        <Input 
          type="text"
          name="industry"
          value={profile?.data?.industry}
          onChange={handleUserProfileChange}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Role</FormLabel>
        <Input 
          type="text"
          name="role"
          value={profile?.data?.role}
          onChange={handleUserProfileChange}
        />
      </FormControl>

      <HStack spacing={4} mb={8} mt={8}>
        {renderCancelButton ? renderCancelButton(): null}
        {renderSubmitButton ? renderSubmitButton(): null}
      </HStack>
    </>
  )
}

export default ProfileForm;