import React, { useState, useContext, useEffect } from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { handleSessionRequest } from '../../common/Api';
import AuthContext from '../../common/AuthContext';

export default function Success() {
    const { authTokens, profile } = useContext(AuthContext);
    const { sessionId, amount } = useParams();
    const [completed, setCompleted] = useState(false);
    const [email, setEmail] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            const response = await handleSessionRequest(authTokens, profile.userId, sessionId, amount);
            setCompleted(response.data.status === "complete")
            setEmail(response.data.customer_email)
        }
        fetchData()
      }
    ,[]);

    return(
        <Box p={8}>
            {
                completed ?
                <>
                    <Heading>
                        We appreciate your business!
                    </Heading>
                    <Text>
                    A confirmation email will be sent to {email}.
                    If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
                    </Text>
                </> :
                <>
                    <Heading>
                        There was an issue processing your payment
                    </Heading>
                    <Text>
                    If you have any questions, please email <a href="mailto:orders@example.com">orders@example.com</a>.
                    </Text>
                </>
            }
            
        </Box>
    )
}