import React, { useState } from 'react';
import {
  Box,
  Flex,
  Button,
  Avatar,
  Stack,
  Heading,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { StarIcon } from '@chakra-ui/icons';
import { useParams, useNavigate } from 'react-router-dom';

const reviews = [
  {
    reviewerName: 'Alice',
    reviewDate: '2 days ago',
    rating: 5,
    reviewText: 'Great project! Everything was well-organized and the tasks were clear.',
  },
  {
    reviewerName: 'Bob',
    reviewDate: '1 week ago',
    rating: 4,
    reviewText: 'Good experience overall, but there were a few unclear instructions.',
  },
  // Add more reviews as needed
];

function ViewFeedback() {
  const navigate = useNavigate();
  const { projectId } = useParams();

  const averageRating =
    reviews.reduce((acc, review) => acc + review.rating, 0) / reviews.length;

  return (
    <Box>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading as="h1" size="xl">
          Project {projectId} Feedback
        </Heading>
        <Box>
          <Button onClick={() => navigate('/project-settings')} mr={2}>
            Project Settings
          </Button>
          <Button onClick={() => navigate(`/manage-batches/${projectId}`)}>Manage Batches</Button>
        </Box>
      </Flex>

      <Box bg={useColorModeValue('white', 'gray.800')} p={6} rounded={'md'} shadow={'lg'}>
        <Heading size="lg" mb={2}>
          {averageRating.toFixed(1)} out of 5 stars
        </Heading>
        <Text mb={4}>{reviews.length} reviews</Text>

        {reviews.map((review, index) => (
          <Box key={index} p={4} borderBottom="1px" borderColor="gray.200">
            <Flex alignItems="center" mb={2}>
              <Avatar size="sm" name={review.reviewerName} mr={4} />
              <Text fontWeight="bold" mr={2}>
                {review.reviewerName}
              </Text>
              <Text color="gray.500" fontSize="sm">
                {review.reviewDate}
              </Text>
              <Flex ml="auto">
                {[...Array(5)].map((_, i) => (
                  <StarIcon key={i} color={i < review.rating ? 'yellow.400' : 'gray.300'} />
                ))}
              </Flex>
            </Flex>
            <Text>{review.reviewText}</Text>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default ViewFeedback;
