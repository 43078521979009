
import ReactPaginate from 'react-paginate';
import { useParams } from 'react-router-dom';
import { devlogger } from '../../common/Logger';
import AuthContext from '../../common/AuthContext';
import { fetchTasksByProjectRequestPage } from '../../common/Api';
import React, { useState, useContext, useEffect } from 'react';
import { useFilters, useTable, useSortBy, useRowSelect } from 'react-table';
import { SuccessToastHelper, ErrorToastHelper } from '../../common/ToastHelper';
import { TriangleDownIcon, TriangleUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { useToast, Table, Thead, Tbody, Tr, Th, Td, chakra, Tooltip, Box, Text, Heading, TableContainer, Badge, Flex, Button, Menu, MenuButton, MenuList, MenuItem, Spinner } from "@chakra-ui/react";
import { DownloadFile } from '../../common/PresignUrlHelper';
import { saveAs } from 'file-saver';


const IndeterminateCheckbox = React.forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref || defaultRef;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <>
      <input type="checkbox" ref={resolvedRef} {...rest} />
    </>
  );
});

function ManageSamples() {
  const [isLoading, setIsLoading] = useState(false);
  const [currentProjectTasks, setCurrentProjectTasks] = useState([]);
  const [data, setData] = useState([]);
  const { authTokens } = useContext(AuthContext);

  const toast = useToast();
  const { projectId } = useParams();
  const [selectedRows, setSelectedRows] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const itemsPerPage = 15;

  useEffect(() => {
    setIsLoading(true);
    if (projectId) {
      fetchTasksByProjectRequestPage(authTokens, projectId, currentPage, itemsPerPage)
      .then(response => {
        if (response.status === 200) {
          console.log("Data: ", response.data.tasks)
          setData(response.data.tasks)
          setCurrentProjectTasks(response.data.tasks);
          setTotalPages(response.data.total_pages)
        } else {
          SuccessToastHelper(toast, "Something went wrong while fetching the batch")
        }
      })
      .catch(error => {
        ErrorToastHelper(toast, "Something went wrong while fetching the batch")
      })
      .finally(() => {
        setIsLoading(false);
      });
    }
  }, [projectId, currentPage]);

  // Not paginated data
  // const data = React.useMemo(() => currentProjectTasks, [currentProjectTasks]);
  
  // Handle page change from the ReactPaginate component
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  const renderTextCell = (text) => (
    <Tooltip label={text} placement="top">
      <div style={{
        maxWidth: '500px', // Adjust as needed
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
        {text}
      </div>
    </Tooltip>
    
  );

  const columns = React.useMemo(
    () => [
      {
        id: 'selection',
        Header: ({ getToggleAllRowsSelectedProps }) => (
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
        ),
        Cell: ({ row }) => <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />,
      },
      {
        Header: 'Sample Data',
        accessor: 'sampleData',
        Cell: ({ value }) => {
          let data;
          try {
            data = JSON.parse(value);
          } catch (e) {
            return renderTextCell(value);
          }

          if (data.image_url) {
            return (
              <Tooltip label={data.image_url} placement="top">
                <img src={data.image_url} alt="sample" width={100} />
              </Tooltip>
            );
          } else if (data.video_url) {
            return (
              <Tooltip label={data.video_url} placement="top">
                <video width="100" controls src={data.video_url} />
              </Tooltip>
            );
          } else {
            return renderTextCell(value);
          }
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
        isFilterable: true,
        Filter: ({ column }) => {
          const { filterValue, setFilter } = column;
          return (
            <Menu>
              <MenuButton as={Button} rightIcon={<ChevronDownIcon />} size="xs" variant="outline">
                {filterValue || 'all'}
              </MenuButton>
              <MenuList>
                <MenuItem value="" onClick={() => setFilter('')}>
                  all
                </MenuItem>
                <MenuItem value="in progress" onClick={() => setFilter('in progress')}>
                  in progress
                </MenuItem>
                <MenuItem value="annotated" onClick={() => setFilter('annotated')}>
                  annotated
                </MenuItem>
                <MenuItem value="in revision" onClick={() => setFilter('in revision')}>
                  in revision
                </MenuItem>
                <MenuItem value="reviewed" onClick={() => setFilter('reviewed')}>
                  reviewed
                </MenuItem>
              </MenuList>
            </Menu>
          );
        },
        Cell: ({ value }) => {
          let colorScheme;
          switch (value) {
            case 'in progress':
              colorScheme = 'yellow';
              break;
            case 'annotated':
              colorScheme = 'blue';
              break;
            case 'in revision':
              colorScheme = 'orange';
              break;
            case 'reviewed':
              colorScheme = 'green';
              break;
            default:
              colorScheme = 'gray';
          }
      
          return (
            <Badge size="xs" colorScheme={colorScheme}>
              {value}
            </Badge>
          );
        },
      },
      {
        Header: 'Completed Date',
        accessor: 'annotatedDate',
        Cell: ({ value }) => {
          if (!value) {
            return null;
          }

          const date = new Date(value);
          return date.toLocaleString();
        },
      },
      // {
      //   Header: 'Annotated Date',
      //   accessor: 'annotatedDate',
      //   Cell: ({ value }) => {
      //     if (!value) {
      //       return null;
      //     }

      //     const date = new Date(value);
      //     return date.toLocaleString();
      //   },
      // },
      // {
      //   Header: 'Reviewed Date',
      //   accessor: 'reviewDate',
      //   Cell: ({ value }) => {
      //     if (!value) {
      //       return null;
      //     }

      //     const date = new Date(value);
      //     return date.toLocaleString();
      //   },
      // },
      {
        Header: 'Result',
        accessor: 'result',
        Cell: ({ value }) => {
          if (value?.gemini_caption) {
            return renderTextCell(value.gemini_caption);
          } else {
            return renderTextCell(value);;
          }
        },
      },
    ],
    [],
  );

  const { 
    getTableProps, 
    getTableBodyProps, 
    headerGroups, 
    rows, 
    prepareRow,
    selectedFlatRows,
  } = useTable(
    { 
      columns, 
      data 
    }, 
    useFilters, 
    useSortBy, 
    useRowSelect,
  );

  useEffect(() => {
    setSelectedRows(selectedFlatRows.map((row) => row.original));
  }, [selectedFlatRows]);

  const handleManifestDownload = async () => {
    const path = `sagemaker/test-animation/${projectId}-datalance-sagemaker-job/manifests/output/output.manifest`
    const parts = path.split('/');
    const file = parts.pop();

    devlogger("Paths: ", path)
    devlogger("Parts: ", parts)
    devlogger("File: ", file)
    const type = "text/manifest";
    const downloadFile = await DownloadFile(file, path, type, authTokens);

    devlogger(downloadFile)

    const blob = new Blob([downloadFile], { type });
    saveAs(blob, "output.manifest");
  }

  const handleDownload = () => {
    const headers = columns
      .filter(column => typeof column.Header === 'string')
      .map(column => column.Header)
      .join(',');

    const rowValues = selectedRows
      .map(row => columns
        .filter(column => typeof column.Header === 'string')
        .map(column => row[column.accessor])
        .join(',')
      )
      .join('\n');

    const csvContent = `${headers}\n${rowValues}`;
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', url);
    link.setAttribute('download', `annotation-result-for-${projectId}.csv`);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box p={8}>
      <Flex justifyContent="space-between" alignItems="center" mb={6}>
        <Heading mb={6}>Manage Samples</Heading>
        {/* {currentProject?.setting?.batchSetting?.batchFilePath && <Text size="xl" mb={6}>Uploaded Batch File: {currentProject?.setting?.batchSetting?.batchFilePath.split('/').pop().replace(`${currentProject.id}_`, '')}</Text>} */}
        {/* <Button onClick={handleManifestDownload}>
          Download Results Manifest
        </Button>
        <Button 
          onClick={handleDownload}
          colorScheme={selectedRows.length > 0 ? "blue" : "gray"}
        >
          Download selected rows ({selectedRows.length})
        </Button> */}

        <Menu>
          <MenuButton as={Button} leftIcon={<ChevronDownIcon />} size="lg" colorScheme="blue">
            Download
          </MenuButton>
          <MenuList>
            <MenuItem onClick={handleManifestDownload}>
              Download Results Manifest
            </MenuItem>
            <MenuItem onClick={handleDownload}>
              Download selected rows ({selectedRows.length})
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
      

      <TableContainer pb={5}>
        <Table {...getTableProps()}>
          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={column.isNumeric}
                  >
                    <Flex align="center">
                      {column.render('Header')}
                      <chakra.span pl='4'>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <TriangleDownIcon aria-label='sorted descending' />
                          ) : (
                            <TriangleUpIcon aria-label='sorted ascending' />
                          )
                        ) : null}
                      </chakra.span>
                      {column?.isFilterable ? (
                        <Box ml={2} as="span">
                          {column.render('Filter')}
                        </Box>
                      ) : null}
                    </Flex>
                    
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          {isLoading ? (
            <Box p={6}>
              <Spinner/>
            </Box>
          ) : currentProjectTasks && currentProjectTasks.length > 0 ? (
            <Tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()}>
                    {row.cells.map((cell) => (
                      <Td {...cell.getCellProps()} isNumeric={cell.column.isNumeric}>
                        {cell.render('Cell')}
                      </Td>
                    ))}
                  </Tr>
                );
              })}
            </Tbody>
          ) : (
            <Text p={6}>No Tasks</Text>
          )}
        </Table>
      </TableContainer>

      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        pageCount={totalPages}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        containerClassName={'pagination'}
        activeClassName={'active'}
        onPageChange={handlePageChange}
      />
      
    </Box>
    
  );
}

export default ManageSamples;