import React, { useState, useContext, useEffect } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, FormControl, FormLabel, Input, Textarea, Box, Text, HStack } from "@chakra-ui/react";
// import ProjectDetailContext from "../../common/ProjectDetailContext";
import ProjectContext from "../../common/ProjectContext";
import ProjectBasicSettingForm from "../ProjectForm/ProjectBasicSettingForm";
import { useNavigate } from "react-router-dom";

function CreateProjectModal({ isOpen, onClose, type }) {

  const { createProject, currentProject } = useContext(ProjectContext);
  const [ isNewProjectSettingValid, setIsNewProjectSettingValid ] = useState(false);
  const navigate = useNavigate();

  const checkBasicSettingValidity = (newProjectSetting) => {
    // console.log("checkBasicSettingValidity newProjectSetting: ", newProjectSetting);

    if (newProjectSetting &&
      newProjectSetting.basicSetting &&
      newProjectSetting.basicSetting.title &&
      newProjectSetting.basicSetting.keyWords &&
      newProjectSetting.basicSetting.description) {
      setIsNewProjectSettingValid(true);
    } else {
      setIsNewProjectSettingValid(false);
    }
  }

  useEffect(() => {
    // console.log("CreateProjectModal useEffect currentProject: ", currentProject);
    checkBasicSettingValidity(currentProject?.setting);
  }, [currentProject]);

  const handleCreateProject = async () => {
    if (isNewProjectSettingValid) {
      await createProject(type)
      onClose();
      // Note: somehow the navigate is not working here 
      // maybe the current project id is not set yet
      // navigate(`/project/${currentProject.id}`);
    }
  };

  const renderCreateProjectButton = () => {
    return (
      <Button colorScheme="blue" onClick={handleCreateProject} isDisabled={!isNewProjectSettingValid} mr={2} ml={2} w="180px">Create New Project</Button>
    )
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="60vw">
          <ModalCloseButton />
          <ModalBody>
            <ProjectBasicSettingForm renderSubmitButton={(renderCreateProjectButton)} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CreateProjectModal;